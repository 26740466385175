import { useState } from "react";
import "./AddCommentInput.scss";
import { CommentI } from "../../../Interfaces/ToneTip";
import { useUserContext } from "../../context/userContext";
import { addComment } from "../../services/CloudFunctions";
import { Spinner } from "react-bootstrap";
import { Timestamp } from "firebase/firestore";

function AddCommentInput(props:{onCommentSent:(comment:CommentI) => void, uarc:string}) {
  const {onCommentSent, uarc} = props 
  const [commentInputOptions, setCommentInputOptions] = useState<boolean>(false);
  const [commentValue, setCommentValue] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const {currentUser} = useUserContext();

const isCommentvalid = commentValue.length > 0

  const handleCommentUpload = async() => {
    if (!(currentUser && currentUser.id)) return
    const newComment:CommentI = {
      userId: currentUser.id,
      text: commentValue,
      createdAt: new Date(),
      toneTipTag: uarc,
      editedComment:false
    }
    try {
      setLoading(true)
      await addComment({uarc:uarc, text:commentValue})
    } catch (error:any) {
      console.log(`Error while adding comment: ${error.message}`)
    } finally{
      setLoading(false)
      setCommentInputOptions(false)
      setCommentValue("")
    }
    onCommentSent(newComment)
  }

  const handleInputChange = (e:any) => {
    setCommentValue(e.target.value)
  }
  

  const handleInputCancel = () => {
    setCommentInputOptions(false)
    setCommentValue("")
  }

  const handleInputComment = (e:any) => {
    setCommentInputOptions(true)
  }
  
  return (
    <div className="comment-container">
      <textarea
        placeholder="Add a comment"
        onFocus={handleInputComment}
        id='text-input'
        value={commentValue}
        onChange={handleInputChange}
      />
        <div className="comment-options-container" style={!commentInputOptions ? {display:'none'}: {}}>
            <button className="cancel-comment-btn" id='cancel-comment-btn' onClick={handleInputCancel} type="button">Cancel</button>
            <button className="save-comment-btn" disabled={!isCommentvalid} onClick={handleCommentUpload}>{loading ? <Spinner/> : "Send"}</button>
        </div>
    </div>
  );
}

export default AddCommentInput;
