import "./TtPreviewPage.scss";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchSingleToneTip,
  getToneTipComments,
} from "../../services/ToneTipServices";
import { CommentI, ToneTipI } from "../../../Interfaces/ToneTip";
import ToneTipStatsCard, {
  ToneTipStats,
} from "../../components/ToneTipStatsCard/ToneTipStatsCard";
import CommandButton from "../../components/CommandButton/CommandButton";
import CommentCard from "../../components/CommentCard/CommentCard";
import { getUserDisplayNameAndEmailById } from "../../services/Users/UserGeneralServices";
import { Spinner } from "react-bootstrap";
import { useUserContext } from "../../context/userContext";
import AddCommentInput from "../../components/AddCommentInput/AddCommentInput";

type CreatorInfo = {
  displayName: string;
  email: string;
};

const TtPreviewPage = () => {
  const navigate = useNavigate();
  const toneTipRegex = /^[a-zA-Z0-9]{12}$/;
  const { id: toneTipUARC } = useParams();
  const [toneTip, setToneTip] = useState<ToneTipI>();
  const [ttStats, setTtStats] = useState<ToneTipStats>();
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [ttLoading, setTtLoading] = useState(false);
  const [comments, setComments] = useState<Array<CommentI>>();
  const [creatorInfo, setCreatorInfo] = useState<CreatorInfo>();
  const { currentUser } = useUserContext();

  let validParam: boolean;

  const onCommentEdited = (id: string | undefined, newComment: string) => {
    const commentToEdit = comments?.filter((comment) => comment.id === id)[0];
    if (commentToEdit) {
      const commentToEditIndex = comments?.indexOf(commentToEdit);
      const tempComments = [...comments];
      commentToEdit.editedComment = true;
      commentToEdit.text = newComment;
      tempComments[commentToEditIndex] = commentToEdit;
      setComments(tempComments);
    }
  };

  const onCommentDeleted = (id: string | undefined) => {
    setComments((prevState) => {
      return prevState?.filter((comment) => comment.id !== id);
    });
  };

  const onCommentSent = (comment: CommentI) => {
    if (comments) {
      const tempComments = [...comments];
      tempComments.unshift(comment);
      setComments(tempComments);
    } else {
      setComments([comment]);
    }
  };

  const getAllToneTipComments = async () => {
    setCommentsLoading(true);
    if (toneTipUARC && validParam) {
      let tempComments = await getToneTipComments(toneTipUARC.toUpperCase());
      if (tempComments) {
        setComments(tempComments);
      }
    }
    setCommentsLoading(false);
  };

  const fetchTT = async () => {
    if (validParam && toneTipUARC) {
      try {
        setTtLoading(true);
        const tt = await fetchSingleToneTip(toneTipUARC.toUpperCase());
        const tempStats: ToneTipStats = {
          likes: tt.likes,
          favorites: tt.favorites,
          impressions: tt.impressions,
          views: tt.views,
          comments: tt.comments,
          shares: tt.shares || 0,
        };
        const response = await getUserDisplayNameAndEmailById(tt.creator);
        setCreatorInfo(response);
        setTtStats(tempStats);
        setToneTip(tt);
        console.log(tt.commentsEnabled);
        if (tt.commentsEnabled != false) {
          getAllToneTipComments();
        }
      } catch (error) {
        alert("Error while fetching ToneTip data");
        navigate("/produce");
      } finally {
        setTtLoading(false);
      }
    } else {
      alert("The UARC you were looking for is not valid");
      navigate("/produce");
    }
  };

  useEffect(() => {
    validParam = toneTipRegex.test(toneTipUARC || "");
    fetchTT();
  }, []);

  return (
    <div className="preview-page-main-container">
      {toneTip && (
        <div className="preview-content-container">
          <div className="tt-card">
            <div className="gray-bg" />
            <img src={toneTip.image} className="tt-image" />
            <p className="title">{toneTip.title}</p>
            <p className="dp">{creatorInfo?.displayName || ""}</p>
          </div>
          <div className="tt-details">
            <div className="section-title">STATS</div>
            <ToneTipStatsCard passedTtStats={ttStats} uarc={toneTip.id} />
            <div className="commands-container">
              {toneTip.commands.map((command, index) => {
                return (
                  <CommandButton
                    values={command}
                    key={index}
                    commandIndex={index}
                    uarc={toneTip.id}
                  />
                );
              })}
            </div>
            <div className="section-title">COMMENTS</div>
            {currentUser && toneTipUARC && toneTip.commentsEnabled != false && (
              <AddCommentInput
                onCommentSent={onCommentSent}
                uarc={toneTipUARC}
              />
            )}
            <div className="comments-section">
              {commentsLoading && commentsLoadingUI}
              {comments &&
                comments.map((comment, index) => {
                  return (
                    <CommentCard
                      comment={comment}
                      key={comment.id}
                      onEditComment={onCommentEdited}
                      onDeleteComment={() => onCommentDeleted(comment.id)}
                    />
                  );
                })}
              {comments?.length === 0 && !commentsLoading && noCommentsFoundUI}
              {toneTip.commentsEnabled == false && commentsDisabledUI}
            </div>
          </div>
        </div>
      )}
      {ttLoading && (
        <div className="loading-tt-container">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
    </div>
  );
};

export default TtPreviewPage;

// UI snippets

const commentsLoadingUI = (
  <div className="loading-tt-container">
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  </div>
);

const noCommentsFoundUI = (
  <h3 style={{ textAlign: "center", color: "#ccc" }}>No comments found!</h3>
);

const commentsDisabledUI = (
  <p style={{textAlign:"center"}}>The Producer has <span style={{color:"#FBB61D"}}>disabled</span> comments</p>
);