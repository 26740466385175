import { CommentI } from "../../../../Interfaces/ToneTip";
import { useUserContext } from "../../../context/userContext";
import { Modal, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import "./CommentOptionsModal.scss";
import OrangeButton from "../../OrangeButton/OrangeButton";
import {
  editComment,
  hardDeleteComment,
  softDeleteComment,
} from "../../../services/CloudFunctions";
import { useState } from "react";

type CommentOptionsModalProps = {
  comment: CommentI;
  show: boolean;
  hideModal: () => void;
  onDeleteComment: () => void;
  onEditComment: (id: string, newComment: string) => void;
};
const softDeleteTooltip = (
  <Tooltip>
    Soft delete deletes the comment for regular users, but admins will still be
    able to see it. The comment information will still exist at the database.
  </Tooltip>
);
const hardDeleteTooltip = (
  <Tooltip>
    Hard delete permanently deletes the comment for everyone, this action cannot
    be undone
  </Tooltip>
);

const CommentOptionsModal = (props: CommentOptionsModalProps) => {
  const { currentUser } = useUserContext();
  const { show, comment, hideModal, onDeleteComment, onEditComment } = props;
  const [loading, setLoading] = useState(false);
  const [newComment, setnewComment] = useState<string>(comment.text);
  const isOwner = comment.userId === currentUser?.id;

  const handleSoftDelete = async () => {
    if (!comment.id) return;
    if (currentUser?.userType === "Producer") {
      const response = window.confirm(
        "Would you like to permanently delete this comment?"
      );
      if (!response) {
        return;
      }
    }

    try {
      setLoading(true);
      const response = await softDeleteComment({
        userId: comment.userId,
        commentId: comment.id,
      });
      if (response.data.message) {
        onDeleteComment();
      }
    } catch (error: any) {
      alert(`Error while deleting comment: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleHardDelete = async () => {
    if (!comment.id) return;
    try {
      setLoading(true);
      const response = await hardDeleteComment({
        commentId: comment.id,
        uarc: comment.toneTipTag,
      });
      if (response.data.message) {
        onDeleteComment();
      }
    } catch (error: any) {
      alert(`Error while deleting comment: ${error.message}`);
    } finally {
      setLoading(false);
    }
    onDeleteComment();
  };

  const handleNewCommentChange = (e: any) => {
    setnewComment(e.target.value);
  };

  const handleEdit = async () => {
    if (
      comment.id &&
      newComment &&
      newComment?.length > 0 &&
      newComment !== comment.text
    ) {
      try {
        setLoading(true);
        await editComment({
          commentUserId: comment.userId,
          originalComment: comment.text,
          newComment: newComment,
          commentId: comment.id,
          editedComment: comment.editedComment || false,
        });
        hideModal();
        onEditComment(comment.id, newComment);
      } catch (error: any) {
        alert(`Error while deleting comment: ${error.message}`);
      } finally {
        setLoading(false);
      }
    } else return;
  };

  return (
    <Modal show={show} onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title as="h4">Edit or delete comment.</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <textarea
          disabled={!isOwner}
          value={newComment}
          onChange={handleNewCommentChange}
        />
      </Modal.Body>
      <Modal.Footer className="actions-section-container">
        {currentUser && isOwner && currentUser.userType === "Producer" && (
          <button
            className="user-info-action-btn delete"
            type="button"
            onClick={handleSoftDelete}
            disabled={loading}
          >
            {loading ? <Spinner /> : "Delete"}
          </button>
        )}
        {currentUser && currentUser.userType === "Admin" && (
          <>
            <OverlayTrigger overlay={softDeleteTooltip} placement="bottom">
              <button
                className="user-info-action-btn soft-delete"
                type="button"
                onClick={handleSoftDelete}
                disabled={loading}
              >
                {loading ? <Spinner /> : "Soft delete"}
              </button>
            </OverlayTrigger>
            <OverlayTrigger overlay={hardDeleteTooltip}>
              <button
                className="user-info-action-btn delete"
                type="button"
                onClick={handleHardDelete}
                disabled={loading}
              >
                {loading ? <Spinner /> : "Hard delete"}
              </button>
            </OverlayTrigger>
          </>
        )}
        <button
          className="user-info-action-btn dismiss"
          type="button"
          onClick={hideModal}
          disabled={loading}
        >
          {loading ? <Spinner /> : "Dismiss"}
        </button>
        {isOwner && (
          <OrangeButton type="button" clickEvent={handleEdit} loader={loading}>
            Update
          </OrangeButton>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CommentOptionsModal;
