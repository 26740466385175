import { Modal } from "react-bootstrap";
import AddRowButton from "../../../assets/icons/add-command-btn.svg";
import { SubscriptionPlanI } from "../../../../Interfaces/PlanInfo";
import { useState } from "react";
import PricePlansTable from "../../PricePlansTable/PricePlansTable";
import {
  getAllProducts,
  getProductPrices,
} from "../../../services/CloudFunctions";

interface PricePlansCRUDmodalProps {
  show: boolean;
  hideModal: () => void;
}

const DUMMY_PRICEPLAN: SubscriptionPlanI = {
  planType: "Dummy Plan",
  annual: false,
  pricePerMonth: 0,
  pricePerYear: 0,
  toneTips: 0,
  impressions: 0,
  imageLink: false,
  activeButtons: 3,
};

const PricePlansCRUDmodal = (props: PricePlansCRUDmodalProps) => {
  const { hideModal, show } = props;
  const [pricePlans, setPricePlans] = useState<Array<SubscriptionPlanI>>([]);

  const handleModalShow = async () => {
    if (pricePlans.length < 1) {
      fetchPricePlans();
    }
  };

  const pricePlansCleanUp = () => {
    const tempPlans = [...(pricePlans || [])];
    const cleanArray = tempPlans.filter((p) => p.id);
    setPricePlans(cleanArray);
  };

  const fetchPricePlans = async () => {
    try {
      const products:any = await getAllProducts();
      const productPromises = products.data.map(async (product: any) => {
        const productPrices: any = await getProductPrices({ productId: product.id });
        return { product, productPrices };
      });
      const productData = await Promise.all(productPromises);
  
      const productsArray = productData.map(({ product, productPrices }) => {
        let monthlyPrice: number = 0;
        let monthlyPriceId:string|null = null;
        let yearlyPrice: number = 0;
        let yearlyPriceId:string|null = null;
        productPrices.data.forEach((e: any) => {
          if (e.recurring.interval === "year") {
            yearlyPrice = +e.unit_amount / 100;
            yearlyPriceId = e.id;
          } else if (e.recurring.interval === "month") {
            monthlyPrice = +e.unit_amount / 100;
            monthlyPriceId = e.id
          }
        });
  
        const tempProduct: SubscriptionPlanI = {
          id: product.id,
          planType: product.name || "Missing name",
          annual: false,
          pricePerMonth: monthlyPrice,
          ...(yearlyPriceId ? {pricePerYearId: yearlyPriceId}:{}),
          pricePerYear: yearlyPrice,
          ...(monthlyPriceId ? {pricePerMonthId: monthlyPriceId}:{}),
          toneTips: parseInt(product.metadata.tonetips) || 0,
          impressions: parseInt(product.metadata.impressions) || 0,
          imageLink: product.metadata.imgCmd === "true" || false,
          activeButtons: parseInt(product.metadata.commandBtns) || 3,
        };
        return tempProduct;
      });
  
      setPricePlans(productsArray);
    } catch (error) {
      // console.log(error);
      setPricePlans([]);
    }
  };

  const addRow = () => {
    const tempPlans = [...(pricePlans || [])];
    tempPlans.push(DUMMY_PRICEPLAN);
    setPricePlans(tempPlans);
  };
  return (
    <Modal
      onHide={hideModal}
      show={show}
      centered
      size="xl"
      onShow={handleModalShow}
      onExited={pricePlansCleanUp}
    >
      <Modal.Header closeButton>
        <h3>Create, read, update or delete price plans</h3>
      </Modal.Header>
      <Modal.Body>
        <PricePlansTable
          pricePlans={pricePlans}
          fetchPricePlans={fetchPricePlans}
        />
        <div className="add-row-container">
          <img src={AddRowButton} onClick={addRow} />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PricePlansCRUDmodal;
