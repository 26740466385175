import { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import Stripe from "stripe";
import EditIcon from "../../assets/img/edit-tonetip-button.svg";
import {
  changeImpressionsExcessPrice,
  getImpressionsExcessProduct,
  getProductPrices,
} from "../../services/CloudFunctions";
import OrangeButton from "../OrangeButton/OrangeButton";

interface ImpressionsExcessModalPropsI {
  show: boolean;
  hideModal: () => void;
}

interface PriceI {
  id:string,
  value:number
}

const ImpressionsExcessModal = (props: ImpressionsExcessModalPropsI) => {
  const [price, setPrice] = useState<PriceI>();
  const { show, hideModal } = props;
  const [editMode, setEditMode] = useState(false);
  const [editedPrice, setEditedPrice] = useState<number>();
  const [loader, setLoader] = useState(false);

  const getProduct = async () => {
    try {
      if (!price) {
        const { data: product } = await getImpressionsExcessProduct();
        const { data: tempPrice } = await getProductPrices({
          productId: product.id,
        });
        if (tempPrice[0].unit_amount) {
          setPrice({value:tempPrice[0].unit_amount / 100, id:tempPrice[0].id});
          setEditedPrice(tempPrice[0].unit_amount / 100);
        }
      }
    } catch (error: any) {
      alert(error.message);
    }
  };

  const handlePriceEdit = () => {
    setEditMode((prevState) => !editMode);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEditedPrice(+value);
  };

  const handleNewPriceSave = async () => {
    setLoader(true);
    try {
      if (price && editedPrice){
        const {data: newPrice} = await changeImpressionsExcessPrice({priceId:price?.id, amount:editedPrice});
          setPrice((prevState) => {
            if (newPrice.unit_amount){
              return {id:newPrice.id, value:newPrice.unit_amount/100}
            } else return prevState;
          } 
          );
      }
      alert("Success updating!")
    } catch (error:any) {
      alert(error.message)
    }
    setLoader(false);
  };

  return (
    <Modal show={show} onHide={hideModal} onShow={getProduct} onExited={() => setEditMode(false)}>
      <Modal.Header closeButton>Excess impressions</Modal.Header>
      <Modal.Body>
        Current cost per 1,000 impressions over limit :{" "}
        {price &&
          !editMode &&
          price.value.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        {price && editMode && (
          <>
            <input
              style={{ width: "65px" }}
              type="number"
              value={editedPrice}
              onChange={handleInputChange}
              step={0.01}
            />
            <OrangeButton clickEvent={handleNewPriceSave} type="button" loader={loader}>
              Save
            </OrangeButton>
          </>
        )}
          {!price && (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          )}
        <img
          src={EditIcon}
          onClick={handlePriceEdit}
          style={{ cursor: "pointer", marginLeft:"10px" }}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ImpressionsExcessModal;
