import { Spinner } from "react-bootstrap";
import { OrangeButtonI } from "../../../Interfaces/OrangeButton";
import styles from "./OrangeButton.module.scss";

const OrangeButton = (props: OrangeButtonI) => {
  const { clickEvent, children, loader, type } = props;

  return (
    <button
      onClick={clickEvent}
      type={type}
      className={styles.button}
      disabled={loader}
    >
      {!loader ? props.children : <Spinner/>}
    </button>
  );
};
export default OrangeButton;
