import { NavLink, useLocation } from "react-router-dom";
import "./SideNavbar.scss";
import ToneTipWaves from "../../assets/img/tone-tip-orange-waves.svg";
import ToneTipLogoNoWaves from "../../assets/img/tone-tip-logo-no-waves.svg";
import ProduceIconGrey from "../../assets/icons/produce-icon-grey.svg";
import ProduceIconOrange from "../../assets/icons/produce-icon-orange.svg";
import UpgradePlanIconGrey from "../../assets/icons/upgrade-plan-icon-grey.svg";
import UpgradePlanIconOrange from "../../assets/icons/upgrade-plan-icon-orange.svg";
// import AboutIconGrey from "../../assets/icons/about-icon-grey.svg";
// import AboutIconOrange from "../../assets/icons/about-icon-orange.svg";
import AdminIconGrey from "../../assets/icons/admin-icon-grey.svg";
import AdminIconOrange from "../../assets/icons/admin-icon-orange.svg";
import { useEffect, useRef, useState } from "react";
import ProfileOptionsModal from "../ProfileOptionsModal/ProfileOptionsModal";
import { useUserContext } from "../../context/userContext";
import DefaultAvatar from "../../assets/img/user-default-avatar.svg";

const SideNavbar = () => {
  const { currentUser } = useUserContext();
  const location = useLocation();
  const checkActiveLink = (path: string) => {
    return location.pathname === path;
  };
  const [profileOptions, setProfileOptions] = useState<boolean>(false);
  const profileOptionsContainerRef: any = useRef(null);

  const handleProfileOptions = () => {
    setProfileOptions(true);
  };

  const produceItemToRender = () => {
    if (checkActiveLink("/produce")) {
      return (
        <>
          <div className="orange-bar"></div>
          <img src={ProduceIconOrange} alt="Produce Tab selected" />
          <span className="hover-sections-text" style={{ color: "#FF8929" }}>
            Produce
          </span>
        </>
      );
    } else {
      return (
        <>
          <img src={ProduceIconGrey} alt="Produce tab" />
          <span className="hover-sections-text">Produce</span>
        </>
      );
    }
  };
  const upgradeItemToRender = () => {
    if (checkActiveLink("/upgrade")) {
      return (
        <>
          <div className="orange-bar"></div>
          <img src={UpgradePlanIconOrange} alt="Upgrade plan selected" />
          <span className="hover-sections-text" style={{ color: "#FF8929" }}>
            Upgrade Plan
          </span>
        </>
      );
    } else {
      return (
        <>
          <img src={UpgradePlanIconGrey} alt="Upgrade plan" />
          <span className="hover-sections-text">Upgrade Plan</span>
        </>
      );
    }
  };

  // const aboutItemToRender = () => {
  //   if (checkActiveLink("/about")) {
  //     return (
  //       <>
  //         <div className="orange-bar"></div>
  //         <img src={AboutIconOrange} alt='About tab selected'/>
  //         <span className="hover-sections-text" style={{ color: "#FF8929" }}>
  //           About
  //         </span>
  //       </>
  //     );
  //   } else {
  //     return (
  //       <>
  //         <img src={AboutIconGrey} alt='About tab'/>
  //         <span className="hover-sections-text">About</span>
  //       </>
  //     );
  //   }
  // };

  const adminItemToRender = () => {
    if (checkActiveLink("/admin")) {
      return (
        <>
          <div className="orange-bar"></div>
          <img src={AdminIconOrange} alt="Admin Tab selected" />
          <span className="hover-sections-text" style={{ color: "#FF8929" }}>
            Admin
          </span>
        </>
      );
    } else {
      return (
        <>
          <img src={AdminIconGrey} alt="Admin Tab" />
          <span className="hover-sections-text">Admin</span>
        </>
      );
    }
  };

  useEffect(() => {
    if (profileOptions) {
      const handleClickOutside = (event: { target: any }) => {
        if (
          profileOptionsContainerRef.current &&
          !profileOptionsContainerRef.current.contains(event.target)
        ) {
          setProfileOptions(false);
        }
      };

      const handleEscapeKey = (event: { keyCode: number }) => {
        if (event.keyCode === 27) {
          // Escape key code
          setProfileOptions(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleEscapeKey);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        document.removeEventListener("keydown", handleEscapeKey);
      };
    }
  }, [profileOptions]);

  return (
    <div
      className={`side-navbar-main-container ${
        profileOptions ? "profile-options" : ""
      } `}
    >
      <div
        className={profileOptions ? "grey-effect" : ""}
        style={{ position: "absolute" }}
      ></div>
      <div className="grey-bar"></div>
      <div className="tone-tip-waves-container sn-container">
        <img src={ToneTipWaves} alt="ToneTip logo waves" />
        <img
          id="tone-tip-logo-no-waves"
          src={ToneTipLogoNoWaves}
          alt="ToneTip logo waveless"
        />
      </div>
      <div className="sidebar-nav-list-container sn-container">
        <ul className="sidebar-nav">
        <li>
            {(currentUser?.active || currentUser?.userType === "Admin" || currentUser?.userType === "Owner") && <NavLink to="/produce">{produceItemToRender()}</NavLink>}
          </li>
          <li>
            {currentUser?.userType !== "Admin" && <NavLink to="/upgrade">{upgradeItemToRender()}</NavLink>}
          </li>
          {/* <li>
            <NavLink to="/about">{aboutItemToRender()}</NavLink>
          </li> */}
          {currentUser?.userType === "Admin" && (
            <li>
              <NavLink to="/admin">{adminItemToRender()}</NavLink>
            </li>
          )}
        </ul>
      </div>
      <div
        className="user-profile-picture-container sn-container"
        ref={profileOptionsContainerRef}
      >
        <div
          className={`user-profile-picture-frame ${
            profileOptions ? "profile-active" : ""
          }`}
          onClick={handleProfileOptions}
        >
          <img src={currentUser?.avatar || DefaultAvatar} alt="User avatar" />
        </div>
        <div
          className={`name-and-plan-text ${
            profileOptions ? "profile-active" : ""
          }`}
          onClick={handleProfileOptions}
        >
          <span className="user-name">
            {currentUser?.displayName || "Display Name"}
          </span>
          <br />
          <span className="account-plan">
            {currentUser?.subType || "No plan selected"}
          </span>
        </div>
        <div
          className="profile-options-container"
          ref={profileOptionsContainerRef}
          style={
            profileOptions
              ? {
                  display: "flex",
                }
              : {}
          }
        >
          <ProfileOptionsModal />
        </div>
      </div>
    </div>
  );
};
export default SideNavbar;
