import { Modal } from "react-bootstrap";
import closeBtn from "../../../assets/img/close-btn.svg";
import ToneTipLogo from "../../../assets/img/tone-tip-logo-circle.svg";
import CouponArrow from "../../../assets/icons/arrow-up-right-icon-orange.svg";
import OrangeButton from "../../OrangeButton/OrangeButton";
import "./Step1Content.scss";
import { Step1I } from "../../../../Interfaces/CheckoutSteps";

function Step1Content(props: Step1I) {
  const { onHide, handleContinue, planInfo, annual } = props;

  const planDetails = () => {
    const price = annual
      ? `${planInfo.pricePerYear}/year`
      : `${planInfo.pricePerMonth}/month`;
    const toneTips = planInfo.toneTips.toLocaleString();
    const impressions = planInfo.impressions.toLocaleString();
    const renewalType = annual ? "yearly" : "monthly";

    return (
      <>
        ${price.toLocaleString()}. Includes {toneTips} ToneTips and {impressions} impressions per
        month. Upgrade or cancel at any time. Automatically renews {renewalType}
        . See the ToneTip price plan for details.
      </>
    );
  };

  return (
    <Modal.Body>
      <div className="close-btn-container">
        <img src={closeBtn} onClick={onHide} style={{ cursor: "pointer" }} />
      </div>
      <div className="plan-info-top-container">
        <img src={ToneTipLogo} id="tonetip-logo" />
        <span>Your order</span>
        <h1>
          $
          {!annual
            ? planInfo.pricePerMonth.toLocaleString()
            : planInfo.pricePerYear?.toLocaleString()}
        </h1>
      </div>
      <div className="plan-info-text-container">
        <span className="plan-name">{planInfo.planType || ""} plan</span>
        <span className="plan-details">{planDetails()}</span>
        {/* <span className="apply-cupon-btn">
          Apply coupon <img src={CouponArrow} />
        </span> */}
      </div>
      <div className="proceed-btn-container">
        <OrangeButton type="button" clickEvent={handleContinue}>
          Proceed to checkout
        </OrangeButton>
      </div>
    </Modal.Body>
  );
}

export default Step1Content;
