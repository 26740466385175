import { ChangeEvent, useState } from "react";
import { useToneTipContext } from "../../context/toneTipsContext";
import "./AdminMode.scss";
import FormCheck from "react-bootstrap/FormCheck";

const AdminMode = () => {
  const {setAdminMode, adminMode} = useToneTipContext();
  const [loading, setLoading] = useState<boolean>();
  
  const handleAdminModeChange = async (e:ChangeEvent<HTMLInputElement>) => {
    const adminModeVal = e.target.checked;
    setAdminMode(adminModeVal);
  }

  return (
    <div className="admin-mode-container">
      <FormCheck
        type="switch"
        id="admin-mode-switch"
        label="Admin mode"
        className="admin-mode-switch"
        onChange={handleAdminModeChange}
        disabled={loading}
        checked={adminMode}
      />
    </div>
  );
};
export default AdminMode;
