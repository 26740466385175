import "./ToneTipStatsCard.scss";
import ThumbUpIcon from "../../assets/icons/thumb-up-icon.svg";
import ThumbUpIconActive from "../../assets/icons/thumb-up-icon-active.svg";
import FavoriteIcon from "../../assets/icons/favorite-heart-icon.svg";
import FavoriteIconActive from "../../assets/icons/favorite-heart-icon-active.svg";
import ShareIcon from "../../assets/icons/share-icon-black.svg";
import ForumIcon from "../../assets/icons/forum-icon.svg";
import VisibilityIcon from "../../assets/icons/eye-visibility-icon.svg";
import { useToneTipContext } from "../../context/toneTipsContext";
import { numberFormatter } from "../../utils/StringFormatters";
import { useEffect, useReducer } from "react";
import {
  addToFavorites,
  addToLikes,
  removeFromFavorites,
  removeFromLikes,
} from "../../services/CloudFunctions";
import { useUserContext } from "../../context/userContext";
import { db } from "../../firebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share";

export type ToneTipStats = {
  likes: number;
  favorites: number;
  impressions: number;
  views: number;
  comments: number;
  shares: number;
};

type ToneTipStatsProps = {
  passedTtStats?: ToneTipStats;
  uarc?: string;
};

type LikeFavReducerActionType =
  | "LIKE"
  | "DISLIKE"
  | "FAV"
  | "UNFAV"
  | "LIKE_PROCESS"
  | "FAV_PROCESS";

function likeFavReducer(
  state: any,
  action: { type: LikeFavReducerActionType }
) {
  switch (action.type) {
    case "LIKE":
      return { ...state, isLiked: true };
    case "DISLIKE":
      return { ...state, isLiked: false };
    case "FAV":
      return { ...state, isFavorite: true };
    case "UNFAV":
      return { ...state, isFavorite: false };
    case "LIKE_PROCESS":
      return { ...state, likeProcess: !state.likeProcess };
    case "FAV_PROCESS":
      return { ...state, favProcess: !state.favProcess };
    default:
      return { ...state };
  }
}

type LikeFavState = {
  isLiked: boolean;
  isFavorite: boolean;
  likeProcess: boolean;
  favProcess: boolean;
};
const likeFavInitialArg: LikeFavState = {
  isLiked: false,
  isFavorite: false,
  likeProcess: false,
  favProcess: false,
};

function ToneTipStatsCard({ passedTtStats, uarc }: ToneTipStatsProps) {
  const [likeFavState, likeFavDispatch] = useReducer(
    likeFavReducer,
    likeFavInitialArg
  );
  const { selectedToneTip } = useToneTipContext();
  const { currentUser } = useUserContext();
  let likes: number = 0,
    favorites: number = 0,
    views: number = 0,
    comments: number = 0,
    shares: number = 0;

  if (selectedToneTip?.title) {
    ({ likes, favorites, views, comments, shares } = selectedToneTip);
  } else if (passedTtStats) {
    ({ likes, favorites, views, comments, shares } = passedTtStats);
  }
  const shareUrl = `https://tt.media/tt/${uarc}`;
  const sharingTooltip = (
    <Tooltip id="Sharing Tooltip" className="sharing_tooltip">
      <p>
        <FacebookShareButton url={shareUrl}>
          <FacebookIcon size={32} round />
        </FacebookShareButton>
      </p>
      <p>
        <TwitterShareButton
          url={shareUrl}
          title={"Check out this ToneTip!"}
        >
          <XIcon size={32} round />
        </TwitterShareButton>
      </p>
      <p>
      <WhatsappShareButton
          url={shareUrl}
          title={"Check out this ToneTip!"}
          separator=":: "
        >
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
      </p>
      <p>
      <LinkedinShareButton
          url={shareUrl}
          className="Demo__some-network__share-button"
        >
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
      </p>
    </Tooltip>
  );

  const likeOrFav = async (action: "like" | "fav") => {
    if (
      currentUser === null ||
      likeFavState.likeProcess ||
      likeFavState.favProcess
    )
      return;
    if (action === "fav") {
      likeFavDispatch({ type: "FAV_PROCESS" });
      if (!likeFavState.isFavorite) {
        // Add TT doc to favorites subcollection
        addToFavorites(selectedToneTip?.id || uarc)
          .then(() => {
            likeFavDispatch({ type: "FAV" });
          })
          .finally(() => {
            likeFavDispatch({ type: "FAV_PROCESS" });
          });
      } else if (likeFavState.isFavorite) {
        // Remove TT from favorites subcollection
        removeFromFavorites(selectedToneTip?.id || uarc)
          .then(() => {
            likeFavDispatch({ type: "UNFAV" });
          })
          .finally(() => {
            likeFavDispatch({ type: "FAV_PROCESS" });
          });
      }
    } else if (action === "like") {
      likeFavDispatch({ type: "LIKE_PROCESS" });
      if (!likeFavState.isLiked) {
        // Add tonetip to likes
        addToLikes(selectedToneTip?.id || uarc)
          .then(() => {
            likeFavDispatch({ type: "LIKE" });
          })
          .finally(() => {
            likeFavDispatch({ type: "LIKE_PROCESS" });
          });
      } else if (likeFavState.isLiked) {
        // Remove ToneTip from likes
        removeFromLikes(selectedToneTip?.id || uarc)
          .then(() => {
            likeFavDispatch({ type: "DISLIKE" });
          })
          .finally(() => {
            likeFavDispatch({ type: "LIKE_PROCESS" });
          });
      }
    }
    return;
  };

  const checkForLikeAndFavorite = async () => {
    const ttUarc = selectedToneTip ? selectedToneTip.id : uarc;
    if (currentUser && currentUser.id && ttUarc) {
      const likeRef = doc(db, "Users", currentUser.id, "likes", ttUarc);
      const favRef = doc(db, "Users", currentUser.id, "favorites", ttUarc);
      const likeDoc = await getDoc(likeRef);
      const favDoc = await getDoc(favRef);
      if (likeDoc.exists()) {
        likeFavDispatch({ type: "LIKE" });
      }
      if (favDoc.exists()) {
        likeFavDispatch({ type: "FAV" });
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    likeFavDispatch({type:"DISLIKE"})
    likeFavDispatch({type:"UNFAV"})
    checkForLikeAndFavorite();
  }, [uarc, selectedToneTip]);

  return (
    <div className="stats-card-container">
      <div className="stat-container">
        <img
          src={likeFavState.isLiked ? ThumbUpIconActive : ThumbUpIcon}
          className={likeFavState.isLiked ? "fadeIn" : ""}
          onClick={() => likeOrFav("like")}
          alt="Like icon"
        />
        <span className="stat-number">{numberFormatter(likes) || 0}</span>
      </div>
      <div className="stat-container">
        <img
          className={likeFavState.isFavorite ? "fadeIn" : ""}
          src={likeFavState.isFavorite ? FavoriteIconActive : FavoriteIcon}
          onClick={() => likeOrFav("fav")}
          alt="Favorite icon"
        />
        <span className="stat-number">{numberFormatter(favorites) || 0}</span>
      </div>
      <div className="stat-container">
        <OverlayTrigger
          overlay={sharingTooltip}
          placement="right"
          trigger={["click"]}
          rootClose
        >
          <img src={ShareIcon} alt="Shares icon" />
        </OverlayTrigger>
        <span className="stat-number">{numberFormatter(shares) || 0}</span>
      </div>
      <div className="stat-container">
        <img src={ForumIcon} alt="Forum icon" />
        <span className="stat-number">{numberFormatter(comments) || 0}</span>
      </div>
      <div className="stat-container">
        <img src={VisibilityIcon} alt="Visualization icon" />
        <span className="stat-number">{numberFormatter(views) || 0}</span>
      </div>
    </div>
  );
}

export default ToneTipStatsCard;
