import "./UserInfoCard.scss";
import ExamplePP from '../../assets/img/inowu-pp.svg';

function UserInfoCard(props:any) {
    const {name, avatar,  email} = props
    return (<div className="user-info-card-container">
        <div className="user-pp-container"><img src={avatar}/></div>
        <div className="user-name-email-container">
            <div className="name">{`${name.firstName} ${name.lastName}`}</div>
            <div className="email">{email}</div>
        </div>
    </div>);
}

export default UserInfoCard;