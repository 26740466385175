import { FormCheck } from "react-bootstrap";
import "./MonthlyAnnualSelector.scss";
import { MonthlyAnnualSelectorPropsI } from "../../../Interfaces/MonthlyAnnualSelector";

export default function MonthlyAnnualSelector(
  props: MonthlyAnnualSelectorPropsI
) {
  const { setAnnual } = props;
  const handleSwitchChange = (e: any) => {
    const checked: boolean = e.target.checked;
    setAnnual(checked);
  };

  return (
    <div className="monthly-annual-selector-container">
      <p>Monthly</p>
      <FormCheck
        type="switch"
        defaultChecked={true}
        id="monthly-annual-switch"
        onChange={handleSwitchChange}
      />
      <p>Annual</p>
    </div>
  );
}
