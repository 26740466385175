import { UserCredential, onAuthStateChanged, signOut } from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { auth, db } from "../firebaseConfig";
import { UserI } from "../../Interfaces/User";
import {
  addAdminToken,
  getUserById,
} from "../services/Users/UserGeneralServices";
import {
  registerUserToDb,
  updateLastLogin,
} from "../services/Users/LoginRegisterServices";
import {
  getSubscription,
  getUsersPaymentMethods,
} from "../services/CloudFunctions";
import Stripe from "stripe";
import { doc, onSnapshot } from "firebase/firestore";

export const UserContext = createContext({
  currentUser: null as UserI | null,
  signOutUser: () => {},
  simpleSignIn: (email: string, password: string): any => {},
  signInWithGoogle: () => {},
  signInWithFacebook: () => {},
  registerWithEmailAndPassword: (
    email: string,
    password: string,
    user: UserI
  ) => {},
  refreshUser: async () => {},
  userPaymentMethod: {} as any,
  getPaymentMethod: async () => {},
  userSubscription: {} as Stripe.Subscription | undefined,
  setUserSubscription: (userSubscription: undefined) => {},
});

export function useUserContext() {
  return useContext(UserContext);
}

const UserContextProvider = ({ children }: any) => {
  const [loading, setLoading] = useState<boolean>(true);
  const googleProvider = new GoogleAuthProvider();
  const facebookProvider = new FacebookAuthProvider();
  const [currentUser, setCurrentUser] = useState<UserI | null>(null);
  const [userPaymentMethod, setUserPaymentMethod] = useState<any>();
  const [userSubscription, setUserSubscription] = useState<
    Stripe.Subscription | undefined
  >();

  const getSubscriptionInfo = async () => {
    if (currentUser?.stripeId) {
      const { data: sub } = await getSubscription(currentUser.stripeId);
      setUserSubscription(sub);
      // console.log(sub)
      // if (sub && sub.status === "past_due"){

      // }
    }
  };

  const getPaymentMethod = async () => {
    if (!currentUser?.stripeId) {
      setUserPaymentMethod(null);
    } else {
      const res: any = await getUsersPaymentMethods({
        stripeId: currentUser?.stripeId,
      });
      if (res.data !== null) {
        setUserPaymentMethod(res.data);
      } else setUserPaymentMethod(res.data);
    }
  };

  const simpleSignIn = async (email: string, password: string) => {
    try {
      const userLoggingIn: any = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const tempUser = await getUserById(userLoggingIn.user.uid);

      if (tempUser != undefined)
        setCurrentUser({ id: userLoggingIn.user.uid, ...tempUser });
      if (tempUser?.userType === "Admin") {
        await addAdminToken(userLoggingIn.user.uid);
      }
      await updateLastLogin(
        userLoggingIn.user.uid,
        +userLoggingIn.user.metadata.lastLoginAt
      );
      return true;
    } catch (error: any) {
      if (
        error.code.includes("wrong-password") ||
        error.code.includes("user-not-found")
      ) {
        alert(
          "Wrong credentials, please check them and try again or try logging in with your Google account."
        );
      } else if (error.code.includes("too-many-requests")) {
        alert("Too many attempts, please try again in a few minutes.");
      }
      return false;
    }
  };

  const signOutUser = async () => {
    try {
      let res = await signOut(auth);
      setCurrentUser(null);
    } catch (error) {}
  };

  const refreshUser = async () => {
    if (currentUser?.id) {
      const id: string = currentUser.id;
      const tempUser = await getUserById(id);
      if (tempUser != undefined) setCurrentUser(tempUser);
    }
  };
  const signInWithGoogle = async () => {
    try {
      let res: any = await signInWithPopup(auth, googleProvider);
      if (res._tokenResponse.isNewUser) {
        // Property returned by Firebase to indicate this is a new user
        let user = res.user;
        let uid: string = user.uid;
        const userData: UserI = {
          id: uid,
          displayName: user.displayName,
          email: user.email,
          name: {
            firstName: res._tokenResponse.firstName,
            lastName: res._tokenResponse.lastName,
          },
          avatar: res._tokenResponse.photoUrl,
          userType: "Producer",
          createdAt: new Date(+user.metadata.createdAt),
          lastLogin: new Date(+user.metadata.lastLoginAt),
          active: false,
          subType: null,
          toneTips: [],
          toneTipsLeft: 0,
          trialTaken: false,
        };
        await registerUserToDb(userData, uid);
        setCurrentUser(userData);
      } else {
        // If the user is not new, get the user info  from the Users collection to set for context
        const tempUser = await getUserById(res.user.uid);
        if (tempUser !== undefined) setCurrentUser(tempUser);
      }
      return res;
    } catch (error) {
      // console.log(error);
      return null;
    }
  };

  const signInWithFacebook = async () => {
    try {
      let res: any = await signInWithPopup(auth, facebookProvider);
      setCurrentUser(res.user);
      return res;
    } catch (error) {
      // console.log(error);
      return null;
    }
  };

  const registerWithEmailAndPassword = async (
    email: string,
    password: string,
    user: UserI
  ) => {
    try {
      const res: UserCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      setCurrentUser({ id: res.user.uid, ...user });
      return res;
    } catch (error: any) {
      throw new Error(error.code);
    }
  };

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userData = onSnapshot(doc(db, "Users", user.uid), (doc) => {
          const updatedUser: UserI = {
            id: user.uid,
            ...(doc.data() as UserI),
          };
          setCurrentUser(updatedUser);
        });
        const tempUser = await getUserById(user.uid);
        if (tempUser !== undefined) setCurrentUser(tempUser);
      } else {
      }
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (currentUser != null) {
      getPaymentMethod();
      getSubscriptionInfo();
    } else {
    }
  }, [currentUser]);

  const values = {
    currentUser,
    signOutUser,
    simpleSignIn,
    signInWithGoogle,
    signInWithFacebook,
    registerWithEmailAndPassword,
    refreshUser,
    userPaymentMethod,
    getPaymentMethod,
    userSubscription,
    setUserSubscription,
  };
  if (loading) return <></>;
  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};
export default UserContextProvider;
