import ToneTipPreviewCard from "../ToneTipPreviewCard/ToneTipPreviewCard";
import CloseButton from "../../assets/img/close-btn.svg";
import "./EditToneTip.scss";
import EditableCommandButton from "../EditableCommandButton/EditableCommandButton";
import UpdateImageButton from "../../assets/img/update-image-btn.svg";
import { useRef, useState } from "react";
import OrangeButton from "../OrangeButton/OrangeButton";
import {
  CommandI,
  ImageCommandI,
  UpdateToneTipDataI,
} from "../../../Interfaces/ToneTip";
import { useToneTipContext } from "../../context/toneTipsContext";
import { useUserContext } from "../../context/userContext";
import AddCommandBtn from "../../assets/icons/add-command-btn.svg";
import DeleteCommandBtn from "../../assets/icons/delete-command-btn.svg";
import {
  enableOrDisableToneTip,
  enableOrDisableToneTipComments,
  updateToneTip,
} from "../../services/ToneTipServices";
import { uploadPicture } from "../../services/StorageUploadServices";
import { FormCheck } from "react-bootstrap";

const FILE_SIZE = 2000000; // 2 MB

const DUMMY_COMMAND_BTN: CommandI = {
  action: "Website",
  bgColor: "#0000BB",
  text: "Button",
  textColor: "#ffffff",
  value: "",
  clicks: 0,
};

function EditToneTip(props: any) {
  const { currentUser } = useUserContext();
  const { handleEditToneTip, selectedToneTip, refreshCurrentToneTip } =
    useToneTipContext();
  const [imagePreview, setImagePreview] = useState<string>("");
  const [toneTipTitle, setToneTipTitle] = useState<string>(
    selectedToneTip?.title || ""
  );
  const [imageCommandType, setImageCommandType] = useState<
    ImageCommandI["action"]
  >(selectedToneTip?.imageCommand?.action || "Website");
  const [imageCommandValue, setImageCommandValue] = useState<string>(
    selectedToneTip?.imageCommand?.value || ""
  );
  const availableCommandButtons =
    currentUser?.subType === "Business" ||
    currentUser?.subType === "Enterprise" ||
    currentUser?.userType === "Admin"
      ? 5
      : 3;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [commandButtons, setCommandButtons] = useState<Array<CommandI>>(
    selectedToneTip?.commands || []
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [toneTipFileName, setToneTipFileName] = useState(
    selectedToneTip?.fileName || ""
  );
  const [initialValues, setInitialValues] = useState({
    title: toneTipTitle,
    file: selectedFile,
    filename: toneTipFileName,
    commandButtons: commandButtons,
    imageCommand: { type: imageCommandType, value: imageCommandValue },
  });

  const handleActiveStatusChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLoading(true);
    try {
      const newStatus: boolean = e.target.checked;
      if (selectedToneTip?.id) {
        await enableOrDisableToneTip(selectedToneTip.id, newStatus);
        await refreshCurrentToneTip();
      }
    } catch (error: any) {
      alert(error.message);
    }
    setLoading(false);
  };

  const handleCommentsEnabledChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLoading(true);
    try {
      const newStatus: boolean = e.target.checked;
      if (selectedToneTip?.id) {
        await enableOrDisableToneTipComments(selectedToneTip.id, newStatus);
        await refreshCurrentToneTip();
      }
    } catch (error: any) {
      alert(error.message);
    }
    setLoading(false);
  };

  const handleSaveToneTip = async () => {
    try {
      setLoading(true);
      const newValues = {
        title: toneTipTitle,
        file: selectedFile,
        commandButtons: commandButtons,
        fileName: toneTipFileName,
        imageCommand: { type: imageCommandType, value: imageCommandValue },
      };
      if (JSON.stringify(initialValues) === JSON.stringify(newValues)) {
        throw new Error("Nothing has changed!!");
      }
      let imageURL: string | null = null;
      if (selectedToneTip?.id) {
        if (selectedFile) {
          imageURL = await uploadPicture(
            selectedFile,
            "tonetipAvatars",
            selectedToneTip.id
          );
        }
        const data: UpdateToneTipDataI = {
          ...(toneTipTitle ? { title: toneTipTitle } : {}),
          ...(imageURL ? { image: imageURL } : {}),
          commands: [...commandButtons],
          lastEdited: new Date(),
          fileName: toneTipFileName,
          imageCommand: { action: imageCommandType, value: imageCommandValue },
        };

        await updateToneTip(data, selectedToneTip.id);
        alert("ToneTip updated successfully");
        await refreshCurrentToneTip();
      } else {
        throw new Error("Unexpected error, please try again");
      }
    } catch (error: any) {
      alert(error.message);
    }
    setLoading(false);
  };

  const resetToDefaults = () => {
    setToneTipTitle("");
    setImagePreview("");
    setImageCommandType(selectedToneTip?.imageCommand?.action || "Website");
    setImageCommandValue(selectedToneTip?.imageCommand?.value || "");
    setToneTipFileName(selectedToneTip?.fileName || "");
    setSelectedFile(null);
    setCommandButtons(selectedToneTip?.commands || []);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > FILE_SIZE) {
        alert("File size must be 2 MB or less");
        return;
      }
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedFile(null);
      setImagePreview("");
    }
  };

  const addCommandButton = (): void => {
    if (commandButtons.length < availableCommandButtons) {
      setCommandButtons((prevState) => {
        return [
          ...prevState,
          selectedToneTip?.commands[commandButtons.length] || DUMMY_COMMAND_BTN,
        ];
      });
    }
  };

  const deleteCommandButton = (): void => {
    if (commandButtons.length > 3) {
      setCommandButtons((prevState) => {
        return prevState.slice(0, -1);
      });
    }
  };

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className="create-tone-tip-container">
      <div className="sticky-container">
        <div className="details-top-part">
          <span className="tone-tip-title">
            Edit - {selectedToneTip?.title}
          </span>
          <div className="tone-tip-action-btns-container">
            <img src={CloseButton} onClick={() => handleEditToneTip()} />
          </div>
        </div>
        <div className="preview section">
          <div className="section-title">CARD PREVIEW</div>
          <ToneTipPreviewCard
            imagePreview={imagePreview}
            titleValue={toneTipTitle}
            updateImage={handleImageClick}
          />
          <div className="image section">
            <div className="upload-image-container">
              {/* <img src={UpdateImageButton} onClick={handleImageClick} /> */}
              <span>
                Image should have 1:1 aspect ratio. Ideal resolution is
                1080x1080 pixels.
              </span>
            </div>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
              accept="image/jpeg, image/png"
            />
          </div>
          <div className="status-change-container">
            <div className="property-switch">
              Status:
              <FormCheck
                type="switch"
                id="status-switch"
                defaultChecked={selectedToneTip?.active}
                onChange={(e) => handleActiveStatusChange(e)}
                disabled={loading}
              />
            </div>
            <div className="property-switch">
              Comments:
              <FormCheck
                type="switch"
                id="comments-switch"
                defaultChecked={selectedToneTip?.commentsEnabled ?? true}
                onChange={(e) => handleCommentsEnabledChange(e)}
                disabled={loading}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="title section">
        <div className="section-title">TITLE</div>
        <div className="add-title-container">
          <input
            type="text"
            placeholder="Add title"
            value={toneTipTitle}
            onChange={(e) => {
              setToneTipTitle(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="title section">
        <div className="section-title">FILENAME</div>
        <div className="add-title-container">
          <input
            type="text"
            placeholder="Filename"
            onChange={(e) => setToneTipFileName(e.target.value)}
            value={toneTipFileName}
          />
        </div>
      </div>
      <div className="title section">
        {currentUser?.subType === "Business" ||
        currentUser?.subType === "Enterprise" ||
        currentUser?.userType === "Admin" ? (
          <div className="title section">
            <div className="section-title">IMAGE COMMAND</div>
            <div className="add-title-container">
              <input
                type="text"
                placeholder="Add image command value"
                onChange={(e) => setImageCommandValue(e.target.value)}
                value={imageCommandValue}
              />
              <select
                value={imageCommandType}
                onChange={(e) =>
                  setImageCommandType(e.target.value as ImageCommandI["action"])
                }
                name="action"
                style={{ marginTop: "10px" }}
              >
                <option value="Website">Website</option>
                <option value="Phone">Phone</option>
                <option value="Location">Location</option>
              </select>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="commands section">
        <div className="section-title">COMMAND BUTTONS</div>
        <div className="command-btns-container">
          {commandButtons.map((command: CommandI, index: number) => {
            return (
              <EditableCommandButton
                id={index}
                key={index}
                commandButtons={commandButtons}
                setCommandButtons={setCommandButtons}
              />
            );
          })}
          <div className="add-delete-command-btns-container">
            {commandButtons.length < availableCommandButtons && (
              <button onClick={addCommandButton}>
                <img src={AddCommandBtn} />
              </button>
            )}
            {commandButtons.length > 3 && (
              <button onClick={deleteCommandButton}>
                <img src={DeleteCommandBtn} />
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="actions-section-container">
        <button
          className="dismiss user-info-action-btn"
          // onClick={resetToDefaults}
          onClick={() => handleEditToneTip()}
        >
          Dismiss
        </button>
        <OrangeButton
          type="button"
          clickEvent={handleSaveToneTip}
          loader={loading}
        >
          Save
        </OrangeButton>
      </div>
    </div>
  );
}

export default EditToneTip;
