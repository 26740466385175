import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CouponsIcon from "../../assets/icons/coupon-icon.svg";
import "./CouponsSettingsButton.scss";
import CouponsCRUDmodal from "../Modals/CouponsCRUDmodal/CouponsCRUDmodal";
import { useState } from "react";

const CouponsSettingsButton = () => {
  const [couponsModal, setCouponsModal] = useState<boolean>(false);
  const couponsSettings = (
    <Tooltip id="coupons-settings-tooltip">
      <strong>Coupons settings</strong>
    </Tooltip>
  );
  return (
    <>
      <CouponsCRUDmodal
        show={couponsModal}
        hideModal={() => {
          setCouponsModal(false);
        }}
      />
      <OverlayTrigger placement="bottom" overlay={couponsSettings}>
        <button
          type="button"
          onClick={() => {
            setCouponsModal(true);
          }}
          className="settings-btn"
        >
          <img src={CouponsIcon} />
        </button>
      </OverlayTrigger>
    </>
  );
};

export default CouponsSettingsButton;
