import { useEffect, useRef, useState } from "react";
import { ToneTipI } from "../../../Interfaces/ToneTip";
import {
  firebaseTimeStampToDate,
  numberFormatter,
} from "../../utils/StringFormatters";
import { FirebaseTimeStampI } from "../../../Interfaces/FirebaseTimeStamp";
import EditButton from "./EditButton";
// import DeleteButton from "./DeleteButton";
import { useToneTipContext } from "../../context/toneTipsContext";
import "./ProduceTable.scss";
import { genWav } from "../../utils/tt-encoder/generateAudioFile";

interface ProduceTableRowI {
  tt: ToneTipI;
  // handleToneTipDelete: () => void;
}

interface UserInfoI {
  displayName: string;
  email: string;
}

export default function ProduceTableRow(props: ProduceTableRowI) {
  const { tt } = props;
  const [isMouseOver, setIsMouseOver] = useState<boolean>(false);
  const [selected, setSelected] = useState<boolean>(false);
  const { selectedToneTip, handleSelectToneTip, handleEditToneTip, adminMode } =
    useToneTipContext();
  const rowRef = useRef<HTMLTableRowElement>(null);

  const handleWAVFileDownload = async (f: number) => {
    if (tt.id) {
      await genWav(tt?.id, tt.fileName || "", f * 1000);
    }
  };

  const handleRowSelect = () => {
    if (selected) {
      handleSelectToneTip();
    } else {
      handleSelectToneTip(tt);
    }
  };

  useEffect(() => {
    if (JSON.stringify(selectedToneTip) === JSON.stringify(tt)) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [selectedToneTip]);

  useEffect(() => {
    const handleMouseOver = () => {
      setIsMouseOver(true);
    };

    const handleMouseOut = () => {
      setIsMouseOver(false);
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (rowRef.current && !rowRef.current.contains(event.target as Node)) {
        setSelected(false);
      }
    };

    const element = rowRef.current;

    // Attach event listeners when the component mounts
    element?.addEventListener("mouseover", handleMouseOver);
    element?.addEventListener("mouseout", handleMouseOut);
    element?.addEventListener("click", handleClickOutside);

    // Clean up event listeners when the component unmounts
    return () => {
      element?.removeEventListener("mouseover", handleMouseOver);
      element?.removeEventListener("mouseout", handleMouseOut);
      element?.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <tr ref={rowRef} key={tt.id}>
      <th
        scope="row"
        className={`${selected ? "selected-row" : "hover-shadow"}`}
        onClick={handleRowSelect}
      >
        <div className="pp-tonetip-cell-container">
          <img src={tt.image} alt="Profile" />
          <span className="tonetip-title">{tt.title}</span>
        </div>
      </th>
      {adminMode && (
        <>
          <td
            className={`${
              selected ? "selected-row" : "hover-shadow"
            } creator-displayName`}
            onClick={handleRowSelect}
          >
            {tt?.creatorDisplayName || ""}
          </td>
          <td
            className={`${selected ? "selected-row" : "hover-shadow"}`}
            onClick={handleRowSelect}
          >
            {tt.creatorEmail || ""}
          </td>
        </>
      )}
      <td
        className={`${selected ? "selected-row" : "hover-shadow"}`}
        onClick={handleRowSelect}
      >
        {firebaseTimeStampToDate(
          tt.createdAt as FirebaseTimeStampI
        )?.toLocaleDateString()}
        <br />
        <span className="time-text">
          {firebaseTimeStampToDate(
            tt.createdAt as FirebaseTimeStampI
          )?.toLocaleTimeString()}
        </span>
      </td>
      <td
        className={`${selected ? "selected-row" : "hover-shadow"}`}
        onClick={handleRowSelect}
      >
        {tt.id}
      </td>
      <td className={`${selected ? "selected-row" : "hover-shadow"} wav`}>
        <button className="wav-btn" onClick={() => handleWAVFileDownload(14)}>
          14
        </button>
        <button className="wav-btn" onClick={() => handleWAVFileDownload(19)}>
          19
        </button>
      </td>
      <td
        className={`${
          selected ? "selected-row centered" : "hover-shadow centered"
        }`}
        onClick={handleRowSelect}
      >
        {numberFormatter(tt.likes)}
      </td>
      <td
        className={`${
          selected ? "selected-row centered" : "hover-shadow centered"
        }`}
        onClick={handleRowSelect}
      >
        {numberFormatter(tt.dislikes)}
      </td>
      <td
        className={`${
          selected ? "selected-row centered" : "hover-shadow centered"
        }`}
        onClick={handleRowSelect}
      >
        {numberFormatter(tt.favorites)}
      </td>
      <td className={`${selected ? "selected-row centered" : "hover-shadow-gradient centered"}`}>
        {numberFormatter(tt.comments)}
      </td>
      <td className={`${selected ? "selected-row centered" : "centered"}`}>
        {
          !isMouseOver || selected ? (
            numberFormatter(tt.views)
          ) : (
            <EditButton clickEvent={() => handleEditToneTip(tt)} />
          )

          // : (
          //   <DeleteButton clickEvent={handleToneTipDelete} />
          // )
        }
      </td>
    </tr>
  );
}
