import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./CheckoutModal.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import Step1Content from "./Step1Content";
import Step2Content from "./Step2Content";
import Step3Content from "./Step3Content";
import FinalStepContent from "./FinalStepContent";
import { useUserContext } from "../../../context/userContext";
import { CheckoutModalPropsI } from "../../../../Interfaces/CheckoutModal";
import PaymentMethodsModal from "../PaymentMethodsModal/PaymentMethodsModal";
import Alert from "react-bootstrap/Alert";

function CheckoutModal(props: CheckoutModalPropsI) {
  const { onHide, show, planInfo, annual } = props;
  const [view, setView] = useState<number>(0);
  const { userPaymentMethod } = useUserContext();
  const [paymentMethodsModal, setPaymentMethodsModal] = useState(false);
  const [paymentMethodAlert, setPaymentMethodAlert] = useState(false);
  const billing_details = userPaymentMethod != null ? userPaymentMethod.billing_details : null;
  const handleMissingPaymentMethod = () => {
    setPaymentMethodAlert(false);
    setPaymentMethodsModal(true);
  };

  const checkForPaymentMethod = async () => {
    if (!userPaymentMethod) {
      onHide();
      setPaymentMethodAlert(true);
    }
  };

  const handleContinue = () => {
    setView((prevState) => prevState + 1);
  };

  const handleGoBack = () => {
    setView((prevState) => prevState - 1);
  };

  const formik = useFormik({
    initialValues: {
      city: billing_details?.address.city || "",
      zipCode: billing_details?.address.postal_code || "",
      state: billing_details?.address.state || "",
      country: billing_details?.address.country || "",
      name: billing_details?.name || "",
      address: `${billing_details?.address.line1}, ${billing_details?.address.line2}` || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string()
        .max(40, "Must be 40 characters or less")
        .required("Required"),
      address: Yup.string().required("Required"),
      city: Yup.string().required("Required"),
      zipCode: Yup.string(),
      state: Yup.string().required("Required"),
      country: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      handleContinue();
    },
  });

  const checkoutStep = () => {
    if (view === 0) {
      return (
        <Step1Content
          onHide={onHide}
          handleContinue={handleContinue}
          planInfo={planInfo}
          annual={annual}
        />
      );
    } else if (view === 1) {
      return (
        <Step2Content
          handleContinue={handleContinue}
          handleGoBack={handleGoBack}
          formik={formik}
        />
      );
    } else if (view === 2) {
      return (
        <Step3Content
          handleContinue={handleContinue}
          handleGoBack={handleGoBack}
          paymentInfo={formik.values}
          planInfo={planInfo}
          annual={annual}
        />
      );
    } else if (view === 3) {
      return <FinalStepContent planInfo={planInfo} onHide={onHide}/>;
    }
  };

  const cleanUp = () => {
    setView(0);
    formik.resetForm();
  };

  // useEffect(() => { // This UseEffect  closes the checkout modal automatically after 3 seconds when subscribing to a plan
  //   if (view === 3) {
  //     const timer = setTimeout(() => {
  //       // Run your function after 3 seconds
  //       onHide();
  //     }, 2000);

  //     // Clean up the timer when the component unmounts or when the view changes
  //     return () => clearTimeout(timer);
  //   }
  // }, [view]);

  return (
    <>
      <Modal show={paymentMethodAlert}>
        <Alert
          variant="warning"
          dismissible
          onClose={handleMissingPaymentMethod}
          style={{marginBottom:"0"}}
        >
          Wati! You don't have a payment method set up, please add one before
          subscribing to any of our awesome plans!
        </Alert>
      </Modal>
      <Modal
        onShow={checkForPaymentMethod}
        show={show}
        onHide={onHide}
        className="checkout-modal"
        onExited={cleanUp}
      >
        {checkoutStep()}
      </Modal>
      <PaymentMethodsModal
        show={paymentMethodsModal}
        onHide={() => setPaymentMethodsModal(false)}
      />
    </>
  );
}

export default CheckoutModal;
