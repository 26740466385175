import {
  PaymentElement,
  useStripe,
  useElements,
  AddressElement,
} from "@stripe/react-stripe-js";
import { FieldOption, Layout } from "@stripe/stripe-js";
import { attachPaymentMethod } from "../../../services/CloudFunctions";
import { useUserContext } from "../../../context/userContext";
import OrangeButton from "../../OrangeButton/OrangeButton";
import { FormEvent, useState } from "react";

interface AddPaymentMethodFormPropsI {
  setAddPaymentMethod: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddPaymentMethodForm = (props: AddPaymentMethodFormPropsI) => {
  const [loader, setLoader] = useState(false);
  const { setAddPaymentMethod } = props;
  const stripe = useStripe();
  const elements = useElements();
  const { currentUser, getPaymentMethod } = useUserContext();
  const [error, setError] = useState<null | string>(null);

  const paymentElementOptions = {
    layout: "tabs" as Layout,
  };
  const addressElementOptions = {
    mode: "billing" as "billing",
    fields: { phone: "always" as "always" },
    display: { name: "split" as "split" },
  };

  const handleSubmit = async (event: any) => {
    setLoader(true);
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setLoader(false);
      return;
    }
    const res = await elements.submit();
    if (res.error) {
      setLoader(false);
      return;
    }

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      elements,
    });
    if (error) {
      setError(error.message ? error.message : null);
      console.log(error);
      setLoader(false);
      return;
    }
    if (paymentMethod) {
      try {
        const attachedPm = await attachPaymentMethod({
          paymentMethodId: paymentMethod.id,
          customerId: currentUser?.stripeId,
        });
        if (attachedPm) {
          await getPaymentMethod();
          setAddPaymentMethod(false);
        }
      } catch (error: any) {
        alert(error.message);
      }
    }
    setLoader(false);
  };

  function checkError(event: FormEvent<HTMLFormElement>): void {
    setError(null);
  }

  return (
    <form onSubmit={handleSubmit} onChange={checkError}>
      <PaymentElement options={paymentElementOptions} />
      <AddressElement options={addressElementOptions} />
      <div style={{ width: "100%", marginBottom: "20px" }} />
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <OrangeButton loader={!stripe || loader} type="submit">
          Submit
        </OrangeButton>
        <p style={{ color: "red" }}>{error}</p>
      </div>
    </form>
  );
};

export default AddPaymentMethodForm;
