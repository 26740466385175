import "./CommandButton.scss";
import GreyWave from "../../assets/img/tone-tip-grey-bg-wave.svg";
import WhiteRedirectArrow from "../../assets/icons/arrow-up-right-icon.svg";
import ClicksIcon from '../../assets/icons/clicks-icon.svg';
import { CommandI } from "../../../Interfaces/ToneTip";
import { numberFormatter } from "../../utils/StringFormatters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faMapLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { increaseClickCount } from "../../services/CloudFunctions";
import { useState } from "react";

interface CommandButtonI {
  values:CommandI
  commandIndex:number
  uarc:string | undefined
}

function CommandButton(props: CommandButtonI) {
  const { values, commandIndex, uarc } = props;
  const [updatingClickCount, setUpdatingClickCount] = useState(false);

  const renderIcon = () => {
    switch (values.action){
      case "Website":
        return <FontAwesomeIcon icon={faLink} />
      case "Location":
        return <FontAwesomeIcon icon={faMapLocationDot} />
      case "Phone":
        return <FontAwesomeIcon icon={faPhone} />
    }
  }

  const handleAction = async () => {
    if (values.value == "" || values.value == null)return
    setUpdatingClickCount(true)
    if (uarc && commandIndex != undefined){
      increaseClickCount({uarc, commandIndex}).finally(() => {
        setUpdatingClickCount(false)
      })
    }
    switch (values.action) {
      case "Website":
        // Navigate to the website
        if (values.value.includes('http')){
          window.open(values.value);
        } else {
          window.open(`https://${values.value.replace("https://", '')}`);
        }

        break;
      case "Phone":
        // Trigger a phone call using the system's default app
        window.location.href = `tel:${values.value}`;
        break;
      case "Location":
        // Search Google Maps for the location
        window.open(`https://maps.google.com/?q=${encodeURIComponent(values.value)}`);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <button
        className="command-btn-container"
        style={{backgroundColor:values?.bgColor, color:values?.textColor} || ""}
        onClick={handleAction}
      >
        {renderIcon()}{values?.text || ""}
        <img src={GreyWave} id="grey-wave" />
        <img src={WhiteRedirectArrow} />
        <span><img id='clicks-icon' src={ClicksIcon}/> : {numberFormatter(values.clicks) || 0}</span>
      </button>
    </>
  );
}

export default CommandButton;
