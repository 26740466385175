import { Accordion, Spinner } from "react-bootstrap";
import "./PaymentMethodCard.scss";
import VisaLogo from "../../assets/img/visa-logo-blue.png";
import AmexLogo from "../../assets/img/amex-logo.png";
import MasterCardLogo from "../../assets/img/mastercard-logo.png";
import DefaultCardIcon from "../../assets/icons/credit-card-icon.svg";
import DiscoverLogo from "../../assets/img/discover-logo.png";
import DinersClubLogo from "../../assets/img/diners-club-logo.png";
import EditButton from "../../assets/img/edit-tonetip-button.svg";
import DeleteButton from "../../assets/img/delete-tonetip-button.svg";
import { detachPaymentMethod } from "../../services/CloudFunctions";
import { useUserContext } from "../../context/userContext";
import { useState } from "react";

const cardBrandLogos: any = {
  visa: VisaLogo,
  amex: AmexLogo,
  mastercard: MasterCardLogo,
  discover: DiscoverLogo,
  diners: DinersClubLogo,
};

const cardBrandText: any = {
  visa: "Visa",
  amex: "American Express",
  mastercard: "Mastercard",
  discover: "Discover",
  diners: "Diners Club",
};

interface AddressI {
  city?: string;
  country?: string;
  line1?: string;
  line2?: string;
  postalCode?: string;
  state?: string;
}

interface PaymentMethodBodyPropsI {
  billingDetails: {
    address: AddressI;
    email: string;
    name: string;
    phone: string;
  };
  handleDetachPaymentMethod: () => Promise<void>;
}

interface PaymentMethodCardPropsI {
  paymentMethod: any;
}

interface PaymentMethodSummaryPropsI {
  brand: string;
  expMonth: number;
  expYear: number;
  last4: string;
}

const formattedBillingAddress = (billingDetails: AddressI): string => {
  const { line1, line2, city, postalCode, country, state } = billingDetails;

  const addressParts: string[] = [];

  if (line1) {
    addressParts.push(line1);
  }

  if (line2) {
    addressParts.push(line2);
  }

  if (city) {
    addressParts.push(city);
  }

  if (state) {
    addressParts.push(state);
  }

  if (postalCode) {
    addressParts.push(postalCode);
  }

  if (country) {
    addressParts.push(country);
  }

  return addressParts.join(", ");
};

const renderCardBrandLogo = (brand: string) => {
  const logoComponent = cardBrandLogos[brand.toLowerCase()] || DefaultCardIcon;
  return <img src={logoComponent} alt={`${brand} Logo`} />;
};

const renderTopDetails = (brand: string, last4: string) => {
  const brandText = cardBrandText[brand.toLowerCase()] || brand;
  return `${brandText} •••• ${last4}`;
};

const PaymentMethodBody = (props: PaymentMethodBodyPropsI) => {
  const { address, email, name, phone } = props.billingDetails;
  const { handleDetachPaymentMethod } = props;
  const [loader, setLoader] = useState(false);

  const handleDetachment = async () => {
    setLoader(true);
    await handleDetachPaymentMethod();
    setLoader(false);
  };
  return (
    <>
      <div className="billing-details-container">
        <h5>Billing details:</h5>
        <p>
          <b>Address:</b>{" "}
          {formattedBillingAddress(address) || "No address provided"}
        </p>
        <p>
          <b>Name:</b> {name || "No name provided"}
        </p>
        <p>
          <b>Phone:</b> {phone || "No phone provided"}
        </p>
      </div>
      <div className="card-actions-btns-container">
        {!loader && (
          <img
            src={DeleteButton}
            alt="Delete button"
            onClick={handleDetachment}
          />
        )}
        {loader && <Spinner animation="border" role="status" />}
      </div>
    </>
  );
};

const PaymentMethodSummary = (props: PaymentMethodSummaryPropsI) => {
  return (
    <div className="card-summary-container">
      <div className="card-brand-container">
        {renderCardBrandLogo(props.brand)}
      </div>
      <div className="card-summary-details-container">
        <div className="card-top-details">
          {renderTopDetails(props.brand, props.last4)}
        </div>
        <div className="card-bottom-details">{`Expires ${props.expMonth}/${props.expYear}`}</div>
      </div>
    </div>
  );
};

const PaymentMethodCard = (props: PaymentMethodCardPropsI) => {
  const { paymentMethod } = props;
  const { getPaymentMethod } = useUserContext();
  const handleDetachPaymentMethod = async () => {
    await detachPaymentMethod({ paymentMethodId: paymentMethod.id });
    await getPaymentMethod();
  };

  return (
    <Accordion.Item eventKey="0">
      <Accordion.Header>
        <PaymentMethodSummary
          brand={paymentMethod.card.brand}
          expMonth={paymentMethod.card.exp_month}
          expYear={paymentMethod.card.exp_year}
          last4={paymentMethod.card.last4}
        />
      </Accordion.Header>
      <Accordion.Body>
        <PaymentMethodBody
          billingDetails={paymentMethod.billing_details}
          handleDetachPaymentMethod={handleDetachPaymentMethod}
        />
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default PaymentMethodCard;
