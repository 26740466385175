import { redirect, useNavigate } from "react-router-dom";
import "./Privacy.scss";
import { useEffect } from "react";
function Privacy() {
  const navigate = useNavigate()
  useEffect(() => {
    window.location.href = "https://tonetip.com/privacy-policy/"
  }, []);


  return null

  return (
    <div className="privacy-policy-container">
      <h1>ToneTip Privacy Policy</h1>
      <h4>Effective date: Nov 1, 2021</h4>
      <h2>1. Introduction</h2>
      <p>
        ToneTip is a service of Tone Command Inc, a company registered in the
        United States. We provide audio hyperlink service and a social media
        platform that allows media producers to connect to their audience. We
        operate the websites at: ToneTip.com, together with the individual
        sub-sites (TT.ToneTip.com) created by our media producers (the
        "Websites"). The term ToneTip and ToneTip.com refer to services offered
        by Tone Command Inc. WwwApp and Social media users are referred to as
        users. Media Producers that create ToneTip audio hyperlinks and
        associated ToneTip screens are referred to as “Producers”. Collectively,
        any entity that uses any ToneTip service, website, or audio hyperlink
        creation service, is referred to as a “user”.
      </p>
      <p>
        This Privacy Policy explains what personal information ToneTip collects
        from customers who use our Services (including any ToneTip app users,
        media producers, social media page users, and other users: “Users”) and
        why, what we do with that information and how we share it.
      </p>
      <p>
        This policy does not set out the information collected by Producers in
        the individual ToneTips. That information is defined by the privacy
        policies of individual Producers. Any privacy information related to a
        specific ToneTip will be directed to the Producer that created that
        ToneTip.
      </p>
      <p>
        Our Privacy Officer may be contacted using the contact details set out
        at the bottom of this policy.
      </p>
      <h2>2. Choice</h2>
      <p>
        It is always a User’s choice whether or not to provide Personal
        Information. However, if a User chooses not to provide requested
        Personal Information, that User will not be able to use certain features
        of the Services. We set out in detail below the information that we
        collect about Users and Users’ use of our Services. ToneTip does not
        intentionally collect things like protected health information,
        financial information, date of birth, social security or other
        government-issued identification number, and other sensitive personal
        information from Users.
      </p>
      <h2>3. The Information We Collect About Users</h2>
      <p>
        Personal data, or personal information, means any information relating
        to an identifiable person who can be directly or indirectly identified.
        It does not include data which cannot be related to an identifiable
        person (anonymous data).
      </p>
      <p>
        We may collect, use, store and transfer different kinds of personal
        information about Users which we have grouped together follows:
      </p>
      <ul>
        <li>
          Identity Data includes first name, last name or similar identifier.
        </li>
        <li>Contact Data includes email address and telephone numbers.</li>
        <li>
          Technical Data includes internet protocol (IP) address, app type,
          phone type, browser type and version, operating system and platform.
        </li>
        <li>
          Usage Data includes information about how Users use our Services, such
          as browsing actions, pages visited, buttons, controls, Users click on,
          pages that Users visit, time spent on those pages, User’s search
          queries, history list, favorites list, shares, likes, dislikes, other
          social functions, dates and times of User’s visits, and app usage.
        </li>
      </ul>
      <p>
        We also collect, use and share Non-personalized data called “Service
        Data” such as statistical or demographic data for any purpose. Service
        Data may be derived from User’s personal information but is not
        considered personal information in law as this information does not
        directly or indirectly reveal User’s identity. For example, we may
        aggregate User’s Usage Data to calculate the percentage of users
        accessing a specific app or website feature.
      </p>
      <p>
        We do not collect any Special Categories of Personal Data about Users
        (this includes details about User’s race or ethnicity, religious or
        philosophical beliefs, sex life, sexual orientation, political opinions,
        union membership, information about User’s health and genetic and
        biometric data, criminal convictions and offences).
      </p>
      <h2>4. How Is User’s Personal Information Collected</h2>
      <p>
        We use different methods to collect data from and about Users including
        through:
      </p>
      <ul>
        <li>
          Direct interactions. Users may give us User’s Identity, Contact and
          Profile Data by registering for the Service and completing fields
          within the Service
        </li>
        <li>
          Automated technologies or interactions. As Users interact with our
          Service, we may automatically collect Technical and Usage Data about
          User’s equipment, browsing actions and patterns. We collect this
          personal data by using cookies, server logs and other similar
          technologies.
        </li>
        <li>
          Third parties or publicly available sources. We may receive personal
          information about Users from various third parties and public sources
          as set out below:
        </li>
      </ul>
      <p>Technical Data from the following parties:</p>
      <ul>
        <li>analytics providers such as Google Analytics.</li>
        <li>search information providers such as Google.</li>
      </ul>
      <h2>5. Purposes for Which We Will Use User’s Personal Information</h2>
      <p>
        Below is a description of all the ways we plan to use Users’ personal
        information.
      </p>
      <ul>
        <li>
          To deliver the Services to Users (including by recognizing users
          across the devices they use, and providing, maintaining, tailoring and
          improving app, website and services)
        </li>
        <li>To register Users as an WwwApp/Social Media User</li>
        <li>To register Users as Media Producers</li>
        <li>
          To manage our relationship with Users which will include Notifying Use
          about changes to our Terms of Use or Privacy Policy
        </li>
        <li>
          To administer and protect our business and this the Service (including
          troubleshooting, data analysis, testing, system maintenance, support,
          reporting and hosting of data)
        </li>
        <li>
          To use data analytics to improve our services, user relationships and
          experiences and to personalize ToneTip services
        </li>
        <li>
          To make suggestions and recommendations to User about services that
          may be of interest to Users and to provide Users with information,
          products or services they request
        </li>
        <li>To improve our Services including:</li>
        <li>To track app actions, web page visits and form submissions</li>
        <li>To inform ToneTip and Producers as to what content is effective</li>
        <li>To flag and remove offensive/inaccurate content and Producers</li>
        <li>To provide WwwApp and Website updates</li>
        <li>To personalize ToneTip services</li>
      </ul>
      <h2>6. Disclosures</h2>
      <p>
        We may disclose Personal Information that we collect, or Users provide
        as described in this Privacy Policy:
      </p>
      <ul>
        <li>
          To contractors, service providers, and other third parties we use to
          support our business solely for the purpose of them providing services
          to us and our customers.
        </li>
        <li>To fulfill the purpose for which Users provide it.</li>
        <li>
          For any other purpose expressly disclosed by us when Users provide the
          information.
        </li>
        <li>
          With User’s express consent to other uses not indicated in this
          Privacy Policy.
        </li>
        <li>To our subsidiaries and affiliates where they are providing.</li>
      </ul>
      <p>We may also disclose User’s Personal Information:</p>
      <ul>
        <li>
          To comply with any court order, law or legal process, including to
          respond to any government or regulatory request.
        </li>
        <li>
          To enforce or apply our terms of service Terms of Use and other
          agreements, including for billing and collection purposes.
        </li>
        <li>
          If we believe disclosure is necessary or appropriate to protect the
          rights, property, or safety of ToneTip, our customers, or others. This
          includes exchanging information with other companies and organizations
          for the purposes of fraud protection and credit risk reduction.
        </li>
      </ul>
      <h2>7. Access</h2>
      <p>
        Upon request, ToneTip will provide Users with information about the
        content of any of User’s Personal Information. ToneTip. If Users have
        any concerns or suspect unauthorized activities associated with User’s
        account, please contact us at the contact email address below. We will
        respond to User’s request within a reasonable timeframe. ToneTip
        acknowledges that Users have the right to access, update, revoke
        permission to process, or request the deletion of User’s Personal
        Information. Any such query can be directed to ToneTip’s Data Protection
        Officer at the email address below. If requested to remove data under
        either scenario, we will respond within a reasonable timeframe.
      </p>
      <h2>8. Security and Enforcement</h2>
      <p>
        Security of User’s Personal Information is important to us. We use
        security safeguards appropriate to the sensitivity of the information to
        ensure that User’s Personal Information is stored and maintained in a
        secure environment. ToneTip exercises managerial and technical
        safeguards designed to protect against the loss or unauthorized use or
        disclosure of Personal Information belonging to our Users. These
        safeguards include encryption, malware protection, logical and physical
        access controls, and detection of social engineering attacks.
      </p>
      <p>
        ToneTip monitors and reviews security safeguards and takes corrective
        action whenever we discover deviations from our Privacy Policy.
      </p>
      <h2>9. Managing the Online Collection of Additional Data</h2>
      <p>
        As Users navigate through and interact with our app, website, and
        services, we may use automatic data collection technologies to collect
        certain information about User’s equipment, browsing actions and
        patterns, location, and workplace usage patterns, including:
      </p>
      <ul>
        <li>
          Details of User’s visits to our app, website and other communication
          data and the resources that Users access and use on the WwwApp or
          website.
        </li>
        <li>
          Information about User’s computer and Internet connection, including
          User’s IP address, operating system, and browser type.
        </li>
        <li>
          We also may use these technologies to collect information about User’s
          online activities over time and across third-party websites or other
          online services.
        </li>
        <li>
          We may collect information about User’s mobile device and Internet
          connection, including the device’s unique device identifier, IP
          address, operating system, browser type, mobile network information
          and the device’s telephone number.
        </li>
        <li>
          Our WwwApp collects real-time information about the location of User’s
          device.
        </li>
      </ul>
      <p>
        We and our third-party service providers may collect information about
        use in a variety of ways. We and/or our third-party partners may employ
        various tracking technologies, such as cookies, web beacons and
        analytics software, that help us better manage content on our Service by
        informing us what content is effective.
      </p>
      <h3>9.1. Cookies</h3>
      <p>
        When Users visit our website or otherwise interact with our Services, we
        (or third-party data or ad networks we work with) may send one or more
        “cookies” to User’s app, computer or other devices. Cookies are
        alphanumeric identifiers stored on User’s computer through User’s web
        browser and are used by most websites to help personalize User’s web
        experience. Some cookies may facilitate additional site features for
        enhanced performance and functionality such as remembering preferences,
        allowing social interactions, analyzing usage for site optimization,
        providing custom content, allowing third parties to provide social
        sharing tools, and serving images or videos from third-party websites.
        Some features on this site will not function if Users do not allow
        cookies. We may link the information we store in cookies to any Personal
        Information Users submit while on our site. We may use both session ID
        cookies and persistent cookies. A session ID cookie expires when Users
        close User’s browser. A persistent cookie remains on User’s hard drive
        for an extended period of time. Persistent cookies enable us to track
        interests of our users to enhance the experience on our site. If Users
        do not want information collected through the use of cookies, there is a
        simple procedure in most browsers that allows Users to automatically
        decline cookies or be given the choice of declining or accepting the
        transfer to User’s computer of a particular cookie (or cookies) from a
        particular site. Users may also wish to refer to
        https://www.allaboutcookies.org/manage-cookies/index.html. If Users
        reject cookies, Users may still use our site, but some features on the
        site will not function properly.
      </p>
      <p>
        Functional cookies, persistent and session type, store information to
        enable core site functionality.
      </p>
      <p>
        Analytics cookies allow us to count page visits and traffic sources, so
        we can measure and improve the performance of our site and our marketing
        campaigns.
      </p>
      <h3>9.2. Web Beacons</h3>
      <p>
        We (or third-party data or ad networks we work with) may use Web Beacons
        alone or in conjunction with cookies to compile information about our
        Service, or other information we or they have collected. Web Beacons are
        tiny graphic objects that are embedded in a web page or email and are
        usually invisible to the user but allow checking that a user has viewed
        the page or email. Web Beacons may be used within the Service to track
        email open rates, web page visits or form submissions. In some cases, we
        tie the information gathered by Web Beacons to our Users’ Personal
        Information. For example, we use clear gifs in our HTML-based emails to
        let us know which emails have been opened by recipients. This allows us
        to gauge the effectiveness of certain communications and the
        effectiveness of our marketing campaigns.
      </p>
      <h3>9.3. HTML5 Storage</h3>
      <p>
        We may use local shared objects or local storage, such as HTML5, to
        store content information and preferences. Third parties with whom we
        partner to provide certain features on our website upon User’s web
        browsing activity may also use HTML5 to collect and store information.
        Various browsers may offer their own management tools for removing
        HTML5.
      </p>
      <h2>10. Transfers of Data</h2>
      <p>
        ToneTip’s headquarters in the United State and is our primary location
        for business operations. Our primary servers hosting the ToneTip
        platform are located in the US and redundant locations around the world.
        In order to provide Users with the information, products, or services
        Users have requested, personal data may be transferred or shared with
        other companies within our family of companies, including those
        third-party vendors who act on our behalf, process personal data in
        accordance with the purposes for which the data was originally
        collected, or for purposes to which Users have subsequently consented.
        Our Privacy Policy is designed to provide equivalent data protection for
        all Users wherever they may reside.
      </p>
      <p>
        ToneTip may engage a third-party to support our billing, support
        services, information technology, or mailings on our behalf.
      </p>
      <p>
        We may also disclose User’s Personal information as required by law,
        such as to comply with a subpoena or other legal process, when we
        believe in good faith that disclosure is necessary to protect our
        rights, protect User’s safety, or the safety of others, investigate
        fraud, or respond to a government request.
      </p>
      <h2>11. Outside Parties and Sites</h2>
      <p>
        Through us, you may be introduced to a variety of third-party vendors,
        apps, websites, and service providers. The privacy policies of these
        third parties are not under our control and may differ from ours. The
        use of any information that Users may provide to any third-party, or the
        use of “cookie” technology by any third-party, will be governed by the
        privacy policy of the operator of the website that Users are visiting.
        If Users have any doubts about the privacy of the information Users are
        providing on another website, we recommend that Users contact that
        website directly for more information and review its privacy policy. To
        properly process online orders, Users may be directed to a third-party
        credit card processing or other payment processing website for payment
        of our services.
      </p>
      <h2>12. Additional Privacy Protections for Minors</h2>
      <p>
        We join the industry in recognizing that children, including young
        teens, may not be able to make informed choices about Personal
        Information requested online. ToneTip does not knowingly solicit or
        collect Personal Information targeted at those under the age of
        thirteen. ToneTip’s Terms of Use (TOU) does not permit the use of the
        website and/or Services by users under the age of thirteen.
      </p>
      <h2>13. Data Retention</h2>
      <p>
        ToneTip will keep User’s Personal Information for as long as it remains
        necessary for the identified purposes in Section 5 or as required by
        law, which may extend beyond the termination of our relationship with
        User. Users acknowledge and agree that if User request that User’s name
        be removed from our databases, it may not be possible to completely
        delete all User’s Personal Information due to legal constraints.
      </p>
      <p>
        <b>Message & file retention</b>
      </p>
      <p>
        For any community, the default post and file retention setting is to
        keep everything for as long as the community exists. Producers may edit
        and delete ToneTip commands and links. Offensive, inaccurate, and false
        content may be removed at any time as defined in our Terms of Use (TOU)
        policy.
      </p>
      <p>
        <b>Edited and deleted messages</b>
      </p>
      <p>
        Users and moderators may edit or delete messages and ToneTip commands.
        Only edited version will remain viewable to the public.
      </p>
      <h2>14. RESIDENTS OF THE EUROPEAN UNION (EU)</h2>
      <p>
        ToneTip generally processes Personal Information in order to fulfill
        contracts we have with our customers and end users and to pursue our
        legitimate business interests listed above. Otherwise, ToneTip will
        obtain consent from an individual with respect to the processing of
        Personal Information if required by law to do so.
      </p>
      <p>
        Further, individuals located in the EU have certain rights under
        European law (including under the General Data Protection Regulation)
        with respect to Personal Information, including the right to request
        access to, obtain, correct, amend, delete, or limit the use of User’s
        personal data. Individual end users, customers or prospective customers
        located in the EU who wish to exercise these rights, should contact
        ToneTip using the contact information below. If we are processing User’s
        information on behalf of a third party then User should contact that
        party directly because ToneTip serves as a data processor on behalf of
        third party in that instance and can only forward User’s request to the
        service provider to allow them to respond. Individuals also have the
        right to make complaints to regulatory authorities in respect of our
        privacy practices.
      </p>
      <h2>15. Changes to this Privacy Policy</h2>
      <p>
        ToneTip may make changes to this Privacy Policy from time to time for
        any reason. Use of information we collect now is subject to the Privacy
        Policy in effect at the time such information is used. If we make any
        material changes or changes in the way we use information, we will
        notify customers via e-mail or by posting an announcement on the website
        prior to the change becoming effective. website users are bound by any
        changes to the Privacy Policy after such changes have been posted.
      </p>
      <p>
        In the event that ToneTip is involved in a merger, acquisition or asset
        sale, we will continue to ensure the confidentiality of any Personal
        Information and give affected users notice before Personal Information
        is transferred or becomes subject to a different privacy policy. Account
        holders will be notified via email and/or a prominent notice on our
        website of any change in ownership or uses of User’s information, as
        well as any choices Use may have regarding User’s information.
      </p>
      <h2>16. Contact information.</h2>
      <p>Data Protection Officer</p>
      <p>Tone Command, Inc.</p>
      <p>TTadmin@ToneTip.com</p>
    </div>
  );
}

export default Privacy;
