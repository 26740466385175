import "./ProduceTable.scss";
import DownloadIcon from "../../assets/icons/download-icon.svg";
import ThumbDownIcon from "../../assets/icons/thumb-down-icon.svg";
import ThumbUpIcon from "../../assets/icons/thumb-up-icon.svg";
import HeartIcon from "../../assets/icons/favorite-heart-icon.svg";
import ForumIcon from "../../assets/icons/forum-icon.svg";
import EyeIcon from "../../assets/icons/eye-visibility-icon.svg";
// import { useEffect, useRef, useState } from "react";
// import DeleteToneTipModal from "../Modals/DeleteToneTipModal/DeleteToneTipModal";
import { ToneTipI } from "../../../Interfaces/ToneTip";
import ProduceTableRow from "./ProduceTableRow";
import { SortingCriteriaI, useToneTipContext } from "../../context/toneTipsContext";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";


export interface FetchedIdI {
  id: string;
  displayName: string;
  email: string;
}

const ProduceTable = () => {
  const { toneTips, adminMode, sortOrder, setSortOrder } = useToneTipContext();
  // const [deleteToneTipModal, setDeleteToneTipModal] = useState<boolean>();

  // const handleToneTipDelete = () => {
  //   setDeleteToneTipModal(true);
  // };
  
  const handleToggleOrder = (criteria: keyof SortingCriteriaI) => {
    if (sortOrder.criteria === criteria) {
      setSortOrder((prevSortOrder) => ({
        ...prevSortOrder,
        ascending: !prevSortOrder.ascending,
      }));
    } else {
      setSortOrder({
        criteria: criteria,
        ascending: true,
      });
    }
  }
  
  const getSortingArrow = (criteria:string) => {
    if (sortOrder.criteria === criteria) {
      return <FontAwesomeIcon icon={sortOrder.ascending ? faArrowUp : faArrowDown}/>;
    }
    return null;
  };

  // useEffect(() => {
  //   console.log(fetchedIds)
  // }, [fetchedIds]);

  return (
    <div className="produce-table-container">
      {/* <DeleteToneTipModal
        show={deleteToneTipModal}
        onHide={() => setDeleteToneTipModal(false)}
      /> */}
      <table className="table">
        <thead>
          <tr>
            <th scope="col" onClick={() => {handleToggleOrder("title")}}>TONETIP {getSortingArrow('title')}</th>
            {adminMode && (
              <>
                <th>CREATOR</th>
                <th>EMAIL</th>
              </>
            )}
            <th scope="col" onClick={() => {handleToggleOrder("createdAt")}}>DATE {getSortingArrow('createdAt')}</th>
            <th scope="col" onClick={() => {handleToggleOrder("id")}}>
              {/* <img src={DownloadIcon} alt="Download icon" /> */}
              {/*Button to download? */}
              <span>TAG #</span> {getSortingArrow('id')}
            </th>
            <th scope="col">
              WAV
            </th>
            <th scope="col" className="centered icon-header" onClick={() => {handleToggleOrder("likes")}}>
              <img src={ThumbUpIcon} alt="Thumbs up icon" /> {getSortingArrow('likes')}
            </th>
            <th scope="col" className="centered icon-header" onClick={() => {handleToggleOrder("dislikes")}}>
              <img src={ThumbDownIcon} alt="Thumbs down icon" /> {getSortingArrow('dislikes')}
            </th>
            <th scope="col" className="centered icon-header" onClick={() => {handleToggleOrder("favorites")}}>
              <img src={HeartIcon} alt="Favorite icon" />  {getSortingArrow('favorites')}
            </th>
            <th scope="col" className="centered icon-header" onClick={() => {handleToggleOrder("comments")}}>
              <img src={ForumIcon} alt="Forum icon" /> {getSortingArrow('comments')}
              {/*Represents total comments on that tone tip */}
            </th>
            <th scope="col" className="centered icon-header" onClick={() => {handleToggleOrder("views")}}>
              <img src={EyeIcon} alt="Visualizations icon" /> {getSortingArrow('views')}
              {/*Number of times this tonetip has been decoded */}
            </th>
          </tr>
        </thead>
        <tbody>
          {toneTips &&
            toneTips.map((tt: ToneTipI, index) => {
              return (
                <ProduceTableRow
                  tt={tt}
                  // handleToneTipDelete={handleToneTipDelete}
                  key={tt.id}
                />
              );
            })}
        </tbody>
      </table>
    </div>
  );
};
export default ProduceTable;
