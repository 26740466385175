import { useFormik } from "formik";
import * as Yup from "yup";
import "./LogIn.scss";
import OrangeButton from "../OrangeButton/OrangeButton";
import EyeIcon from "../../assets/icons/EyeIcon.svg";
import { useNavigate } from "react-router-dom";
// import AppleIcon from "../../assets/icons/apple-login-icon.svg";
import GoogleIcon from "../../assets/icons/google-login-icon.svg";
import FacebookIcon from "../../assets/icons/facebook-login-icon.svg";
import { useUserContext } from "../../context/userContext";
import {
  resetPassword,
  updateLastLogin,
} from "../../services/Users/LoginRegisterServices";
import { useState } from "react";

const SignupSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().required("Required"),
});

const LogIn = (props: any) => {
  const [loader, setLoader] = useState(false);
  const [passwordReset, setpasswordReset] = useState(false);
  const [prLoader, setPrLoader] = useState(false);
  const navigate = useNavigate();
  const { setLogIn } = props;

  const { simpleSignIn, signInWithGoogle, signInWithFacebook } =
    useUserContext();

    const handlePasswordReset = async (e:any) => {
      try {
        setPrLoader(true);
        e.preventDefault();
        await resetPassword(e.target.emailForReset.value);
      } catch (error) {
        
      } finally {
        setPrLoader(false);
      }
    }

  const handleGoogleSignIn = async () => {
    let res: any = await signInWithGoogle();
    if (res) {
      await updateLastLogin(res.user.uid, +res.user.metadata.lastLoginAt);
      navigate("/produce");
    } else return;
  };

  const handleFacebookLogin = async () => {
    let res: any = await signInWithFacebook();
    if (res) {
      navigate("/produce");
    } else return;
  };

  // const handleAppleLogin = async () => {
  //   let res:any = await signInWithApple();
  //   if (res){
  //     navigate('/produce');
  //   } else return;
  // }

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      showPassword: false,
    },
    validationSchema: SignupSchema,
    onSubmit: async (values) => {
      try {
        setLoader(true);
        const successfulLogin = await simpleSignIn(
          values.email,
          values.password
        );
        if (successfulLogin) {
          await updateLastLogin(
            successfulLogin.user.uid,
            +successfulLogin.user.metadata.lastLoginAt
          );
          navigate("/produce");
        }
      } catch (error) {
      } finally {
        setLoader(false);
      }
    },
  });

  return (
    <div className="register-form-container">
      <h1 className="rf-title">Welcome back!</h1>
      <p className="rf-subtitle">
        Let’s create something great... if you don’t have an account,{" "}
        <span onClick={() => setLogIn(false)}>click here</span>
      </p>
      <form onSubmit={formik.handleSubmit}>
        <div className="rf-input-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Type your email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.email && formik.errors.email && (
            <div className="formik-warning">{formik.errors.email}</div>
          )}
        </div>

        <div className="rf-input-group">
          <label htmlFor="password">Password</label>
          <input
            type={formik.values.showPassword ? "text" : "password"}
            id="password"
            name="password"
            placeholder="Type your password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          {formik.touched.password && formik.errors.password && (
            <div className="formik-warning">{formik.errors.password}</div>
          )}
          <button
            type="button"
            onClick={() =>
              formik.setFieldValue("showPassword", !formik.values.showPassword)
            }
            id="toggle-show-password"
          >
            <img src={EyeIcon} alt="Eye icon" />
          </button>
        </div>

        <div className="submit-container">
          <OrangeButton type="submit" loader={loader}>
            Log in
          </OrangeButton>
        </div>
      </form>
      <div className="login-form-divider-container">
        <div className="line"></div>
        <div className="text">or do it with other accounts</div>
        <div className="line"></div>
      </div>
      <div className="login-alternatives-container">
        <img src={GoogleIcon} alt="Google" onClick={handleGoogleSignIn} />
        {/* <img src={FacebookIcon} alt="Facebook" onClick={handleFacebookLogin}/> */}
        {/* <img src={AppleIcon} alt="Apple" onClick={handleAppleLogin}/> */}
      </div>
      <p className="rf-subtitle reset-password">
        If you wish to reset your password,{" "}
        <span onClick={() => setpasswordReset(true)}>click here</span>
      </p>
      {passwordReset && (
        <form onSubmit={handlePasswordReset}>
          <div className="rf-input-group">
            <label htmlFor="emailForReset">Email</label>
            <input
              type="emailForReset"
              id="emailForReset"
              name="emailForReset"
              placeholder="Email to reset password"
            />
            <div className="submit-container" style={{marginTop:"1rem"}}><OrangeButton loader={prLoader} type="submit">Reset</OrangeButton></div>
          </div>
        </form>
      )}
    </div>
  );
};

export default LogIn;
