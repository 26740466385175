import { Modal, ProgressBar } from "react-bootstrap";
import OrangeButton from "../../OrangeButton/OrangeButton";
import GoBackArrow from "../../../assets/img/go-back-arrow.svg";
import "./Step3Content.scss";
import CouponArrow from "../../../assets/icons/arrow-up-right-icon-orange.svg";
import { useUserContext } from "../../../context/userContext";
import {
  cancelSubscription,
  createSubscription,
  getSingleCoupon,
} from "../../../services/CloudFunctions";
import { useEffect, useState } from "react";
import ApplyCoupon from "../../ApplyCoupon/ApplyCoupon";
import { changeUserActiveStatus } from "../../../services/Users/UserGeneralServices";
import { capitalizeFirstLetter } from "../../../utils/StringFormatters";

function Step3Content(props: any) {
  const { handleContinue, handleGoBack, paymentInfo, planInfo, annual } = props;
  const { currentUser, userPaymentMethod, refreshUser } = useUserContext();
  const [couponId, setCouponId] = useState<string>();
  const [applyCoupon, setApplyCoupon] = useState(false);
  const [couponError, setCouponError] = useState<string | undefined>();
  const [loader, setLoader] = useState(false);

  const testCouponExistance = async (couponCode: string) => {
    try {
      setCouponError(undefined);
      const { data: coupon } = await getSingleCoupon(couponCode);
      let successText;
      if (coupon.valid === false) {
        successText = "This coupon is no longer valid";
      } else {
        setCouponId(coupon.id);
        successText = coupon.amount_off
          ? `Coupon found, enjoy ${coupon.amount_off / 100}$ off!`
          : `Coupon found, enjoy ${coupon.percent_off}% off!`;
      }
      setCouponError(successText);
    } catch (error: any) {
      setCouponError(error.message);
      // console.log(error.message);
    }
  };

  const handleSubscriptionPayment = async () => {
    try {
      setLoader(true);
      if (currentUser?.stripeId) {
        const priceId = annual
          ? planInfo.pricePerYearId
          : planInfo.pricePerMonthId;
        const stripeId = currentUser.stripeId;
        if (currentUser.trialTaken && planInfo.planType.toLowerCase().includes("trial")){
          throw new Error("You already took a trial period, choose any of the other plans please.")
        }
        const subscription = await createSubscription({
          customerId: stripeId,
          priceId: priceId,
          couponId: couponId,
          ...(planInfo.planType.toLowerCase().includes("trial") ? {trial: true} : {})
        });
        if (
          subscription.data.status === "active" ||
          subscription.data.status === "trialing"
        ) {
          // await refreshUser();
          handleContinue();
        } 
        // else if (subscription.data.status === "incomplete") {
        //   const canceledSubscription = await cancelSubscription(
        //     subscription.data.id
        //   );
        //   if (canceledSubscription.data.status === "incomplete_expired") {
        //     alert(
        //       "Subscription creation failed because of failed payment, please change your payment method and try again."
        //     );
        //   }
        // }
      }
    } catch (error: any) {
      // console.log(error.message);
      alert(error.message);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    return () => {
      setCouponId(undefined);
    };
  }, []);

  return (
    <Modal.Body>
      <div className="goback-title-container">
        <img src={GoBackArrow} onClick={handleGoBack} />
        <span className="step-3-title">Order summary</span>
      </div>
      <ProgressBar now={67} />
      <div className="step-3-content-container">
        <div className="plan-and-cost-container">
          <span className="plan-name">{`${planInfo.planType} plan`}</span>
          <span className="plan-cost">
            $
            {annual
              ? planInfo.pricePerYear.toLocaleString()
              : planInfo.pricePerMonth}
          </span>
        </div>
        <span className="apply-cupon-btn" onClick={() => setApplyCoupon(true)}>
          Apply coupon <img src={CouponArrow} />
        </span>
        {applyCoupon && (
          <ApplyCoupon testCouponExistance={testCouponExistance} />
        )}
        <p style={{ color: "red" }}>{couponError && couponError}</p>
        <div className="checkout-info-container">
          <div className="orange-bar"></div>
          <div className="title-data-container">
            <span className="title">Account</span>
            <span className="data">{`${currentUser?.name?.firstName} ${currentUser?.name?.lastName}`}</span>
            <span className="data">{currentUser?.email || ""}</span>
          </div>
          <span className="edit-data-btn" onClick={handleGoBack}>
            Edit &#8680;
          </span>
        </div>
        <div className="checkout-info-container">
          <div className="orange-bar"></div>
          <div className="title-data-container">
            <span className="title">Billing</span>
            <span className="data">{`${userPaymentMethod.billing_details.name} `}</span>
            <span className="data">{paymentInfo.address}</span>
            <span className="data">{`${paymentInfo.city}, ${paymentInfo.state}, ${paymentInfo.country}`}</span>
          </div>
          <span className="edit-data-btn" onClick={handleGoBack}>
            Edit &#8680;
          </span>
        </div>
        <div className="checkout-info-container">
          <div className="orange-bar"></div>
          <div className="title-data-container">
            <span className="title">Payment</span>
            <span className="data">{`${capitalizeFirstLetter(
              userPaymentMethod.card.brand
            )} endind in ${userPaymentMethod.card.last4}`}</span>
          </div>
          <span className="edit-data-btn" onClick={handleGoBack}>
            Edit &#8680;
          </span>
        </div>
      </div>
      <div className="next-btn-container">
        <OrangeButton type="button" clickEvent={handleSubscriptionPayment} loader={loader}>
          Pay $
          {annual
            ? planInfo.pricePerYear.toLocaleString()
            : planInfo.pricePerMonth}
          .00 & subscribe
        </OrangeButton>
      </div>
    </Modal.Body>
  );
}

export default Step3Content;
