import "./ToneTipPreviewCard.scss";
import VisibilityIcon from "../../assets/icons/EyeIconWhite.svg";
import CommentIcon from "../../assets/icons/comment-icon.svg";
import ShareIcon from "../../assets/icons/share-icon.svg";
import { useUserContext } from "../../context/userContext";
import DefaultTtImg from "../../assets/img/new-tonetip-default.svg";
import { useToneTipContext } from "../../context/toneTipsContext";
import { firebaseTimeStampToDate } from "../../utils/StringFormatters";
import { FirebaseTimeStampI } from "../../../Interfaces/FirebaseTimeStamp";
import { getUserDisplayNameAndEmailById } from "../../services/Users/UserGeneralServices";

function ToneTipPreviewCard(props: any) {
  const { titleValue, imagePreview, updateImage, notEditable } = props;
  const { currentUser } = useUserContext();
  const { selectedToneTip } = useToneTipContext();
  const exampleTime = new Date().toLocaleTimeString("en-US");

  return (
    <div className="preview-card-container">
      <div className="picture-container">
        {!notEditable && <div className="hover-image-preview" onClick={updateImage}>Click to update image!</div>}
        <img src={imagePreview || selectedToneTip?.image || DefaultTtImg} />
      </div>
      <div className="preview-right-part-container">
        <div className="title-author-container">
          <span className="tone-tip-title">
            {titleValue || selectedToneTip?.title || "Add a title!"}
          </span>
          <span className="tone-tip-author">{selectedToneTip?.creatorDisplayName}</span>
        </div>
        <div className="actions-date-container">
          <div className="actions-part">
            <img src={CommentIcon} />
            <img src={ShareIcon} />
            <img src={VisibilityIcon} />
          </div>
          <div className="time-part">
            {firebaseTimeStampToDate(
              selectedToneTip?.createdAt as FirebaseTimeStampI
            )?.toLocaleTimeString() || exampleTime}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ToneTipPreviewCard;
