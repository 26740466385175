import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";

export async function getUsageFeeNotice() {
  try {
    const ufnRef = doc(db, "Miscellaneous", "UsageFeeNotice");
    const ufn = await getDoc(ufnRef);
    if (ufn.exists()) {
      return ufn.data().value;
    } else {
      throw new Error("Usage Fee Notice not found");
    }
  } catch (error: any) {
    throw new Error(error.message);
  }
}

export async function updateUsageFeeNotice(newUfn: string) {
  try {
    const ufnRef = doc(db, "Miscellaneous", "UsageFeeNotice");
    await setDoc(ufnRef, { value: newUfn });
    return true;
  } catch (error: any) {
    throw new Error(error.message);
  }
}
