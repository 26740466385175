import { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ImpressionsIcon from "../../assets/icons/EyeIcon.svg";
import ImpressionsExcessModal from "./ImpressionsExcessModal";

const ImpressionsExcessButton = () => {
    const [impressionsExcessModal, setImpressionsExcessModal] = useState(false);
    const pricePlansTooltip = (
      <Tooltip id="price-plans-tooltip">
        <strong>Change extra impressions cost</strong>
      </Tooltip>
    );
  return (
    <>
    <ImpressionsExcessModal
      show={impressionsExcessModal}
      hideModal={() => {
        setImpressionsExcessModal(false);
      }}
    />
    <OverlayTrigger placement="bottom" overlay={pricePlansTooltip}>
      <button type="button" onClick={() => {setImpressionsExcessModal(true)}} className="settings-btn">
        <img src={ImpressionsIcon} style={{width:"70%"}}/>
      </button>
    </OverlayTrigger>
  </>
  )
}

export default ImpressionsExcessButton
