import { Button, Modal, Spinner } from "react-bootstrap";
import "./ManageSubscriptionModal.scss";
import {
  cancelSubscription,
  retryPayment,
} from "../../../services/CloudFunctions";
import { useUserContext } from "../../../context/userContext";
import { useEffect, useState } from "react";
import { format, fromUnixTime } from "date-fns";
import { capitalizeFirstLetter } from "../../../utils/StringFormatters";
import { useNavigate } from "react-router-dom";
import Stripe from "stripe";
import OrangeButton from "../../OrangeButton/OrangeButton";

interface ManageSubscriptionModalPropsI {
  onHide: React.Dispatch<React.SetStateAction<boolean>>;
  show: boolean;
}

const ManageSubscriptionModal = (props: ManageSubscriptionModalPropsI) => {
  const [loader, setLoader] = useState(false);
  const { show, onHide } = props;
  const { userSubscription, refreshUser, currentUser } = useUserContext();
  const [latestInvoice, setLatestInvoice] = useState<Stripe.Invoice>();
  const [invoiceNeedsPayment, setInvoiceNeedsPayment] = useState(false);
  const navigate = useNavigate();

  const handleSubscriptionCancellation = async () => {
    try {
      setLoader(true);
      const confirmed = window.confirm(
        "Are you sure you want to cancel your subscription? This cannot be undone, and you will lose all your information regarding ToneTip."
      );

      if (!confirmed) {
        setLoader(false);
        return;
      }

      setLoader(true);

      if (userSubscription) {
        const canceledSub = await cancelSubscription(userSubscription.id);
        //await refreshUser();
      }

      setLoader(false);
    } catch (error) {
      console.error("An error occurred:", error);
      setLoader(false);
    } finally {
      setLoader(false);
    }
  };

  const handleChangePlan = () => {
    onHide(false);
    navigate("/upgrade");
  };

  const handleAttemptPayment = async () => {
    try {
      if (latestInvoice?.id) {
        const { data: newInvoice } = await retryPayment(latestInvoice.id);
        if (newInvoice.status === "paid") {
          alert(
            "Payment successfull, refresh the page and you should be able to continue creating ToneTips!"
          );
        }
      }
    } catch (error: any) {
      alert(error.message);
    }
  };

  useEffect(() => {
    if (
      userSubscription?.latest_invoice != null &&
      typeof userSubscription?.latest_invoice != "string"
    ) {
      setLatestInvoice(userSubscription?.latest_invoice);
      if (userSubscription.latest_invoice.status === "open") {
        setInvoiceNeedsPayment(true);
      }
    }
  }, [userSubscription]);

  return (
    <Modal
      show={show}
      onHide={() => onHide(false)}
      contentClassName="manage-sub-modal"
    >
      <Modal.Header closeButton>
        <h3 style={{ marginBottom: "0" }}>Manage Subscription</h3>
      </Modal.Header>
      <Modal.Body>
        {userSubscription && (
          <>
            <h5>Plan: {currentUser?.subType}</h5>
            <h5>Status: {capitalizeFirstLetter(userSubscription.status)}</h5>
            <h5>
              Current period:{" "}
              {format(
                fromUnixTime(userSubscription.current_period_start),
                "MM/dd/yy"
              )}{" "}
              -{" "}
              {format(
                fromUnixTime(userSubscription.current_period_end),
                "MM/dd/yy"
              )}
            </h5>
            <h5>
              Start date:{" "}
              {format(fromUnixTime(userSubscription.start_date), "MM/dd/yy")}
            </h5>
            <div>
              <h5>
                Latest invoice status:{" "}
                {capitalizeFirstLetter(latestInvoice?.status || "") || "-"}
              </h5>
              {invoiceNeedsPayment && (
                <OrangeButton type="button" clickEvent={handleAttemptPayment}>
                  Attempt payment
                </OrangeButton>
              )}
            </div>
            <Button
              className="delete"
              type="button"
              onClick={handleSubscriptionCancellation}
              variant="danger"
              disabled={loader}
            >
              {!loader ? (
                "Cancel my subscription"
              ) : (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              )}
            </Button>
            <Button
              type="button"
              variant="success"
              disabled={loader}
              onClick={handleChangePlan}
            >
              Change my plan
            </Button>
          </>
        )}
        {!userSubscription && (
          <h5>
            Subscription information is not available or you are not yet
            subscribed to any plans.
          </h5>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ManageSubscriptionModal;
