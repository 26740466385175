import { FirebaseTimeStampI } from "../../Interfaces/FirebaseTimeStamp";

export function firebaseTimeStampToDate(timestamp?: FirebaseTimeStampI) {
  if (timestamp){
    const seconds = timestamp.seconds;
    const nanoseconds = timestamp.nanoseconds;
    return new Date(seconds * 1000 + nanoseconds / 1000000);
  } else {
    return undefined;
  }
}

export const numberFormatter = (num?: number): string => {  // add k for thousands, m  for millions and 2 decimal places
  if (num && num >= 1000 && num<1000000) {
    const formattedNum = (num / 1000).toFixed(2);
    return `${formattedNum}K`;
  } else if (num && num >= 1000000) {
    const formattedNum = (num / 1000000).toFixed(2);
    return `${formattedNum}M`;
  } else if (!num) {
    return "0";
  } else {
    return num.toLocaleString();
  }
};

export const numberFormatter2 = (num?: number): string => { // add k for thousands, m for millions with no decimal places
  if (num && num >= 1000 && num<1000000) {
    const formattedNum = (num / 1000);
    return `${formattedNum}k`;
  } else if (num && num >= 1000000) {
    const formattedNum = (num / 1000000);
    return `${formattedNum}M`;
  } else if (!num) {
    return "0";
  } else {
    return num.toLocaleString();
  }
};

export function capitalizeFirstLetter(str:string) {
  if ( str.length === 0) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}