import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./ErrorPage.scss";

function ErrorPage() {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/produce");
    }, 3000);

    return () => clearTimeout(timer);
  }, []);



  return (
    <div className="error-page-container">
      <div className="error-message">The page you tried to navigate was not found :( <br/> Redirecting you in 3, 2, 1 ...</div>
    </div>
  );
}

export default ErrorPage;
