import downloader from "js-file-download";
import { Buffer } from "buffer";
const ttEnc = require("../tt-encoder/kotlin/tt-encoder");

export const genWav = async (uarc: string, filename: string, f: number) => {
  const a = ttEnc.generateWav(uarc, f) as Uint8Array;
  const b = Buffer.from(a).toString("base64");
  // const b = await new Promise<string>((resolve, reject) => {
  //   const blob = new Blob([a], { type: "application/octet-stream" });
  //   const reader = new FileReader();
  //   reader.onloadend = () => {
  //     const base64String = reader.result as string;
  //     resolve(base64String.split(",")[1]);
  //   };
  //   reader.onerror = () => {
  //     reject(new Error("Failed to read the ArrayBuffer."));
  //   };
  //   reader.readAsDataURL(blob);
  // });

  const wb = Uint8Array.from(window.atob(b as string), (c) => c.charCodeAt(0));
  downloader(wb, `TT - ${uarc} - ${f/1000}k - ${filename}.wav`);
  // return btoa(binary);
};
