import { storage } from "../firebaseConfig";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

export async function uploadPicture(
  file: File,
  folder: string,
  fileName: string
) {
  try {
    const storageRef = ref(storage, `/${folder}/${fileName}`);
    let pictureURL = '';
    await uploadBytes(storageRef, file).then(async (snapshot) => {
      pictureURL = await getDownloadURL(snapshot.ref);
    });
    return pictureURL;
  } catch (error:any) {
    // console.log(error.code);
    return null;
  }
}
