import { useEffect, useState } from "react";
import MonthlyAnnualSelector from "../../components/MonthlyAnnualSelector/MonthlyAnnualSelector";
import ToneTipBaseCard from "../../components/ToneTipBaseCard/ToneTipBaseCard";
import "./UpgradePage.scss";
import { useUserContext } from "../../context/userContext";
import { SubscriptionPlanI } from "../../../Interfaces/PlanInfo";
//import { getAllPlans } from "../../services/PricePlansServices";
import PlanCardContent from "../../components/PlanCardContent/PlanCardContent";
import { Spinner } from "react-bootstrap";
import {
  getAllProducts,
  getProductPrices,
} from "../../services/CloudFunctions";

export interface CurrentPlanI {
  name: string;
  interval: string | undefined;
}

const Upgrade = () => {
  const [annual, setAnnual] = useState<boolean>(true);
  const { currentUser, userSubscription } = useUserContext();
  const [currentPlan, setCurrentPlan] = useState<CurrentPlanI>();
  const [availablePlans, setAvailablePlans] =
    useState<Array<SubscriptionPlanI>>();

  const getPricePlans = async () => {
    try {
      const products: any = await getAllProducts();
      const productPromises = products.data.map(async (product: any) => {
        const productPrices: any = await getProductPrices({
          productId: product.id,
        });
        return { product, productPrices };
      });
      const productData = await Promise.all(productPromises);

      const productsArray = productData.map(({ product, productPrices }) => {
        let monthlyPrice: number = 0;
        let monthlyPriceId: string | null = null;
        let yearlyPrice: number = 0;
        let yearlyPriceId: string | null = null;
        productPrices.data.forEach((e: any) => {
          if (e.recurring.interval === "year") {
            yearlyPrice = +e.unit_amount / 100;
            yearlyPriceId = e.id;
          } else if (e.recurring.interval === "month") {
            monthlyPrice = +e.unit_amount / 100;
            monthlyPriceId = e.id;
          }
        });

        const tempProduct: SubscriptionPlanI = {
          id: product.id,
          planType: product.name || "Missing name",
          annual: false,
          pricePerMonth: monthlyPrice,
          ...(yearlyPriceId ? { pricePerYearId: yearlyPriceId } : {}),
          pricePerYear: yearlyPrice,
          ...(monthlyPriceId ? { pricePerMonthId: monthlyPriceId } : {}),
          toneTips: parseInt(product.metadata.tonetips) || 0,
          impressions: parseInt(product.metadata.impressions) || 0,
          imageLink: product.metadata.imgCmd === "true" || false,
          activeButtons: parseInt(product.metadata.commandBtns) || 3,
        };
        return tempProduct;
      });
      productsArray.sort((a, b) => b.pricePerMonth - a.pricePerMonth);
      setAvailablePlans(productsArray);
    } catch (error) {
      // console.log(error);
      setAvailablePlans([]);
    }
  };

  useEffect(() => {
    if (currentUser?.subType && userSubscription) {
      setCurrentPlan({
        name: currentUser.subType,
        interval: userSubscription.items.data[0].price.recurring?.interval,
      });
    } else if (currentUser?.subType === null && !userSubscription){
      setCurrentPlan(undefined)
    }
    getPricePlans();
  }, [userSubscription, currentUser]);

  return (
    <div className="upgrade-main-container">
      <div className="upgrade-top-text">
        <h2>Choose a plan that's right for you</h2>
        <h6>
          Are you an early adopter, YouTube producer, or maybe you host and
          produce a Podcast? We have ToneTip plans that are built specifically
          for you.
        </h6>
      </div>
      <MonthlyAnnualSelector setAnnual={setAnnual} />
      <div className="cards-container">
        {!availablePlans && (
          <>
            <Spinner animation="border" role="status" />
            {"Loading plans..."}
          </>
        )}
        {availablePlans &&
          availablePlans.map((plan, index) => {
            if ((annual && plan.pricePerYear || (!annual && plan.pricePerMonth))) {
              return (
                <ToneTipBaseCard id={plan.id || index} key={plan.id || index}>
                  <PlanCardContent
                    planInfo={plan}
                    annual={annual}
                    currentPlan={currentPlan}
                  />
                </ToneTipBaseCard>
              );
            }
          })}
      </div>
      {/* <div className="promo-container">
        /*The promo consists of a 90-day plan with 10 free tonetips 
        <span>Get 10 FREE Audio Hyperlinks</span>
        <div className="promo-btns-container">
          <OrangeButton type="button">GET FREE AUDIO HYPERLINKS</OrangeButton>
          <OrangeButton type="button">LEARN HOW TO GET STARTED</OrangeButton>
        </div>
      </div> */}
    </div>
  );
};

export default Upgrade;
