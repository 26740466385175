import { Modal, ProgressBar } from "react-bootstrap";
import OrangeButton from "../../OrangeButton/OrangeButton";
import GoBackArrow from "../../../assets/img/go-back-arrow.svg";
import './Step2Content.scss';

function Step2Content(props:any) {
    const { handleGoBack, formik} = props;
  return (
    <Modal.Body>
      <div className="goback-title-container">
        <img src={GoBackArrow} onClick={handleGoBack} />
        <span className="step-2-title">Billing details</span>
      </div>
      <ProgressBar now={33} />
      <div className="checkout-form-container">
        <form onSubmit={formik.handleSubmit}>
          <div className="input-row">
            <div className="cf-input-group">
              <label htmlFor="name">Full name:</label>
              <input
                id="name"
                name="name"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="form-error">{formik.errors.name}</div>
              ) : null}
            </div>
            {/* <div className="cf-input-group last-name">
              <label htmlFor="lastName">Last name</label>
              <input
                id="lastName"
                name="lastName"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.lastName}
              />
              {formik.touched.lastName && formik.errors.lastName ? (
                <div className="form-error">{formik.errors.lastName}</div>
              ) : null}
            </div> */}
          </div>
          <div className="input-row">
            <div className="cf-input-group address">
              <label htmlFor="address">Address</label>
              <input
                id="address"
                name="address"
                type="address"
                onChange={formik.handleChange}
                value={formik.values.address}
              />
              {formik.touched.address && formik.errors.address ? (
                <div className="form-error">{formik.errors.address}</div>
              ) : null}
            </div>
          </div>
          <div className="input-row">
            <div className="cf-input-group city">
              <label htmlFor="city">City</label>
              <input
                id="city"
                name="city"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.city}
              />
              {formik.touched.city && formik.errors.city ? (
                <div className="form-error">{formik.errors.city}</div>
              ) : null}
            </div>
            <div className="cf-input-group zipCode">
              <label htmlFor="zipCode">Zip (Optional)</label>
              <input
                id="zipCode"
                name="zipCode"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.zipCode}
              />
              {formik.touched.zipCode && formik.errors.zipCode ? (
                <div className="form-error">{formik.errors.zipCode}</div>
              ) : null}
            </div>
          </div>
          <div className="input-row">
            <div className="cf-input-group state">
              <label htmlFor="state">State</label>
              <input
                id="state"
                name="state"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.state}
              />
              {formik.touched.state && formik.errors.state ? (
                <div className="form-error">{formik.errors.state}</div>
              ) : null}
            </div>
            <div className="cf-input-group country">
              <label htmlFor="country">Country</label>
              <select
                id="country"
                name="country"
                onChange={formik.handleChange}
                value={formik.values.country}
              >
                <option value="" disabled>Select an option</option>
                <option value="US">United States</option>
                <option value="MX">Mexico</option>
              </select>
              {formik.touched.country && formik.errors.country ? (
                <div className="form-error">{formik.errors.country}</div>
              ) : null}
            </div>
          </div>
          <div className="next-btn-container">
            <OrangeButton type="submit">
              Next
            </OrangeButton>
          </div>
        </form>
      </div>
    </Modal.Body>
  );
}

export default Step2Content;
