import { Outlet } from "react-router-dom";
import SideNavbar from "../../components/SideNavbar/SideNavbar";
import BgGreyWave from "../../assets/img/tone-tip-grey-bg-wave.svg";
import "./MainLayout.scss";

const MainLayout = () => {
  return (
    <div className="main-layout-container">
      <SideNavbar />
      <div className="current-component-main-container">
        <Outlet />
      </div>
      {/* <img id="wave1" src={BgGreyWave}/>
      <img id="wave2" src={BgGreyWave}/> */}
    </div>
  );
};
export default MainLayout;
