/* eslint-disable */ 
(function (root, factory) {
    if (typeof define === 'function' && define.amd)
      define(['exports', 'kotlin'], factory);
    else if (typeof exports === 'object')
      factory(module.exports, require('kotlin'));
    else {
      if (typeof kotlin === 'undefined') {
        throw new Error("Error loading module 'tt-encoder'. Its dependency 'kotlin' was not found. Please, check whether 'kotlin' is loaded prior to 'tt-encoder'.");
      }root['tt-encoder'] = factory(typeof this['tt-encoder'] === 'undefined' ? {} : this['tt-encoder'], kotlin);
    }
  }(this, function (_, Kotlin) {
    'use strict';
    var Kind_OBJECT = Kotlin.Kind.OBJECT;
    var L255 = Kotlin.Long.fromInt(255);
    var toString = Kotlin.kotlin.text.toString_if0zpk$;
    var joinToString = Kotlin.kotlin.collections.joinToString_s78119$;
    var to = Kotlin.kotlin.to_ujzrz7$;
    var abs = Kotlin.kotlin.math.abs_za3lpa$;
    var numberToInt = Kotlin.numberToInt;
    var IntRange = Kotlin.kotlin.ranges.IntRange;
    var coerceIn = Kotlin.kotlin.ranges.coerceIn_nayhkp$;
    var toByte = Kotlin.toByte;
    var copyOfRange = Kotlin.kotlin.collections.copyOfRange_ietg8x$;
    var Kind_CLASS = Kotlin.Kind.CLASS;
    var JsMath = Math;
    var roundToInt = Kotlin.kotlin.math.roundToInt_yrwdxr$;
    var CharRange = Kotlin.kotlin.ranges.CharRange;
    var IllegalStateException_init = Kotlin.kotlin.IllegalStateException_init_pdl1vj$;
    var toShort = Kotlin.toShort;
    var fill = Kotlin.kotlin.collections.fill_htcctw$;
    var Array_0 = Array;
    var copyOf = Kotlin.kotlin.collections.copyOf_mrm5p$;
    var arrayCopy = Kotlin.kotlin.collections.arrayCopy;
    var Unit = Kotlin.kotlin.Unit;
    RsEncoder.prototype = Object.create(Rs.prototype);
    RsEncoder.prototype.constructor = RsEncoder;
    RsDecoder.prototype = Object.create(Rs.prototype);
    RsDecoder.prototype.constructor = RsDecoder;
    function Crc() {
      Crc_instance = this;
      this.crc_ccitt_table_0 = new Int32Array([0, 4489, 8978, 12955, 17956, 22445, 25910, 29887, 35912, 40385, 44890, 48851, 51820, 56293, 59774, 63735, 4225, 264, 13203, 8730, 22181, 18220, 30135, 25662, 40137, 36160, 49115, 44626, 56045, 52068, 63999, 59510, 8450, 12427, 528, 5017, 26406, 30383, 17460, 21949, 44362, 48323, 36440, 40913, 60270, 64231, 51324, 55797, 12675, 8202, 4753, 792, 30631, 26158, 21685, 17724, 48587, 44098, 40665, 36688, 64495, 60006, 55549, 51572, 16900, 21389, 24854, 28831, 1056, 5545, 10034, 14011, 52812, 57285, 60766, 64727, 34920, 39393, 43898, 47859, 21125, 17164, 29079, 24606, 5281, 1320, 14259, 9786, 57037, 53060, 64991, 60502, 39145, 35168, 48123, 43634, 25350, 29327, 16404, 20893, 9506, 13483, 1584, 6073, 61262, 65223, 52316, 56789, 43370, 47331, 35448, 39921, 29575, 25102, 20629, 16668, 13731, 9258, 5809, 1848, 65487, 60998, 56541, 52564, 47595, 43106, 39673, 35696, 33800, 38273, 42778, 46739, 49708, 54181, 57662, 61623, 2112, 6601, 11090, 15067, 20068, 24557, 28022, 31999, 38025, 34048, 47003, 42514, 53933, 49956, 61887, 57398, 6337, 2376, 15315, 10842, 24293, 20332, 32247, 27774, 42250, 46211, 34328, 38801, 58158, 62119, 49212, 53685, 10562, 14539, 2640, 7129, 28518, 32495, 19572, 24061, 46475, 41986, 38553, 34576, 62383, 57894, 53437, 49460, 14787, 10314, 6865, 2904, 32743, 28270, 23797, 19836, 50700, 55173, 58654, 62615, 32808, 37281, 41786, 45747, 19012, 23501, 26966, 30943, 3168, 7657, 12146, 16123, 54925, 50948, 62879, 58390, 37033, 33056, 46011, 41522, 23237, 19276, 31191, 26718, 7393, 3432, 16371, 11898, 59150, 63111, 50204, 54677, 41258, 45219, 33336, 37809, 27462, 31439, 18516, 23005, 11618, 15595, 3696, 8185, 63375, 58886, 54429, 50452, 45483, 40994, 37561, 33584, 31687, 27214, 22741, 18780, 15843, 11370, 7921, 3960]);
    }
    Crc.prototype.crc16ccit_pdp8qh$ = function (sym, crc) {
      return crc >> 8 ^ this.crc_ccitt_table_0[(crc ^ sym) & 255];
    };
    Crc.$metadata$ = {
      kind: Kind_OBJECT,
      simpleName: 'Crc',
      interfaces: []
    };
    var Crc_instance = null;
    function Crc_getInstance() {
      if (Crc_instance === null) {
        new Crc();
      }return Crc_instance;
    }
    function Coder() {
      Coder_instance = this;
      this.RS_N = 255;
      this.RS_M = 8;
      this.RS_T = 4;
      this.RS_K = 247;
    }
    Coder.$metadata$ = {
      kind: Kind_OBJECT,
      simpleName: 'Coder',
      interfaces: []
    };
    var Coder_instance = null;
    function Coder_getInstance() {
      if (Coder_instance === null) {
        new Coder();
      }return Coder_instance;
    }
    function Fft() {
      Fft_instance = this;
      this.Rcoef_0 = new Float32Array([-1.0, 0.0, 0.70710677, 0.9238795, 0.98078525, 0.9951847, 0.99879545, 0.9996988, 0.9999247, 0.99998116, 0.9999953, 0.9999988, 0.9999997, 0.99999994]);
      this.Icoef_0 = new Float32Array([0.0, -1.0, -0.70710677, -0.38268343, -0.19509032, -0.09801714, -0.049067676, -0.024541229, -0.012271538, -0.0061358847, -0.0030679568, -0.0015339801, -7.669903E-4, -3.8349518E-4]);
    }
    Fft.prototype.logN_0 = function (n) {
      var rezN = 4;
      var logN = 2;
      while (logN < 15) {
        if (rezN === n)
          return logN;
        rezN = rezN << 1;
        logN = logN + 1 | 0;
      }
      return -1;
    };
    Fft.prototype.fft_0 = function (Rdat, Idat, N, LogN) {
      var tmp$;
      if (N > 16384 || N < 1)
        return 0;
      if (LogN < 2 || LogN > 14)
        return 0;
      var nn = N >> 1;
      var ie = N;
      for (var n = 1; n <= LogN; n++) {
        var rw = this.Rcoef_0[LogN - n | 0];
        var iw = this.Icoef_0[LogN - n | 0];
        var inn = ie >> 1;
        var ru = 1.0;
        var iu = 0.0;
        for (var j = 0; j < inn; j++) {
          tmp$ = ie;
          for (var i = j; i < N; i += tmp$) {
            var io = i + inn | 0;
            var rtp = Rdat[i] + Rdat[io];
            var itp = Idat[i] + Idat[io];
            var rtq = Rdat[i] - Rdat[io];
            var itq = Idat[i] - Idat[io];
            Rdat[io] = rtq * ru - itq * iu;
            Idat[io] = itq * ru + rtq * iu;
            Rdat[i] = rtp;
            Idat[i] = itp;
          }
          var sr = ru;
          ru = ru * rw - iu * iw;
          iu = iu * rw + sr * iw;
        }
        ie = ie >>> 1;
      }
      var j_0 = 1;
      for (var i_0 = 1; i_0 < N; i_0++) {
        if (i_0 < j_0) {
          var io_0 = i_0 - 1 | 0;
          var xn = j_0 - 1 | 0;
          var rtp_0 = Rdat[xn];
          var itp_0 = Idat[xn];
          Rdat[xn] = Rdat[io_0];
          Idat[xn] = Idat[io_0];
          Rdat[io_0] = rtp_0;
          Idat[io_0] = itp_0;
        }var k = nn;
        while (k < j_0) {
          j_0 = j_0 - k | 0;
          k = k >>> 1;
        }
        j_0 = j_0 + k | 0;
      }
      return 1;
    };
    Fft.prototype.fFTfloat_algbus$ = function (N, input, Rdat, Idat) {
      var lN = this.logN_0(N);
      if (lN < 2)
        return 0;
      for (var i = 0; i < N; i++) {
        Rdat[i] = input[i];
        Idat[i] = 0.0;
      }
      return this.fft_0(Rdat, Idat, N, lN);
    };
    Fft.$metadata$ = {
      kind: Kind_OBJECT,
      simpleName: 'Fft',
      interfaces: []
    };
    var Fft_instance = null;
    function Fft_getInstance() {
      if (Fft_instance === null) {
        new Fft();
      }return Fft_instance;
    }
    function Decoder(freq, log, onDetected) {
      Decoder$Companion_getInstance();
      this.log_0 = log;
      this.onDetected_0 = onDetected;
      this.rsCoder_0 = new RsDecoder();
      this.skipSymbolCounter_0 = 0;
      this.fftLen_0 = 4096;
      this.fftLen9X_0 = ((2000 * this.fftLen_0 | 0) + 9 | 0) / 18 | 0;
      this.fftBuffR_0 = new Float32Array(8192);
      this.fftBuffI_0 = new Float32Array(8192);
      this.fftBuffAmp_0 = new Float32Array(4096);
      this.fftBuffInputLinear_0 = new Float32Array(8192);
      this.fftBuffCircular_0 = new Float32Array(8192);
      this.bufferIndexExt_0 = 0;
      this.fftBuffCircularIndex_0 = 0;
      this.lowFftBin_0 = 0;
      this.scanFftIndexLow_0 = 0;
      this.scanFftIndexHigh_0 = 0;
      this.rsToneBuffer_0 = new Int8Array(32);
      this.receivedBuffer_0 = new Int8Array(16);
      this.decodedBuffer_0 = new Int8Array(255);
      this.buf9Head_0 = 0;
      this.buffer9_0 = new Float32Array(512);
      this.buffer9tones_0 = new Float32Array(512);
      var dfft = Decoder$Companion_getInstance().NOMINAL_EQ_RATE_0 / this.fftLen_0;
      var df = dfft * 2;
      var dFreqL = freq - 7.5 * df;
      var x = dFreqL / dfft + 0.5;
      this.lowFftBin_0 = numberToInt(JsMath.floor(x) + 0.1);
      var b = this.lowFftBin_0 - 2 | 0;
      this.scanFftIndexLow_0 = JsMath.max(2, b);
      var a = (this.fftLen_0 / 2 | 0) - 4 | 0;
      var b_0 = this.lowFftBin_0 + 32 | 0;
      this.scanFftIndexHigh_0 = JsMath.min(a, b_0);
    }
    function Decoder$Companion() {
      Decoder$Companion_instance = this;
      this.speedx_0 = 2;
      this.shiftx_0 = 2;
      this.NOMINAL_EQ_RATE_0 = 48000.0;
      this.X1_FFT_LEN_0 = 8192;
      this.MFSK_BITLEN_0 = 9;
      this.MFSK_BUF_LEN_0 = 512;
      this.MFSK_BUF_LEN1_0 = 511;
      this.MFSK_BLOCK_LEN_HALF_0 = 16;
      this.MFSK_BLOCK_LEN_0 = 32;
      this.GMV_D_INDEX_0 = 2;
    }
    function Decoder$Companion$toHexString$lambda(b) {
      var s = toString(Kotlin.Long.fromInt(b).and(L255), 16);
      return s.length === 2 ? s : '0' + s;
    }
    Decoder$Companion.prototype.toHexString_964n91$ = function ($receiver) {
      return joinToString($receiver, '', void 0, void 0, void 0, void 0, Decoder$Companion$toHexString$lambda);
    };
    Decoder$Companion.$metadata$ = {
      kind: Kind_OBJECT,
      simpleName: 'Companion',
      interfaces: []
    };
    var Decoder$Companion_instance = null;
    function Decoder$Companion_getInstance() {
      if (Decoder$Companion_instance === null) {
        new Decoder$Companion();
      }return Decoder$Companion_instance;
    }
    Decoder.prototype.processSamples = function (samples, numSamples) {
      for (var i = 0; i < numSamples; i++) {
        this.processSample(samples[i]);
      }
    };
    Decoder.prototype.processSample = function (sample) {
      var tmp$, tmp$_0;
      var v = sample;
      this.fftBuffCircular_0[this.fftBuffCircularIndex_0] = v;
      this.fftBuffCircularIndex_0 = this.fftBuffCircularIndex_0 + 1 & 8191;
      this.bufferIndexExt_0 = this.bufferIndexExt_0 + 1000 | 0;
      if (this.bufferIndexExt_0 >= this.fftLen9X_0) {
        this.bufferIndexExt_0 = this.bufferIndexExt_0 - this.fftLen9X_0 | 0;
        var k = this.fftBuffCircularIndex_0 - this.fftLen_0 & 8191;
        tmp$ = this.fftLen_0;
        for (var j = 0; j < tmp$; j++) {
          this.fftBuffInputLinear_0[j] = this.fftBuffCircular_0[tmp$_0 = k, k = tmp$_0 + 1 | 0, tmp$_0];
          k = k & 8191;
        }
        Fft_getInstance().fFTfloat_algbus$(this.fftLen_0, this.fftBuffInputLinear_0, this.fftBuffR_0, this.fftBuffI_0);
        this.bit9Sample_0();
      }};
    Decoder.prototype.bit9Sample_0 = function () {
      var tmp$, tmp$_0;
      var fl2 = 2.0 * this.fftLen_0 * this.fftLen_0;
      tmp$ = this.scanFftIndexLow_0 - 1 | 0;
      tmp$_0 = this.scanFftIndexHigh_0 + 1 | 0;
      for (var i = tmp$; i <= tmp$_0; i++) {
        var v1 = this.fftBuffR_0[i] / fl2;
        var fftval = v1 * v1;
        var v2 = this.fftBuffI_0[i] / fl2;
        fftval += v2 * v2;
        var tmp$_1;
        if (fftval > 1.0E-12) {
          var x = fftval;
          tmp$_1 = JsMath.sqrt(x);
        } else
          tmp$_1 = 0.0;
        fftval = tmp$_1;
        this.fftBuffAmp_0[i] = fftval;
      }
      var tmp$_2 = this.decode_0();
      var vv = tmp$_2.component1()
      , vdiff = tmp$_2.component2();
      this.buf9Head_0 = this.buf9Head_0 + 1 & 511;
      this.buffer9_0[this.buf9Head_0] = vdiff;
      this.buffer9tones_0[this.buf9Head_0] = vv;
      if (this.skipSymbolCounter_0 > 0) {
        this.skipSymbolCounter_0 = this.skipSymbolCounter_0 - 1 | 0;
        return;
      }var start = this.buf9Head_0 - 288 & 511;
      return this.testAndPrintPacket_0(start);
    };
    Decoder.prototype.decode_0 = function () {
      var tmp$, tmp$_0;
      var maxnum = 0;
      var maxval = -1.0;
      var minval = 1.0E8;
      tmp$ = this.scanFftIndexLow_0;
      tmp$_0 = this.scanFftIndexHigh_0;
      for (var i = tmp$; i <= tmp$_0; i++) {
        var v = this.fftBuffAmp_0[i];
        if (v > maxval) {
          maxnum = i;
          maxval = v;
        }if (v < minval)
          minval = v;
      }
      var df = 0.0;
      if (maxval > 1.0E-5) {
        var v0 = this.fftBuffAmp_0[maxnum - 1 | 0];
        var v1 = this.fftBuffAmp_0[maxnum + 1 | 0];
        if (v1 > v0) {
          if (maxval - v0 > 1.0E-5)
            df = 0.5 * (v1 - v0) / (maxval - v0);
          else if (maxval - v1 > 1.0E-5)
            df = 0.5 * (v1 - v0) / (maxval - v1);
        }}var vali = maxnum + df;
      var tmp$_1 = this.lowFftBin_0;
      var x = (vali - this.lowFftBin_0) / 2 + 0.5;
      var valo = tmp$_1 + JsMath.floor(x) * 2;
      return to(valo, maxval - minval);
    };
    Decoder.prototype.testData_0 = function (start, len) {
      var pos = start & 511;
      var summ = 0.0;
      for (var i = 0; i < len; i++) {
        summ += this.buffer9_0[pos];
        pos = pos + 9 & 511;
      }
      return summ;
    };
    Decoder.prototype.getOffset_0 = function (index0) {
      var maxval = 0.0;
      var maxi = 0;
      for (var i = -4; i <= 5; i++) {
        var index1 = index0 + i & 511;
        var qval = this.testData_0(index1, 15);
        if (qval > maxval) {
          maxval = qval;
          maxi = i;
        }}
      return maxi;
    };
    Decoder.prototype.getMaxVal_0 = function (index0, len) {
      var maxval = 0.0;
      var maxi = 0;
      var startidx = index0 + (len > 0 ? 0 : 9 * (len + 1 | 0) | 0) & 511;
      var alen = abs(len);
      for (var i = -2; i <= 2; i++) {
        var index1 = startidx + i & 511;
        var qval = this.testData_0(index1, alen);
        if (qval > maxval) {
          maxval = qval;
          maxi = i;
        }}
      return this.buffer9tones_0[index0 + maxi & 511];
    };
    Decoder.prototype.decodeSymbol_0 = function (freq) {
      var df = (freq - this.lowFftBin_0) / 2;
      var x = df + 0.5;
      var nibble = coerceIn(numberToInt(JsMath.floor(x) + 0.1), new IntRange(0, 15));
      return toByte((nibble ^ nibble >> 1) & 15);
    };
    Decoder.prototype.decodeMessage_0 = function () {
      var tmp$;
      for (var i = 0; i < 16; i++) {
        var bb = this.rsToneBuffer_0[i * 2 | 0] << 4 | this.rsToneBuffer_0[(i * 2 | 0) + 1 | 0];
        this.receivedBuffer_0[i] = toByte(bb);
      }
      var rez = this.rsCoder_0.decode_r9qwmk$(this.receivedBuffer_0, this.decodedBuffer_0);
      if (rez !== 0)
        return false;
      for (var i_0 = 16; i_0 < 255; i_0++) {
        if (this.decodedBuffer_0[i_0] !== 0)
          return false;
      }
      var crc = 65535;
      var idx = 8;
      for (var i_1 = 0; i_1 <= 7; i_1++) {
        crc = Crc_getInstance().crc16ccit_pdp8qh$(this.decodedBuffer_0[tmp$ = idx, idx = tmp$ + 1 | 0, tmp$], crc);
      }
      if ((crc & 65535) !== 0)
        return false;
      for (var i_2 = 0; i_2 < 16; i_2++) {
        if (this.decodedBuffer_0[i_2] !== this.receivedBuffer_0[i_2])
          return false;
      }
      return true;
    };
    Decoder.prototype.testAndPrintPacket_0 = function (start) {
      var index = start & 511;
      var pktOffset = this.getOffset_0(index);
      if (pktOffset > 3)
        return;
      index = index + pktOffset & 511;
      for (var k = 0; k < 32; k++) {
        var df = k < 8 ? this.getMaxVal_0(index, 7) : this.getMaxVal_0(index, -7);
        this.rsToneBuffer_0[k] = this.decodeSymbol_0(df);
        index = index + 9 & 511;
      }
      if (!this.decodeMessage_0())
        return;
      this.skipSymbolCounter_0 = 72;
      this.onDetected_0(copyOfRange(this.decodedBuffer_0, 8, 14));
    };
    Decoder.$metadata$ = {
      kind: Kind_CLASS,
      simpleName: 'Decoder',
      interfaces: []
    };
    var FINE_SINUS_TABLE_LEN;
    var SINUS_AMPLITUDE;
    var fineSinusTable;
    function TMfskGenerator(speedx, shiftx, cfreq, realCardSpeed) {
      TMfskGenerator$Companion_getInstance();
      this.realCardSpeed_0 = realCardSpeed;
      this.symbolLengthX_0 = 0;
      this.phaseX_0 = 0;
      this.symPhase_0 = 0;
      this.fineFreqs_0 = null;
      var mfskSpeed = TMfskGenerator$Companion_getInstance().NOMINAL_EQ_RATE_0 / 8192 * speedx;
      var deltaFreq = mfskSpeed * shiftx;
      var freql = mfskSpeed * roundToInt((cfreq - 7.5 * deltaFreq) / mfskSpeed);
      this.symbolLengthX_0 = roundToInt(this.realCardSpeed_0 * 1000.0 / mfskSpeed);
      var array = new Int32Array(16);
      var tmp$;
      tmp$ = array.length - 1 | 0;
      for (var i = 0; i <= tmp$; i++) {
        array[i] = roundToInt((freql + deltaFreq * i) * 32768 / this.realCardSpeed_0);
      }
      this.fineFreqs_0 = array;
      this.dataBytes_0 = new Int8Array(247);
      this.controlBytes_0 = new Int8Array(8);
      this.rsCoder_0 = new RsEncoder();
    }
    function TMfskGenerator$Companion() {
      TMfskGenerator$Companion_instance = this;
      this.NOMINAL_EQ_RATE_0 = 48000.0;
      this.X1_FFT_LEN_0 = 8192;
      this.symToTone_0 = new Int32Array([0, 1, 3, 2, 7, 6, 4, 5, 15, 14, 12, 13, 8, 9, 11, 10]);
    }
    TMfskGenerator$Companion.$metadata$ = {
      kind: Kind_OBJECT,
      simpleName: 'Companion',
      interfaces: []
    };
    var TMfskGenerator$Companion_instance = null;
    function TMfskGenerator$Companion_getInstance() {
      if (TMfskGenerator$Companion_instance === null) {
        new TMfskGenerator$Companion();
      }return TMfskGenerator$Companion_instance;
    }
    TMfskGenerator.prototype.send4Bytes_0 = function (out, symbol) {
      var tone = TMfskGenerator$Companion_getInstance().symToTone_0[symbol];
      var freq = this.fineFreqs_0[tone];
      while (this.symPhase_0 < this.symbolLengthX_0) {
        this.phaseX_0 = this.phaseX_0 + freq & 32767;
        out.writeShort_za3lpa$(fineSinusTable[this.phaseX_0]);
        this.symPhase_0 = this.symPhase_0 + 1000 | 0;
      }
      this.symPhase_0 = this.symPhase_0 - this.symbolLengthX_0 | 0;
    };
    TMfskGenerator.prototype.sendSymbol_0 = function (out, sym) {
      this.send4Bytes_0(out, sym >> 4 & 15);
      this.send4Bytes_0(out, sym & 15);
    };
    TMfskGenerator.prototype.fillTxOn_0 = function (out) {
      var freq = this.fineFreqs_0[7];
      for (var index = 0; index < 2; index++) {
        out.writeShort_za3lpa$(0);
      }
      var b = 8192000 / this.symbolLengthX_0 | 0;
      var dsamp = JsMath.max(1, b);
      this.phaseX_0 = 0;
      for (var i = 0; i < 8192; i += dsamp) {
        this.phaseX_0 = this.phaseX_0 + freq & 32767;
        out.writeShort_za3lpa$(Kotlin.imul(fineSinusTable[i], fineSinusTable[this.phaseX_0]) / 32000 | 0);
      }
    };
    TMfskGenerator.prototype.fillTxOff_0 = function (out) {
      var freq = this.fineFreqs_0[6];
      var b = 8192000 / this.symbolLengthX_0 | 0;
      var dsamp = JsMath.max(1, b);
      for (var i = 8192; i < 16384; i += dsamp) {
        this.phaseX_0 = this.phaseX_0 + freq & 32767;
        out.writeShort_za3lpa$(Kotlin.imul(fineSinusTable[i], fineSinusTable[this.phaseX_0]) / 32000 | 0);
      }
      for (var index = 0; index < 2; index++) {
        out.writeShort_za3lpa$(0);
      }
    };
    TMfskGenerator.prototype.write_3mpt8o$ = function (data, out) {
      var crc = 65535;
      for (var i = 0; i <= 5; i++) {
        var d = data[i];
        this.dataBytes_0[i] = d;
        crc = Crc_getInstance().crc16ccit_pdp8qh$(d, crc);
      }
      this.dataBytes_0[6] = toByte(crc & 255);
      this.dataBytes_0[7] = toByte(crc >> 8 & 255);
      this.rsCoder_0.encode_r9qwmk$(this.dataBytes_0, this.controlBytes_0);
      this.fillTxOn_0(out);
      for (var i_0 = 0; i_0 < 8; i_0++) {
        this.sendSymbol_0(out, this.controlBytes_0[i_0]);
      }
      for (var i_1 = 0; i_1 <= 7; i_1++) {
        this.sendSymbol_0(out, this.dataBytes_0[i_1]);
      }
      this.fillTxOff_0(out);
    };
    TMfskGenerator.$metadata$ = {
      kind: Kind_CLASS,
      simpleName: 'TMfskGenerator',
      interfaces: []
    };
    function hexToVal($receiver) {
      if ((new CharRange(48, 57)).contains_mef7kx$($receiver))
        return $receiver - 48;
      else if ((new CharRange(97, 102)).contains_mef7kx$($receiver))
        return $receiver - 97 + 10 | 0;
      else if ((new CharRange(65, 70)).contains_mef7kx$($receiver))
        return $receiver - 65 + 10 | 0;
      else {
        throw IllegalStateException_init(('Invalid char: ' + String.fromCharCode($receiver)).toString());
      }
    }
    function generateWav(message, frequency, speed, wavFreq) {
      if (speed === void 0)
        speed = 2;
      if (wavFreq === void 0)
        wavFreq = 48000;
      var array = new Int8Array(message.length / 2 | 0);
      var tmp$;
      tmp$ = array.length - 1 | 0;
      for (var i = 0; i <= tmp$; i++) {
        array[i] = toByte(toByte(hexToVal(message.charCodeAt(i * 2 | 0)) << 4) | toByte(hexToVal(message.charCodeAt((i * 2 | 0) + 1 | 0))));
      }
      var data = array;
      var w = new WavWriter(wavFreq, 1);
      var gen = new TMfskGenerator(speed, 2, frequency, wavFreq);
      gen.write_3mpt8o$(data, w.stream);
      return w.close();
    }
    function Rs() {
      Rs$Companion_getInstance();
      this.alphaTo = new Int32Array(256);
      this.indexOf = new Int32Array(256);
      this.gg = new Int32Array(9);
      this.generateGf_5qhvuh$_0();
      this.genPoly_yrh5kb$_0();
    }
    function Rs$Companion() {
      Rs$Companion_instance = this;
      this.pp_0 = new Int32Array([1, 0, 0, 0, 1, 1, 1, 0, 1]);
    }
    Rs$Companion.$metadata$ = {
      kind: Kind_OBJECT,
      simpleName: 'Companion',
      interfaces: []
    };
    var Rs$Companion_instance = null;
    function Rs$Companion_getInstance() {
      if (Rs$Companion_instance === null) {
        new Rs$Companion();
      }return Rs$Companion_instance;
    }
    Rs.prototype.generateGf_5qhvuh$_0 = function () {
      var mask = 1;
      this.alphaTo[8] = 0;
      for (var i = 0; i < 8; i++) {
        this.alphaTo[i] = mask;
        this.indexOf[this.alphaTo[i]] = i;
        if (Rs$Companion_getInstance().pp_0[i] !== 0)
          this.alphaTo[8] = this.alphaTo[8] ^ mask;
        mask = mask << 1;
      }
      this.indexOf[this.alphaTo[8]] = 8;
      mask = mask >> 1;
      for (var i_0 = 9; i_0 < 255; i_0++) {
        this.alphaTo[i_0] = this.alphaTo[i_0 - 1 | 0] >= mask ? this.alphaTo[8] ^ (this.alphaTo[i_0 - 1 | 0] ^ mask) << 1 : this.alphaTo[i_0 - 1 | 0] << 1;
        this.indexOf[this.alphaTo[i_0]] = i_0;
      }
      this.indexOf[0] = -1;
    };
    Rs.prototype.genPoly_yrh5kb$_0 = function () {
      this.gg[0] = 2;
      this.gg[1] = 1;
      for (var i = 2; i <= 8; i++) {
        this.gg[i] = 1;
        for (var j = i - 1 | 0; j >= 1; j--) {
          this.gg[j] = this.gg[j] !== 0 ? this.gg[j - 1 | 0] ^ this.alphaTo[(this.indexOf[this.gg[j]] + i | 0) % 255] : this.gg[j - 1 | 0];
        }
        this.gg[0] = this.alphaTo[(this.indexOf[this.gg[0]] + i | 0) % 255];
      }
      for (var i_0 = 0; i_0 <= 8; i_0++) {
        this.gg[i_0] = this.indexOf[this.gg[i_0]];
      }
    };
    Rs.$metadata$ = {
      kind: Kind_CLASS,
      simpleName: 'Rs',
      interfaces: []
    };
    function RsEncoder() {
      Rs.call(this);
    }
    RsEncoder.prototype.encode_r9qwmk$ = function (data, bb) {
      var tmp$;
      for (var i = 246; i >= 0; i--) {
        var feedback = this.indexOf[(data[i] ^ bb[7]) & 255];
        if (feedback !== -1) {
          for (var j = 7; j >= 1; j--) {
            if (this.gg[j] !== -1) {
              tmp$ = toByte(bb[j - 1 | 0] ^ this.alphaTo[(this.gg[j] + feedback | 0) % 255]);
            } else
              tmp$ = bb[j - 1 | 0];
            bb[j] = tmp$;
          }
          bb[0] = toByte(this.alphaTo[(this.gg[0] + feedback | 0) % 255]);
        } else {
          for (var j_0 = 7; j_0 >= 1; j_0--) {
            bb[j_0] = bb[j_0 - 1 | 0];
          }
          bb[0] = 0;
        }
      }
    };
    RsEncoder.$metadata$ = {
      kind: Kind_CLASS,
      simpleName: 'RsEncoder',
      interfaces: [Rs]
    };
    function RsDecoder() {
      Rs.call(this);
      this.recd_0 = new Int16Array(255);
      var array = Array_0(10);
      var tmp$;
      tmp$ = array.length - 1 | 0;
      for (var i = 0; i <= tmp$; i++) {
        array[i] = new Int32Array(8);
      }
      this.elp_0 = array;
      this.d_0 = new Int32Array(10);
      this.l_0 = new Int32Array(10);
      this.uLu_0 = new Int32Array(10);
      this.s_0 = new Int32Array(9);
      this.root_0 = new Int32Array(4);
      this.loc_0 = new Int32Array(4);
      this.z_0 = new Int32Array(5);
      this.err_0 = new Int32Array(255);
      this.reg_0 = new Int32Array(5);
    }
    function RsDecoder$decode$f(this$RsDecoder) {
      return function () {
        var tmp$;
        for (var i = 0; i < 255; i++) {
          var v = this$RsDecoder.recd_0[i];
          if (v !== -1) {
            tmp$ = toShort(this$RsDecoder.alphaTo[v]);
          } else
            tmp$ = 0;
          this$RsDecoder.recd_0[i] = tmp$;
        }
      };
    }
    RsDecoder.prototype.decode_r9qwmk$ = function (input, decoded) {
      var tmp$, tmp$_0, tmp$_1, tmp$_2, tmp$_3, tmp$_4, tmp$_5, tmp$_6, tmp$_7, tmp$_8, tmp$_9;
      for (var i = 0; i !== input.length; ++i) {
        this.recd_0[i] = toShort(this.indexOf[input[i] & 255]);
      }
      fill(this.recd_0, -1 | 0, input.length, this.recd_0.length);
      var synError = false;
      for (var i_0 = 1; i_0 <= 8; i_0++) {
        this.s_0[i_0] = 0;
        for (var j = 0; j !== input.length; ++j) {
          var v = this.recd_0[j];
          if (v !== -1) {
            this.s_0[i_0] = this.s_0[i_0] ^ this.alphaTo[(v + Kotlin.imul(i_0, j) | 0) % 255];
          }}
        if (this.s_0[i_0] !== 0)
          synError = true;
        this.s_0[i_0] = this.indexOf[this.s_0[i_0]];
      }
      var f = RsDecoder$decode$f(this);
      if (synError) {
        this.d_0[0] = 0;
        this.d_0[1] = this.s_0[1];
        this.elp_0[0][0] = 0;
        this.elp_0[1][0] = 1;
        for (var i_1 = 1; i_1 < 8; i_1++) {
          this.elp_0[0][i_1] = -1;
          this.elp_0[1][i_1] = 0;
        }
        this.l_0[0] = 0;
        this.l_0[1] = 0;
        this.uLu_0[0] = -1;
        this.uLu_0[1] = 0;
        var u = 0;
        do {
          u = u + 1 | 0;
          if (this.d_0[u] === -1) {
            this.l_0[u + 1 | 0] = this.l_0[u];
            tmp$ = this.l_0[u];
            for (var i_2 = 0; i_2 <= tmp$; i_2++) {
              this.elp_0[u + 1 | 0][i_2] = this.elp_0[u][i_2];
              this.elp_0[u][i_2] = this.indexOf[this.elp_0[u][i_2]];
            }
          } else {
            var q = u - 1 | 0;
            while (this.d_0[q] === -1 && q > 0) {
              q = q - 1 | 0;
            }
            if (q > 0) {
              var j_0 = q;
              do {
                j_0 = j_0 - 1 | 0;
                if (this.d_0[j_0] !== -1 && this.uLu_0[q] < this.uLu_0[j_0])
                  q = j_0;
              }
               while (j_0 > 0);
            }if (this.l_0[u] > (this.l_0[q] + u - q | 0))
              this.l_0[u + 1 | 0] = this.l_0[u];
            else
              this.l_0[u + 1 | 0] = this.l_0[q] + u - q | 0;
            for (var i_3 = 0; i_3 < 8; i_3++)
              this.elp_0[u + 1 | 0][i_3] = 0;
            tmp$_0 = this.l_0[q];
            for (var i_4 = 0; i_4 <= tmp$_0; i_4++) {
              if (this.elp_0[q][i_4] !== -1) {
                this.elp_0[u + 1 | 0][i_4 + u - q | 0] = this.alphaTo[(this.d_0[u] + 255 - this.d_0[q] + this.elp_0[q][i_4] | 0) % 255];
              }}
            tmp$_1 = this.l_0[u];
            for (var i_5 = 0; i_5 <= tmp$_1; i_5++) {
              this.elp_0[u + 1 | 0][i_5] = this.elp_0[u + 1 | 0][i_5] ^ this.elp_0[u][i_5];
              this.elp_0[u][i_5] = this.indexOf[this.elp_0[u][i_5]];
            }
          }
          this.uLu_0[u + 1 | 0] = u - this.l_0[u + 1 | 0] | 0;
          if (u < 8) {
            if (this.s_0[u + 1 | 0] !== -1)
              this.d_0[u + 1 | 0] = this.alphaTo[this.s_0[u + 1 | 0]];
            else
              this.d_0[u + 1 | 0] = 0;
            tmp$_2 = this.l_0[u + 1 | 0];
            for (var i_6 = 1; i_6 <= tmp$_2; i_6++) {
              if (this.s_0[u + 1 - i_6 | 0] !== -1 && this.elp_0[u + 1 | 0][i_6] !== 0) {
                this.d_0[u + 1 | 0] = this.d_0[u + 1 | 0] ^ this.alphaTo[(this.s_0[u + 1 - i_6 | 0] + this.indexOf[this.elp_0[u + 1 | 0][i_6]] | 0) % 255];
              }}
            this.d_0[u + 1 | 0] = this.indexOf[this.d_0[u + 1 | 0]];
          }}
         while (u < 8 && this.l_0[u + 1 | 0] <= 4);
        u = u + 1 | 0;
        if (this.l_0[u] > 4) {
          return -this.l_0[u] | 0;
        }if (this.l_0[u] <= 4) {
          tmp$_3 = this.l_0[u];
          for (var i_7 = 0; i_7 <= tmp$_3; i_7++)
            this.elp_0[u][i_7] = this.indexOf[this.elp_0[u][i_7]];
          tmp$_4 = this.l_0[u];
          for (var i_8 = 1; i_8 <= tmp$_4; i_8++) {
            this.reg_0[i_8] = this.elp_0[u][i_8];
          }
          var cnt = 0;
          for (var i_9 = 1; i_9 <= 255; i_9++) {
            var q_0 = 1;
            tmp$_5 = this.l_0[u];
            for (var j_1 = 1; j_1 <= tmp$_5; j_1++) {
              if (this.reg_0[j_1] !== -1) {
                this.reg_0[j_1] = (this.reg_0[j_1] + j_1 | 0) % 255;
                q_0 = q_0 ^ this.alphaTo[this.reg_0[j_1]];
              }}
            if (q_0 === 0) {
              this.root_0[cnt] = i_9;
              this.loc_0[cnt] = 255 - i_9 | 0;
              cnt = cnt + 1 | 0;
            }}
          if (cnt !== this.l_0[u]) {
            return cnt + 1 | 0;
          }if (cnt === this.l_0[u]) {
            tmp$_6 = this.l_0[u];
            for (var i_10 = 1; i_10 <= tmp$_6; i_10++) {
              if (this.s_0[i_10] !== -1 && this.elp_0[u][i_10] !== -1) {
                this.z_0[i_10] = this.alphaTo[this.s_0[i_10]] ^ this.alphaTo[this.elp_0[u][i_10]];
              } else if (this.s_0[i_10] !== -1 && this.elp_0[u][i_10] === -1) {
                this.z_0[i_10] = this.alphaTo[this.s_0[i_10]];
              } else if (this.s_0[i_10] === -1 && this.elp_0[u][i_10] !== -1) {
                this.z_0[i_10] = this.alphaTo[this.elp_0[u][i_10]];
              } else
                this.z_0[i_10] = 0;
              for (var j_2 = 1; j_2 < i_10; j_2++) {
                if (this.s_0[j_2] !== -1 && this.elp_0[u][i_10 - j_2 | 0] !== -1) {
                  this.z_0[i_10] = this.z_0[i_10] ^ this.alphaTo[(this.elp_0[u][i_10 - j_2 | 0] + this.s_0[j_2] | 0) % 255];
                }}
              this.z_0[i_10] = this.indexOf[this.z_0[i_10]];
            }
            for (var i_11 = 0; i_11 < 255; i_11++) {
              this.err_0[i_11] = 0;
              var v_0 = this.recd_0[i_11];
              this.recd_0[i_11] = v_0 !== -1 ? toShort(this.alphaTo[v_0]) : 0;
            }
            tmp$_7 = this.l_0[u];
            for (var i_12 = 0; i_12 < tmp$_7; i_12++) {
              this.err_0[this.loc_0[i_12]] = 1;
              tmp$_8 = this.l_0[u];
              for (var j_3 = 1; j_3 <= tmp$_8; j_3++) {
                if (this.z_0[j_3] !== -1) {
                  this.err_0[this.loc_0[i_12]] = this.err_0[this.loc_0[i_12]] ^ this.alphaTo[(this.z_0[j_3] + Kotlin.imul(j_3, this.root_0[i_12]) | 0) % 255];
                }}
              if (this.err_0[this.loc_0[i_12]] !== 0) {
                this.err_0[this.loc_0[i_12]] = this.indexOf[this.err_0[this.loc_0[i_12]]];
                var q_1 = 0;
                tmp$_9 = this.l_0[u];
                for (var j_4 = 0; j_4 < tmp$_9; j_4++) {
                  if (j_4 !== i_12) {
                    q_1 = q_1 + this.indexOf[1 ^ this.alphaTo[(this.loc_0[j_4] + this.root_0[i_12] | 0) % 255]] | 0;
                  }}
                q_1 = q_1 % 255;
                this.err_0[this.loc_0[i_12]] = this.alphaTo[(this.err_0[this.loc_0[i_12]] - q_1 + 255 | 0) % 255];
                this.recd_0[this.loc_0[i_12]] = toShort(this.recd_0[this.loc_0[i_12]] ^ this.err_0[this.loc_0[i_12]]);
              }}
          } else {
            f();
          }
        } else {
          f();
        }
      } else {
        f();
      }
      for (var i_13 = 0; i_13 < 255; i_13++) {
        decoded[i_13] = toByte(this.recd_0[i_13] & 255);
      }
      return 0;
    };
    RsDecoder.$metadata$ = {
      kind: Kind_CLASS,
      simpleName: 'RsDecoder',
      interfaces: [Rs]
    };
    function SimpleByteArrayOutputStream(size) {
      if (size === void 0)
        size = 0;
      var tmp$ = Int8Array;
      var b = size;
      this.buf_0 = new tmp$(JsMath.max(1024, b));
      this.size_t0k2qz$_0 = 0;
    }
    Object.defineProperty(SimpleByteArrayOutputStream.prototype, 'size', {
      configurable: true,
      get: function () {
        return this.size_t0k2qz$_0;
      },
      set: function (size) {
        this.size_t0k2qz$_0 = size;
      }
    });
    SimpleByteArrayOutputStream.prototype.expand_0 = function (i) {
      if ((this.size + i | 0) <= this.buf_0.length)
        return;
      var newbuf = new Int8Array((this.size + i | 0) * 2 | 0);
      arrayCopy(this.buf_0, newbuf, 0, 0, this.size);
      this.buf_0 = newbuf;
    };
    SimpleByteArrayOutputStream.prototype.seek_za3lpa$ = function (offs) {
      this.size = offs;
    };
    SimpleByteArrayOutputStream.prototype.write_0 = function (oneByte) {
      var tmp$;
      if (this.size === this.buf_0.length)
        this.expand_0(1);
      this.buf_0[tmp$ = this.size, this.size = tmp$ + 1 | 0, tmp$] = toByte(oneByte);
    };
    SimpleByteArrayOutputStream.prototype.writeShort_za3lpa$ = function (v) {
      this.write_0(v & 255);
      this.write_0(v >> 8 & 255);
    };
    Object.defineProperty(SimpleByteArrayOutputStream.prototype, 'buffer', {
      configurable: true,
      get: function () {
        return copyOf(this.buf_0, this.size);
      }
    });
    SimpleByteArrayOutputStream.$metadata$ = {
      kind: Kind_CLASS,
      simpleName: 'SimpleByteArrayOutputStream',
      interfaces: []
    };
    function WavWriter(sampleRate, numChannels) {
      WavWriter$Companion_getInstance();
      this.stream = new SimpleByteArrayOutputStream();
      var s = this.stream;
      WavWriter$Companion_getInstance().writeInt_0(s, 1179011410);
      WavWriter$Companion_getInstance().writeInt_0(s, 0);
      WavWriter$Companion_getInstance().writeInt_0(s, 1163280727);
      WavWriter$Companion_getInstance().writeInt_0(s, 544501094);
      WavWriter$Companion_getInstance().writeInt_0(s, 16);
      s.writeShort_za3lpa$(1);
      s.writeShort_za3lpa$(numChannels);
      WavWriter$Companion_getInstance().writeInt_0(s, sampleRate);
      WavWriter$Companion_getInstance().writeInt_0(s, Kotlin.imul(sampleRate * 16 | 0, numChannels) / 8 | 0);
      s.writeShort_za3lpa$(numChannels * 2 | 0);
      s.writeShort_za3lpa$(16);
      WavWriter$Companion_getInstance().writeInt_0(s, 1635017060);
      WavWriter$Companion_getInstance().writeInt_0(s, 0);
    }
    function WavWriter$Companion() {
      WavWriter$Companion_instance = this;
    }
    WavWriter$Companion.prototype.writeInt_0 = function ($receiver, v) {
      $receiver.writeShort_za3lpa$(v & 65535);
      $receiver.writeShort_za3lpa$(v >> 16 & 65535);
    };
    WavWriter$Companion.$metadata$ = {
      kind: Kind_OBJECT,
      simpleName: 'Companion',
      interfaces: []
    };
    var WavWriter$Companion_instance = null;
    function WavWriter$Companion_getInstance() {
      if (WavWriter$Companion_instance === null) {
        new WavWriter$Companion();
      }return WavWriter$Companion_instance;
    }
    WavWriter.prototype.close = function () {
      var l = this.stream.size;
      this.stream.seek_za3lpa$(4);
      WavWriter$Companion_getInstance().writeInt_0(this.stream, l - 8 | 0);
      this.stream.seek_za3lpa$(40);
      WavWriter$Companion_getInstance().writeInt_0(this.stream, l - 44 | 0);
      this.stream.seek_za3lpa$(l);
      return this.stream.buffer;
    };
    WavWriter.$metadata$ = {
      kind: Kind_CLASS,
      simpleName: 'WavWriter',
      interfaces: []
    };
    function createDecoder$lambda(s) {
      console.log('log', s);
      return Unit;
    }
    function createDecoder$lambda_0(closure$onDetected) {
      return function (a) {
        var s = Decoder$Companion_getInstance().toHexString_964n91$(a);
        closure$onDetected(s);
        return Unit;
      };
    }
    function createDecoder(freq, onDetected) {
      return new Decoder(freq, createDecoder$lambda, createDecoder$lambda_0(onDetected));
    }
    Object.defineProperty(_, 'Crc', {
      get: Crc_getInstance
    });
    Object.defineProperty(_, 'Coder', {
      get: Coder_getInstance
    });
    Object.defineProperty(Decoder, 'Companion', {
      get: Decoder$Companion_getInstance
    });
    _.Decoder = Decoder;
    _.generateWav = generateWav;
    Object.defineProperty(Rs, 'Companion', {
      get: Rs$Companion_getInstance
    });
    _.Rs = Rs;
    _.RsEncoder = RsEncoder;
    _.RsDecoder = RsDecoder;
    _.SimpleByteArrayOutputStream = SimpleByteArrayOutputStream;
    Object.defineProperty(WavWriter, 'Companion', {
      get: WavWriter$Companion_getInstance
    });
    _.WavWriter = WavWriter;
    _.createDecoder = createDecoder;
    FINE_SINUS_TABLE_LEN = 32768;
    SINUS_AMPLITUDE = 32000;
    var array = new Int32Array(32768);
    var tmp$;
    tmp$ = array.length - 1 | 0;
    for (var i = 0; i <= tmp$; i++) {
      var x = 2.0 * 3.14159265358 * i / 32768;
      array[i] = roundToInt(32000 * JsMath.sin(x));
    }
    fineSinusTable = array;
    Kotlin.defineModule('tt-encoder', _);
    return _;
  }));
  
  // sourceMappingURL=tt-encoder.js.map
  //i deleted a # following the two / on the line above because it was giving me a useless warning
  