import { Modal } from "react-bootstrap";
import "../../Modals/DeleteToneTipModal/DeleteModal.scss";
import { useState } from "react";
import { deleteUserFromAuthAndFb } from "../../../services/CloudFunctions";

function DeleteUserModal(props: any) {
  const { show, onHide, userToDeleteId, refreshUsers } = props;
  const [loading, setLoading] = useState(false);

  const handleDeleteUser = async () => {
    setLoading(true);
    try {
      await deleteUserFromAuthAndFb({userId:userToDeleteId});
      await refreshUsers();
    } catch (error) {
      alert(error);
    }
    setLoading(false);
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      contentClassName="delete-modal"
      dialogClassName="delete-modal-dialog"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete this User?,{" "}
        <span>this action cannot be undone.</span>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={onHide} id="dismiss-btn">
          Dismiss
        </button>
        <button
          id="delete-btn"
          onClick={handleDeleteUser}
          type="button"
          disabled={loading}
          style={loading ? { backgroundColor: "grey", cursor:"default" } : {}}
        >
          Delete
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteUserModal;
