import { Modal } from "react-bootstrap";
//import { getAllCoupons } from "../../../services/CouponsServices";
import { useState } from "react";
import { CouponI } from "../../../../Interfaces/Coupon";
import CouponsTable from "../../CouponsTable/CouponsTable";
import AddRowButton from "../../../assets/icons/add-command-btn.svg";
import "./CouponsCRUDmodal.scss";
import { getAllCoupons } from "../../../services/CloudFunctions";

interface CounponsCRUDmodalProps {
  show: boolean;
  hideModal: () => void;
}

const DUMMY_COUPON: CouponI = {
  title: "Title",
  code: "Code",
  type: "percentage",
  discountAmount: 100,
  durationType: "once",
  redeemBy: null,
  maximumRedemptions: 0,
  timesRedeemed: 0,
};

const CouponsCRUDmodal = (props: CounponsCRUDmodalProps) => {
  const { show, hideModal } = props;
  const [coupons, setCoupons] = useState<Array<CouponI>>([]);

  const couponCleanUp = () => {
    const tempCoupons = [...(coupons || [])];
    const cleanArray = tempCoupons.filter((c) => c.createdAt);
    setCoupons(cleanArray);
  };

  const fetchCoupons = async () => {
    try {
      const res = await getAllCoupons();
      const tempCoupons:any = res.data;
      const couponsArray:CouponI[] = tempCoupons.map((c:any, index:number) => {
        const tempC:CouponI = {
          createdAt: c.created,
          title: c.name,
          code: c.id,
          type: (c.percent_off ? "percentage" : "money"),
          discountAmount: (c.percent_off ? c.percent_off : c.amount_off/100),
          durationType: c.duration,
          durationMonths: (c.duration === "repeating" ? c.duration_in_months : null),
          redeemBy: c.redeem_by || null,
          maximumRedemptions: c.max_redemptions,
          timesRedeemed: c.times_redeemed,
        }
        return tempC;
      }) 
      setCoupons(couponsArray);
    } catch (error) {
      // console.log(error)
      setCoupons([]);
    }
  };

  const addRow = () => {
    const tempCoupons = [...(coupons || [])];
    tempCoupons.push(DUMMY_COUPON);
    setCoupons(tempCoupons);
  };

  return (
    <Modal
      centered
      onShow={coupons.length < 1 ? fetchCoupons : undefined}
      show={show}
      onHide={hideModal}
      size="xl"
      onExited={couponCleanUp}
    >
      <Modal.Header closeButton>
        <h3>Create, read, update or delete coupons</h3>
      </Modal.Header>
      <Modal.Body>
        {coupons && (
          <CouponsTable coupons={coupons} fetchCoupons={fetchCoupons} />
        )}
        <div className="add-row-container">
          <img src={AddRowButton} onClick={addRow} />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CouponsCRUDmodal;
