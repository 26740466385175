import { httpsCallable } from "firebase/functions";
import { functions } from "../firebaseConfig";
import { Price, Subscription } from "@stripe/firestore-stripe-payments";
import Stripe from "stripe";
import { UserI } from "../../Interfaces/User";
import { HttpsError } from "firebase-functions/v1/auth";
import { CommentI } from "../../Interfaces/ToneTip";

type SuccessfulReturn = {message:string}

export const deleteUserFromAuthAndFb = httpsCallable(functions, 'deleteUser');
export const getUsersPaymentMethods = httpsCallable(functions, 'getUsersPaymentMethods');
export const attachPaymentMethod = httpsCallable(functions, 'attachPaymentMethodToUser');
export const detachPaymentMethod = httpsCallable(functions, "detachPaymentMethod");
export const getAllProducts = httpsCallable(functions, "getAllProducts");
export const createNewProduct = httpsCallable(functions, "createNewProduct");
export const deleteProduct = httpsCallable(functions, "deleteProduct");
export const updateProduct = httpsCallable(functions, "updateProduct");
export const getProductPrices = httpsCallable<any,Price[]>(functions, "getProductPrices");
export const updatePrice = httpsCallable(functions, "updatePrice");
export const createNewPrice = httpsCallable(functions, "createNewPrice");
export const getAllCoupons = httpsCallable(functions, "getAllCoupons");
export const createNewCoupon = httpsCallable(functions, "createNewCoupon");
export const deleteCoupon = httpsCallable(functions, "deleteCoupon");
export const updateCouponName = httpsCallable(functions, "updateCouponName");
export const updateStripeCustomerEmailAndName = httpsCallable(functions, "updateCustomerEmailAndName");
export const createSubscription = httpsCallable<any,Subscription>(functions, "createSubscription");
export const getLastCustomerInvoice = httpsCallable(functions, "getLastCustomerInvoice");
export const retryPayment = httpsCallable<string, Stripe.Invoice>(functions, "retryPayment");
export const cancelSubscription = httpsCallable<string, Subscription>(functions, "cancelSubscription");
export const getSingleCoupon = httpsCallable<string, Stripe.Coupon>(functions, "getSingleCoupon");
export const getImpressionsExcessProduct = httpsCallable<void,Stripe.Product>(functions, "getImpressionsExcessProduct");
export const changeImpressionsExcessPrice = httpsCallable<{priceId:string,amount:number},Price>(functions, "changeImpressionsExcessPrice");
export const getSubscription = httpsCallable<string, Stripe.Subscription|undefined>(functions, "getSubscription");
export const changeCurrentPlan = httpsCallable<{newPriceId:string, subItemId:string},Stripe.SubscriptionItem>(functions, "changeCurrentPlan");
export const getCustomerBalance = httpsCallable<string,number>(functions, "getCustomerBalance");
export const addAdminClaim = httpsCallable<string,void>(functions, "addAdminAdditionalClaim");
export const createUserDocInFb = httpsCallable<UserI, { message: "success" }>(functions, "addNewUserToCollection");
export const softDeleteComment = httpsCallable<{userId:string, commentId:string},SuccessfulReturn>(functions,"softDeleteComment")
export const hardDeleteComment = httpsCallable<{commentId:string, uarc:string}, SuccessfulReturn>(functions,"hardDeleteComment")
export const editComment = httpsCallable<{commentUserId:string, originalComment:string, newComment:string, commentId:string, editedComment:boolean},SuccessfulReturn>(functions,"editComment")
export const increaseClickCount = httpsCallable<{uarc:string, commandIndex:number}, SuccessfulReturn | HttpsError>(functions,"increaseClickCount")
export const addToFavorites = httpsCallable<string,SuccessfulReturn|HttpsError>(functions, "addToFavorites")
export const removeFromFavorites = httpsCallable<string,SuccessfulReturn|HttpsError>(functions, "removeFromFavorites")
export const addToLikes = httpsCallable<string,SuccessfulReturn|HttpsError>(functions, "addToLikes")
export const removeFromLikes = httpsCallable<string,SuccessfulReturn|HttpsError>(functions, "removeFromLikes")
export const addComment = httpsCallable<{uarc:string, text:string}, SuccessfulReturn>(functions, "addComment")
