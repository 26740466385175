import { Tip } from "../../../Interfaces/RegisterPage";
import "./LoginPageTips.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

//import { SwiperOptions } from "swiper/types";

import { Autoplay, Pagination, Navigation } from "swiper";

const tips: Tip[] = [
  {
    title: "Tip #1",
    description:
      "ToneTips provide instant access to information from your favorite podcasts, videos and events.",
  },
  {
    title: "Tip #2",
    description: "Description example for Tip number 2.",
  },
  {
    title: "Tip #3",
    description: "Description example for Tip number 2.",
  },
  {
    title: "Tip #4",
    description: "Description example for Tip number 2.",
  },
];

const LoginPageTips: React.FC = () => {
  return (
    <Swiper
      spaceBetween={30}
      centeredSlides={true}
      slidesPerView={1}
      loop={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      modules={[Autoplay]}
      className="mySwiper"
    >
      <SwiperSlide>
        <div className="lt-title-container">
          <h3>{tips[0].title}</h3>
        </div>
        <div className="lt-description-container">{tips[0].description}</div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="lt-title-container">
          <h3>{tips[1].title}</h3>
        </div>
        <div className="lt-description-container">{tips[1].description}</div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="lt-title-container">
          <h3>{tips[2].title}</h3>
        </div>
        <div className="lt-description-container">{tips[2].description}</div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="lt-title-container">
          <h3>{tips[3].title}</h3>
        </div>
        <div className="lt-description-container">{tips[3].description}</div>
      </SwiperSlide>
    </Swiper>
  );
};
export default LoginPageTips;

// <div className="login-tips-container">
//   <div className="tip-selector">
//     <div className="actual-tip-indicator"></div>
//     <div className="actual-tip-indicator"></div>
//     <div className="actual-tip-indicator active"></div>
//     <div className="actual-tip-indicator"></div>
//   </div>
// </div>
