import { faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import UsageFeeNoticeModal from "../Modals/UsageFeeNoticeModal/UsageFeeNoticeModal";

const UsageFeeNoticeButton = () => {
  const [usageFeeNoticeModal, setUsageFeeNoticeModal] = useState(false);
  const ufnToolTip = (
    <Tooltip id="price-plans-tooltip">
      <strong>Check Usage Fee Notice</strong>
    </Tooltip>
  );
  return (
    <>
      <UsageFeeNoticeModal
        show={usageFeeNoticeModal}
        hideModal={() => {
          setUsageFeeNoticeModal(false);
        }}
      />
      <OverlayTrigger placement="bottom" overlay={ufnToolTip}>
        <button
          type="button"
          onClick={() => {
            setUsageFeeNoticeModal(true);
          }}
          className="settings-btn"
        >
          <FontAwesomeIcon icon={faFile} style={{color: "#646464",}}/>
        </button>
      </OverlayTrigger>
    </>
  );
};

export default UsageFeeNoticeButton;
