/// Function to check if a string could be a valid URL
export function checkUrl(url: string):string|boolean {
  // Regular expression to check if the string has a valid URL format

  const urlRegex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+)(\/[^\s]*)?$/;
//   const urlRegex = new RegExp(
//     '^(https?:\\/\\/)?' + // protocol
//       "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
//       "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
//       "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
//       "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
//       "(\\#[-a-z\\d_]*)?$", // fragment locator
//     "i"
//   );

  // Check if the string matches the URL regex
  if (urlRegex.test(url)) {
    // If the scheme (http:// or https://) is missing, add it
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      url = "http://" + url;
    }

    // If 'www.' is missing, add it
    if (!url.includes("www.")) {
      url = url.replace("://", "://www.");
    }

    // If there is no dot (.) after 'www.', it's not valid
    if (!url.match(/www\.[^.]+\..+/)) {
        return false;
      }

    // Now the URL is modified and can be considered valid
    return url;
  }

  // If the string doesn't match the URL format, it's not valid
  return false;
}
