import { CouponI } from "../../../Interfaces/Coupon";
import CouponsTableRow from "./CouponsTableRow";
import "./CouponsTable.scss";
import { Spinner } from "react-bootstrap";

interface CouponsTablePropsI {
  coupons: CouponI[];
  fetchCoupons: () => Promise<void>;
}

const CouponsTable = (props: CouponsTablePropsI) => {
  const { coupons, fetchCoupons } = props;

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>Title</th>
            <th>Code</th>
            <th>Type</th>
            <th>Amount</th>
            <th>Duration type</th>
            <th>Months</th>
            <th>Redeem by</th>
            <th style={{maxWidth:"100px"}}>Max Redemptions</th>
            <th style={{maxWidth:"100px"}}>Times Redeemed</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {coupons.length === 0 && (
            <tr>
              <td colSpan={9} align="center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </td>
            </tr>
          )}
          {coupons &&
            coupons.map((c, index) => {
              return (
                <CouponsTableRow
                  coupon={c}
                  key={c.createdAt || index}
                  fetchCoupons={fetchCoupons}
                />
              );
            })}
        </tbody>
      </table>
    </>
  );
};

export default CouponsTable;
