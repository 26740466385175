import stockImage from "../../assets/img/new-york-stock.png";
import "./RegisterPage.scss";
import Logo from "../../assets/img/tone-tip-logo.svg";
import Text from "../../assets/img/register-page-text.svg";
import OrangeLine from "../../assets/img/orange-line-register.svg";
import LoginPageTips from "../../components/LoginPageTips/LoginPageTips";
import LoginWave from "../../assets/img/login-page-wave.svg";
import Register from "../../components/Register/Register";
import DesignBy from "../../assets/img/design-by-inowu.svg";
import { useState } from "react";
import LogIn from "../../components/LogIn/LogIn";

export default function RegisterPage() {
  const [logIn, setLogIn] = useState<boolean>(true);
  return (
    <div className="register-main-container">
      <div className="register-left-container">
        <img src={Logo} id="logo" alt="ToneTip logo"/>
        <img src={stockImage} id="stock-image" alt="Background pic"/>
        <img src={Text} id="register-text-1" alt="Register text"/>
        <img src={OrangeLine} id="orange-line" alt="Orange line"/>
        <LoginPageTips />
        <div id="register-text-2">
          © 2021-2023 ToneTip. All rights reserved.
        </div>
      </div>
      <div className="register-right-container">
        <img src={LoginWave} id="wave-1" alt="Bg wave"/>
        <img src={LoginWave} id="wave-2" alt="Bg wave"/>
        <a href='https://www.inowu.dev' target="_blank" rel="noreferrer"><img src={DesignBy} id="design-by-inowu" alt="Designed by INOWU Development"/></a>
        {!logIn && <Register setLogIn={setLogIn}/>}
        {logIn && <LogIn setLogIn={setLogIn}/>}
      </div>
    </div>
  );
}
