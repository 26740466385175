import "./CommentCard.scss";
import PPexample from "../../assets/img/pp-example.jpg";
import OptionsDots from "../../assets/icons/option-dots.svg";
import { CommentI } from "../../../Interfaces/ToneTip";
import { getUserById } from "../../services/Users/UserGeneralServices";
import { useEffect, useState } from "react";
import { UserI } from "../../../Interfaces/User";
import { Timestamp } from "firebase/firestore";
import CommentOptionsModal from "../Modals/CommentOptionsModal/CommentOptionsModal";
import { useUserContext } from "../../context/userContext";

interface CommentCardPropsI {
  comment: CommentI;
  onEditComment: (id: string, newComment: string) => void;
  onDeleteComment: () => void;
}

function CommentCard(props: CommentCardPropsI) {
  const [user, setUser] = useState<UserI>();
  const [options, setOptions] = useState(false);
  const { comment, onEditComment, onDeleteComment } = props;
  const { currentUser } = useUserContext();
  const isAdminOrOwner =
    currentUser?.id === comment.userId || currentUser?.userType === "Admin";
  const fetchCommentOwner = async () => {
    await getUserById(comment.userId).then((res) => {
      if (res) {
        setUser(res);
      }
    });
  };
  const commentDateParser = (commentDate: CommentI["createdAt"]) => {
    if (commentDate instanceof Date) {
      const month = (commentDate.getMonth() + 1).toString().padStart(2, "0");
      const day = commentDate.getDate().toString().padStart(2, "0");
      const year = commentDate.getFullYear().toString().slice(-2);

      return `${month}/${day}/${year}`;
    }
    const date = commentDate.toDate();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice(-2);

    return `${month}/${day}/${year}`;
  };

  const toggleOptions = () => {
    if (!comment.id){
      alert("Reload to get comment Id!")
      return
    }
    setOptions((prevState) => !prevState);
  };

  useEffect(() => {
    fetchCommentOwner();
    return () => {
      setUser(undefined);
    };
  }, []);

  return (
    <>
      {user && (
        <div className="comment-card-container">
          <CommentOptionsModal
            show={options}
            comment={comment}
            hideModal={() => setOptions((prevState) => !prevState)}
            onEditComment={onEditComment}
            onDeleteComment={onDeleteComment}
          />
          <div className="pp-section">
            <div className="pp-frame">
              <img src={user?.avatar || PPexample} alt="Profile avatar" />
            </div>
          </div>
          <div className="actual-comment-section">
            <div className="comment-info">
              {user?.displayName} &#183;{" "}
              {commentDateParser(comment.createdAt as Timestamp)} &#183;{" "}
              {comment.editedComment ? <b>Edited</b> : ""}
            </div>
            <div className="actual-comment">{comment.text}</div>
          </div>
          {currentUser && isAdminOrOwner && (
            <div className="comment-options-button">
              <div className="options-button">
                <img
                  src={OptionsDots}
                  alt="Options button"
                  onClick={toggleOptions}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default CommentCard;
