import { User, onAuthStateChanged, signOut } from "firebase/auth";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { auth } from "../firebaseConfig";
import { useUserContext } from "./userContext";
import { ToneTipI } from "../../Interfaces/ToneTip";
import {
  fetchEveryToneTip,
  fetchSingleToneTip,
  getProducerToneTips,
} from "../services/ToneTipServices";
import React from "react";
import { FirebaseTimeStampI } from "../../Interfaces/FirebaseTimeStamp";

export const ToneTipContext = createContext({
  toneTips: [{}] as Array<ToneTipI> | undefined,
  selectedToneTip: undefined as ToneTipI | undefined,
  searchToneTips: (searchTerm: string) => {},
  handleEditToneTip: (tt?: ToneTipI) => {},
  handleSelectToneTip: (tt?: ToneTipI) => {},
  handleCreateToneTip: () => {},
  fetchUserToneTips: async () => {},
  refreshCurrentToneTip: async () => {},
  setAdminMode: (() => {}) as Dispatch<SetStateAction<boolean>>,
  setSortOrder: (() => {}) as Dispatch<
    SetStateAction<{
      criteria: keyof SortingCriteriaI;
      ascending: boolean;
    }>
  >,
  sortOrder: {} as {
    criteria: keyof SortingCriteriaI;
    ascending: boolean;
  },
  editToneTip: {} as boolean,
  toneTipDetails: {} as boolean,
  createToneTip: {} as boolean,
  adminMode: {} as boolean,
});

export function useToneTipContext() {
  return useContext(ToneTipContext);
}

export interface SortingCriteriaI {
  title: string;
  createdAt: Date | FirebaseTimeStampI;
  id: string;
  likes: number;
  dislikes: number;
  favorites: number;
  comments: number;
  views: number;
}

const ToneTipContextProvider = ({ children }: any) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [toneTips, setToneTips] = useState<Array<ToneTipI>>();
  const [toneTipsBackUp, setToneTipsBackUp] = useState<Array<ToneTipI>>();
  const [selectedToneTip, setSelectedToneTip] = useState<ToneTipI>();
  const [editToneTip, setEditToneTip] = useState(false);  //  State to set the edit tonetip Offcanvas
  const [createToneTip, setCreateToneTip] = useState<boolean>(false); // State to set the create tonetip Offcanvas
  const [toneTipDetails, setToneTipDetails] = useState(false); // State to set the details Offcanvas 
  const [adminMode, setAdminMode] = useState<boolean>(false);
  const [sortOrder, setSortOrder] = useState({
    criteria: "createdAt" as keyof SortingCriteriaI,
    ascending: false,
  });
  const { currentUser } = useUserContext();

  const searchToneTips = (searchTerm: string): void => {
    if (!adminMode) {
      let ttsCopy: ToneTipI[] = [...(toneTipsBackUp || [])];
      const filteredToneTips: ToneTipI[] = ttsCopy.filter(
        (obj) =>
          obj.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          obj.id?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setToneTips(filteredToneTips);
    } else {
      let ttsCopy: ToneTipI[] = [...(toneTipsBackUp || [])];
      const filteredToneTips: ToneTipI[] = ttsCopy.filter(
        (obj) =>
          obj.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          obj.id?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          obj.creatorDisplayName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          obj.creatorEmail?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setToneTips(filteredToneTips);
    }
  };

  const sortToneTips = () => {
    if (toneTips) {
      const { criteria, ascending } = sortOrder;
      const toneTipsClone: Array<ToneTipI> = [...toneTips]; // Clone the original array to avoid direct modification

      toneTipsClone.sort((a: ToneTipI, b: ToneTipI) => {
        let valueA, valueB;
        if (
          typeof a[criteria] === "string" ||
          typeof b[criteria] === "string"
        ) {
          valueA = a[criteria]?.toString().toLowerCase();
          valueB = b[criteria]?.toString().toLowerCase();
        } else {
          valueA = a[criteria];
          valueB = b[criteria];
        }
        if (valueA != undefined && valueB != undefined) {
          if (valueA < valueB) {
            return ascending ? -1 : 1;
          } else if (valueA > valueB) {
            return ascending ? 1 : -1;
          } else {
            return 0;
          }
        } else return 0;
      });
      setToneTips(toneTipsClone);
    }
  };

  const refreshCurrentToneTip = async () => {
    try {
      if (selectedToneTip?.id) {
        const tempTT = await fetchSingleToneTip(selectedToneTip.id);
        if (tempTT && toneTips) {
          const updatedTT: ToneTipI = tempTT as ToneTipI;
          const tempToneTips: ToneTipI[] = [...toneTips];
          const indexToUpdate = tempToneTips.findIndex(
            (obj) => obj.id === updatedTT.id
          );
          tempToneTips[indexToUpdate] = updatedTT;
          setToneTips(tempToneTips);
          setSelectedToneTip(tempTT as ToneTipI);
        }
      }
    } catch (error: any) {
      alert(error.message);
    }
  };

  const fetchUserToneTips = async () => {
    if (currentUser?.id) {
      const tempToneTips: Array<ToneTipI> | undefined =
        await getProducerToneTips(currentUser.id);
      setToneTips(tempToneTips);
      setToneTipsBackUp(tempToneTips);
      setSortOrder({ criteria: "createdAt", ascending: false });
      if (tempToneTips && tempToneTips[0]){
        handleSelectToneTip(tempToneTips[0]);
      }
      setLoading(false);
    }
  };

  const handleCreateToneTip = () => {
    setCreateToneTip((prevState) => !prevState);
    setSelectedToneTip(undefined);
    setEditToneTip(false);
    setToneTipDetails(false);
  };

  const handleEditToneTip = (tt?: ToneTipI) => {
    if (!tt) {
      setSelectedToneTip(undefined);
      setEditToneTip(false);
    } else {
      setSelectedToneTip(tt);
      setToneTipDetails(false);
      setCreateToneTip(false);
      setEditToneTip(true);
    }
  };

  const handleSelectToneTip = (tt?: ToneTipI) => {
    if (!tt) {
      setSelectedToneTip(undefined);
      setToneTipDetails(false);
    } else {
      setSelectedToneTip(tt);
      setEditToneTip(false);
      setCreateToneTip(false);
      setToneTipDetails(true);
    }
  };

  const fetchAllToneTips = async () => {
    try {
      const tempTTs = await fetchEveryToneTip();
      setToneTips(tempTTs);
      setToneTipsBackUp(tempTTs);
      setSortOrder({ criteria: "createdAt", ascending: false });
    } catch (error: any) {
      alert(error.message);
    }
  };

  useEffect(() => {
    sortToneTips();
  }, [sortOrder]);

  useEffect(() => {
    if (adminMode === true) {
      fetchAllToneTips();
    } else if (adminMode === false) {
      fetchUserToneTips();
    }
  }, [adminMode]);

  // useEffect(() => {
  //   fetchUserToneTips();
  // }, []);

  const values = {
    fetchUserToneTips,
    refreshCurrentToneTip,
    handleEditToneTip,
    handleSelectToneTip,
    handleCreateToneTip,
    setAdminMode,
    setSortOrder,
    searchToneTips,
    sortOrder,
    toneTips, // tonetips fetched
    selectedToneTip, // Currently  selected tonetip, either for edit or to view details
    editToneTip, // Edit Tonetip State (just to let the UI know which component to render)
    toneTipDetails, // Same, just a state
    createToneTip, // Same, just a state
    adminMode,
  };

  if (loading) return <>Loading</>;
  return (
    <ToneTipContext.Provider value={values}>{children}</ToneTipContext.Provider>
  );
};
export default ToneTipContextProvider;
