import React from 'react'
import "./BasicAlternateLayout.scss"
import TTlogo from "../../assets/img/tone-tip-logo.svg";
import { Link } from 'react-router-dom'
import { useUserContext } from '../../context/userContext';
import googlePlayBadge from "../../assets/img/google-play-badge.png"
import appStoreBadge from "../../assets/img/app-store-badge.svg"

const BasicAlternateLayout = ({children}:{children: React.ReactNode}) => {
  const {currentUser} = useUserContext();

  return (
    <div className='basic-layout-main-container'>
      <div className="basic-layout-top">
        <Link to="https://www.tonetip.com" target="_blank">
          <img src={TTlogo} />
        </Link>
        {!currentUser && (
          <Link to="/login" style={{ textDecoration: "none" }}>
            <button>Login/Register</button>
          </Link>
        )}
        <div className='badges-container'>
        <a
          href="https://play.google.com/store/apps/details?id=com.tonetip"
          target="_blank"
          style={{ textDecoration: "none" }}
          >
          <img src={googlePlayBadge}/>
        </a>
        <a
          href="https://apps.apple.com/app/tonetip/id1598183150?l=en-GB"
          target="_blank"
          style={{ textDecoration: "none" }}
          >
          <img src={appStoreBadge}/>
        </a>
          </div>
      </div>

    {children}
    </div>
  )
}

export default BasicAlternateLayout
