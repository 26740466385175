import { SubscriptionPlanI } from "../../../Interfaces/PlanInfo";
import DeleteIcon from "../../assets/img/delete-tonetip-button.svg";
import EditIcon from "../../assets/img/edit-tonetip-button.svg";
import "./PricePlansTable.scss";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBroom, faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { numberFormatter } from "../../utils/StringFormatters";
import {
  createNewPrice,
  createNewProduct,
  deleteProduct,
  updatePrice,
  updateProduct,
} from "../../services/CloudFunctions";
import { Spinner } from "react-bootstrap";

interface PricePlansTableRowI {
  pricePlan: SubscriptionPlanI;
  fetchPricePlans: () => Promise<void>;
}

const PricePlansTableRow = (props: PricePlansTableRowI) => {
  let { pricePlan, fetchPricePlans } = props;
  const [editMode, setEditMode] = useState<boolean>(false);
  const [pricePlanBackUp, setPricePlanBackUp] = useState(pricePlan);
  const [editablePricePlan, setEditablePricePlan] = useState(pricePlan);
  const [loader, setLoader] = useState(false);

  const saveChanges = async () => {
    setLoader(true);
    try {
      if (editablePricePlan.pricePerMonth !== pricePlanBackUp.pricePerMonth) {
        if (pricePlanBackUp.pricePerMonthId){
          await updatePrice({
            priceId: editablePricePlan.pricePerMonthId,
            amount: editablePricePlan.pricePerMonth,
            productId: editablePricePlan.id,
            interval: "month",
          });
        } else {
          await createNewPrice({
            productId: pricePlanBackUp.id,
            amount: editablePricePlan.pricePerMonth,
            interval: "month",
        })
        }
      }
      if (
        editablePricePlan.pricePerYear !== pricePlanBackUp.pricePerYear &&
        editablePricePlan.pricePerYear
      ) {
        if (pricePlanBackUp.pricePerYearId){
          await updatePrice({
            priceId: editablePricePlan.pricePerYearId,
            amount: editablePricePlan.pricePerYear,
            productId: editablePricePlan.id,
            interval: "year",
          });
        } else {
          await createNewPrice({
            productId: pricePlanBackUp.id,
            amount: editablePricePlan.pricePerYear,
            interval: "year",
        })
        }
      }
      await updateProduct({ ...editablePricePlan });
      await fetchPricePlans();
      setEditMode(false);
    } catch (error: any) {
      alert(error.message);
    }
    setLoader(false);
  };

  const createProductPrices = async (productId: string) => {
    const { pricePerMonth, pricePerYear } = editablePricePlan;
    if (pricePerMonth) {
      await createNewPrice({
        productId,
        amount: pricePerMonth,
        interval: "month",
      });
    }
    if (pricePerYear) {
      await createNewPrice({
        productId,
        amount: pricePerYear,
        interval: "year",
      });
    }
  };

  const saveNewPricePlan = async () => {
    setLoader(true);
    try {
      const newStripeProduct: any = await createNewProduct({
        ...editablePricePlan,
      });
      if (newStripeProduct) {
        await createProductPrices(newStripeProduct.data.id);
      }
      setEditMode(false);
      await fetchPricePlans();
    } catch (error: any) {
      alert(error.message);
    }
    setLoader(false);
  };

  const handleInputChanges = (e: any, property: keyof SubscriptionPlanI) => {
    let { value } = e.target;
    if (!Number.isNaN(parseFloat(value))) {
      value = parseFloat(value);
    }
    if (value === "on") {
      value = e.target.checked;
    }
    setEditablePricePlan((prevState) => ({ ...prevState, [property]: value }));
  };

  const discardChanges = () => {
    setEditMode(false);
    pricePlan = pricePlanBackUp;
  };

  const handleDeletePricePlan = async () => {
    setLoader(true);
    if (window.confirm("Are you sure you want to delete this plan?")) {
      if (pricePlan.id) {
        try {
          const isDeleted = await deleteProduct({ id: pricePlan.id });
          if (isDeleted) {
            alert("Plan deleted successfully");
            await fetchPricePlans();
          }
        } catch (error: any) {
          alert(error.message);
        }
      } else {
        alert("This plan cannot be deleted");
      }
    }
    setLoader(false);
  };

  const editPricePlan = async () => {
    setEditMode(true);
  };

  return (
    <tr className="coupon-table-row">
      <th style={{ maxWidth: "110px" }}>
        {editMode || !pricePlan.id ? (
          <input
            value={editablePricePlan.planType}
            type="text"
            name="planType"
            onChange={(e) => {
              handleInputChanges(e, "planType");
            }}
          />
        ) : (
          pricePlan.planType
        )}
      </th>
      <td style={{ maxWidth: "100px" }}>
        {editMode || !pricePlan.id ? (
          <input
            value={editablePricePlan.toneTips}
            type="number"
            name="toneTips"
            onChange={(e) => {
              handleInputChanges(e, "toneTips");
            }}
          />
        ) : (
          numberFormatter(pricePlan.toneTips)
        )}
      </td>
      <td style={{ maxWidth: "110px" }}>
        {editMode || !pricePlan.id ? (
          <input
            value={editablePricePlan.impressions}
            type="number"
            name="impressions"
            onChange={(e) => {
              handleInputChanges(e, "impressions");
            }}
          />
        ) : (
          numberFormatter(pricePlan.impressions)
        )}
      </td>
      <td>
        <input
          checked={editablePricePlan.imageLink}
          type="checkbox"
          name="imageLink"
          disabled={!editMode && pricePlan.id != null}
          onChange={(e) => {
            handleInputChanges(e, "imageLink");
          }}
        />
      </td>
      <td>
        {editMode || !pricePlan.id ? (
          <select
            value={editablePricePlan.activeButtons}
            name="activeButtons"
            onChange={(e) => {
              handleInputChanges(e, "activeButtons");
            }}
          >
            <option value={3}>3</option>
            <option value={5}>5</option>
          </select>
        ) : (
          pricePlan.activeButtons
        )}
      </td>
      <td style={{ maxWidth: "85px" }}>
        {editMode || !pricePlan.id ? (
          <input
            value={editablePricePlan.pricePerMonth}
            type="number"
            name="pricePerMonth"
            onChange={(e) => {
              handleInputChanges(e, "pricePerMonth");
            }}
          />
        ) : (
          pricePlan.pricePerMonth?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
        )}
      </td>
      <td style={{ maxWidth: "85px" }}>
        {editMode || !pricePlan.id ? (
          <input
            value={editablePricePlan.pricePerYear || undefined}
            type="number"
            name="pricePerYear"
            onChange={(e) => {
              handleInputChanges(e, "pricePerYear");
            }}
          />
        ) : (
          pricePlan.pricePerYear?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
        )}
      </td>
      {pricePlan.id && !editMode && (
        <>
          <td>
            {!loader && <img src={DeleteIcon} onClick={handleDeletePricePlan} />}
            {loader && <Spinner animation="border" role="status" />}
          </td>
          <td>
            <img src={EditIcon} onClick={editPricePlan} />
          </td>
        </>
      )}
      {(!pricePlan.id || editMode) && (
        <>
          <td>
            {!loader && <button
              type="button"
              className="dismiss"
              style={{ backgroundColor: "#ff8929" }}
              onClick={!pricePlan.id ? saveNewPricePlan : saveChanges}
            >
              <FontAwesomeIcon icon={faFloppyDisk} />
            </button>}
            {loader &&  <Spinner animation="border" role="status" />}
          </td>
        </>
      )}
      {pricePlan.id && editMode && (
        <>
          <td>
            <button className="dismiss" onClick={discardChanges}>
              <FontAwesomeIcon icon={faBroom} />
            </button>
          </td>
        </>
      )}
    </tr>
  );
};

export default PricePlansTableRow;
