import "./Admin.scss";
import AdminTable from "../../components/AdminTable/AdminTable";
import RightSideDetailsContainer from "../../components/RighSideDetailsContainer/RightSideDetailsContainer";
import UserDetails from "../../components/UserDetails/UserDetails";
import { useEffect, useState } from "react";
import { getAllUsers } from "../../services/Users/UserGeneralServices";
import { UserI } from "../../../Interfaces/User";
import PricePlansSettingsButton from "../../components/PricePlansSettingsButton/PricePlansSettingsButton";
import CouponsSettingsButton from "../../components/CouponsSettingsButton/CouponsSettingsButton";
import { Spinner } from "react-bootstrap";
import UsageFeeNoticeButton from "../../components/UsageFeeNoticeButton/UsageFeeNoticeButton";
import ImpressionsExcessButton from "../../components/ImpressionsExcessButton/ImpressionsExcessButton";

const Admin = () => {
  const [users, setUsers] = useState<Array<UserI>>();
  const [selectedUser, setSelectedUser] = useState<UserI>();

  const fetchAllUsers = async () => {
    const tempUsers = await getAllUsers();
    setUsers(tempUsers);
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);

  return (
    <div className="admin-main-container">
      <div className="admin-left-part">
        <div className="search-bar-container">
          <ImpressionsExcessButton/>
          <UsageFeeNoticeButton/>
          <CouponsSettingsButton />
          <PricePlansSettingsButton />
          {/* <SearchBar /> */}
        </div>
        <div className="admin-table-container">
          {users && (
            <AdminTable users={users} setSelectedUser={setSelectedUser} />
          )}
          {!users && (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          )}
        </div>
      </div>
      <RightSideDetailsContainer>
        {users && (
          <UserDetails
            selectedUser={selectedUser || users[0]}
            fetchAllUsers={fetchAllUsers}
          />
        )}
      </RightSideDetailsContainer>
    </div>
  );
};

export default Admin;
