import { useEffect, useState } from "react";
import SearchIcon from "../../assets/icons/search-icon.svg";
import "./SearchBar.scss";
import { useToneTipContext } from "../../context/toneTipsContext";
const SearchBar = () => {
  const { searchToneTips } = useToneTipContext();
  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    const searchDebounce = setTimeout(() => {
      if (searchTerm) {
        searchToneTips(searchTerm);
      } else {
        searchToneTips("");
      }
    }, 500);
    return () => {
      clearTimeout(searchDebounce);
    };
  }, [searchTerm]);

  return (
    <div className="search-container">
      <img className="search-icon" src={SearchIcon} alt="Search Icon" />
      <input
        className="search-input"
        type="text"
        placeholder="Search..."
        onChange={(e) => {
          setSearchTerm(e.target.value);
        }}
      />
    </div>
  );
};
export default SearchBar;
