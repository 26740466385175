import "./PlanCardContent.scss";
import PersonalPlanLogo from "../../assets/img/personal-plan-logo.svg";
import BusinessPlanLogo from "../../assets/img/business-plan-logo.svg";
import EnterPrisePlanLogo from "../../assets/img/enterprise-plan-logo.svg";
import TrialPlanLogo from "../../assets/img/tone-tip-logo.svg";
import PlanFeatureCheckMark from "../../assets/img/plan-feature-checkmark.svg";
import OrangeButton from "../OrangeButton/OrangeButton";
import { PlanCardContentI } from "../../../Interfaces/PlanCardContent";
import CheckoutModal from "../Modals/CheckoutModal/CheckoutModal";
import { useState } from "react";
import { numberFormatter2 } from "../../utils/StringFormatters";
import { useUserContext } from "../../context/userContext";
import ChangePlanModal from "../Modals/ChangePlanModal/ChangePlanModal";
import { useNavigate } from "react-router-dom";

const planLogos: any = {
  Personal: PersonalPlanLogo,
  Business: BusinessPlanLogo,
  Enterprise: EnterPrisePlanLogo,
};
export default function PlanCardContent(props: PlanCardContentI) {
  const { annual, planInfo, currentPlan } = props;
  const [checkoutModal, setCheckoutModal] = useState<boolean>(false);
  const [changePlanModal, setChangePlanModal] = useState(false);
  const { currentUser } = useUserContext();
  const navigate = useNavigate();

const goToLogin = () => {
  navigate("/login");
}


  function proceedToCheckout() {
    if (currentUser?.subType !== null) {
      setChangePlanModal(true);
      return;
    } else {
      setCheckoutModal(true);
    }
  }

  const renderBilledAt = () => {
    if (planInfo.pricePerYear) {
      return `Billed at ${planInfo.pricePerYear.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
      })}/year`;
    }
  };

  const calculateSavings = () => {
    if (planInfo.pricePerYear) {
      const savings = (
        planInfo.pricePerMonth * 12 -
        planInfo.pricePerYear
      ).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
      });
      return `Save ${savings} with annual plan.`;
    }
  };

  const renderPrice = () => {
    if (annual && planInfo.pricePerYear) {
      return (
        <>
          <h1>${(planInfo.pricePerYear / 12).toLocaleString()}</h1>
          <p>USD/Month</p>
        </>
      );
    } else if (!annual) {
      return (
        <>
          <h1>${planInfo.pricePerMonth}</h1>
          <p>USD/Month</p>
        </>
      );
    }
  };

  const renderLogo = () => {
    const defaultLogo = TrialPlanLogo; // Default logo if no match is found
    if (planInfo.planType) {
      return planLogos[planInfo.planType] || defaultLogo;
    }
  };

  const isActualPlan = (): boolean => {
    const interval = annual ? "year" : "month";
    if (
      planInfo.planType === currentPlan?.name &&
      currentPlan.interval === interval
    ) {
      return true;
    } else {
      return false;
    }
  };

  if (annual && planInfo.pricePerYear == 0) {
    return <h5>This subscription is only available on a monthly basis.</h5>;
  } else {
    return (
      <div className={`plan-content-container ${isActualPlan() && "active"}`}>
        <div className="plan-type-container">
          <img src={renderLogo()} />
        </div>
        {planInfo.planType.toLowerCase().includes("trial") && (
          <h5><span style={{color:"red"}}>FREE</span> 90 Day Trial</h5>
        )}
        <div className="plan-cost-container">{renderPrice()}</div>
        <div className="current-billed-container">
          <h6>
            {isActualPlan() && "Current plan"}
            {!isActualPlan() && !annual && calculateSavings()}
            {!isActualPlan() && annual && renderBilledAt()}
          </h6>
        </div>
        <div className="plan-features">
          {planInfo.planType.toLowerCase().includes("trial") && (
            <div className="plan-feature-container">
              <img src={PlanFeatureCheckMark} />
              <span>
                90-day free trial
              </span>
            </div>
          )}
          <div className="plan-feature-container">
            <img src={PlanFeatureCheckMark} />
            <span>
              {numberFormatter2(planInfo.toneTips)} Audio Hyperlink Files
            </span>
          </div>
          <div className="plan-feature-container">
            <img src={PlanFeatureCheckMark} />
            <span>
              {numberFormatter2(planInfo.impressions)} Impressions/month
            </span>
          </div>
          <div className="plan-feature-container">
            <img src={PlanFeatureCheckMark} />
            <span>Color picker for Button & Text</span>
          </div>
          <div className="plan-feature-container">
            <img src={PlanFeatureCheckMark} />
            <span>{planInfo.activeButtons} Active Buttons</span>
          </div>
          {planInfo.imageLink && (
            <div className="plan-feature-container">
              <img src={PlanFeatureCheckMark} />
              <span>Image Link</span>
            </div>
          )}
        </div>
        <div className="select-plan-btn-container">
          <OrangeButton type="button" clickEvent={currentUser ? proceedToCheckout : goToLogin}>
            {currentUser?.subType == null ? "Select plan" : "Change plan"}
          </OrangeButton>
        </div>
        {changePlanModal && (
          <ChangePlanModal
            show={changePlanModal}
            onHide={() => setChangePlanModal(false)}
            planInfo={planInfo}
            annual={annual}
          />
        )}
        <CheckoutModal
          show={checkoutModal}
          onHide={() => setCheckoutModal(false)}
          planInfo={planInfo}
          annual={annual}
        />
      </div>
    );
  }
}
