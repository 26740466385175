import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../context/userContext";
import { useEffect } from "react";

export default function AdminAccess (props:any) {
    const {currentUser} = useUserContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (currentUser?.userType !== "Admin"){
            navigate('/produce');
        }
    }, [currentUser]);
    return props.children;
}