import { SubscriptionPlanI } from "../../../Interfaces/PlanInfo";
import "./PricePlansTable.scss";
import { Spinner } from "react-bootstrap";
import PricePlansTableRow from "./PricePlansTableRow";

interface PricePlansTablePropsI {
  pricePlans: SubscriptionPlanI[];
  fetchPricePlans: () => Promise<void>;
}

const PricePlansTable = (props: PricePlansTablePropsI) => {
  const { pricePlans, fetchPricePlans } = props;

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>TTs</th>
            <th>Impressions</th>
            <th>Img Cmd</th>
            <th>Command Btns</th>
            <th>$/Month</th>
            <th>$/Year</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {pricePlans.length === 0 && (
            <tr>
              <td colSpan={8} align="center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </td>
            </tr>
          )}
          {pricePlans &&
            pricePlans.map((p, index) => {
              return (
                <PricePlansTableRow
                  pricePlan={p}
                  key={p.id || index}
                  fetchPricePlans={fetchPricePlans}
                />
              );
            })}
        </tbody>
      </table>
    </>
  );
};

export default PricePlansTable;
