import { Modal } from "react-bootstrap";
import {
  getUsageFeeNotice,
  updateUsageFeeNotice,
} from "../../../services/UsageFeeNoticeServices";
import { useState } from "react";
import "./UsageFeeNoticeModal.scss";
import OrangeButton from "../../OrangeButton/OrangeButton";

interface UsageFeeNoticeModalProps {
  show: boolean;
  hideModal: () => void;
}

const UsageFeeNoticeModal = (props: UsageFeeNoticeModalProps) => {
  const { show, hideModal } = props;
  const [ufn, setUfn] = useState<string>("");
  const [loader, setLoader] = useState(false);
  let ufnBackUp: string = "";

  const saveUfn = async () => {
    setLoader(true);
    try {
      if (ufn.length > 1) {
        const res = await updateUsageFeeNotice(ufn);
        if (res === true) {
          alert("Usage Fee Notice updated!");
          hideModal();
        }
      }
    } catch (error) {
      alert("Could not update Usage Fee Notice, please try again.");
      // console.log(error);
    }
    setLoader(false);
  };

  const fetchUsageFeeNotice = async () => {
    if (ufn.length < 1){
      const tempUfn = await getUsageFeeNotice();
      setUfn(tempUfn);
      ufnBackUp = tempUfn;
    }
  };

  return (
    <Modal
      show={show}
      onHide={hideModal}
      centered
      onShow={fetchUsageFeeNotice}
      contentClassName="ufn-modal-content"
      onExited={() => {setUfn(ufnBackUp)}}
    >
      <Modal.Header closeButton>
        <h3>Usage Fee Notice</h3>
      </Modal.Header>
      <Modal.Body>
        <textarea value={ufn} onChange={(e) => setUfn(e.target.value)} />
      </Modal.Body>
      <Modal.Footer>
        <button className="dismiss" id="close-btn" onClick={hideModal}>
          Close
        </button>
        <OrangeButton type="button" clickEvent={saveUfn} loader={loader}>
          Save
        </OrangeButton>
      </Modal.Footer>
    </Modal>
  );
};

export default UsageFeeNoticeModal;
