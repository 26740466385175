import AddNewToneTipButton from "../../components/AddNewToneTipButton/AddNewToneTipButton";
import AdminMode from "../../components/AdminMode/AdminMode";
import CreateToneTip from "../../components/CreateToneTip/CreateToneTip";
import EditToneTip from "../../components/EditToneTip/EditToneTip";
import ProduceTable from "../../components/ProduceTable/ProduceTable";
import RightSideDetailsContainer from "../../components/RighSideDetailsContainer/RightSideDetailsContainer";
import SearchBar from "../../components/SearchBar/SearchBar";
import ToneTipDetails from "../../components/ToneTipDetails/ToneTipDetails";
import "./Produce.scss";
import { useUserContext } from "../../context/userContext";
import { useToneTipContext } from "../../context/toneTipsContext";

const Produce = () => {
  const { currentUser } = useUserContext();
  const {
    toneTips,
    editToneTip,
    toneTipDetails,
    createToneTip,
    handleCreateToneTip,
    selectedToneTip,
  } = useToneTipContext();

  return (
    <div className="produce-main-container">
      <div className="produce-left-part">
        <div className="top-actions-container">
          {/*Under admin mode, we will have access to all tone tips created, 
        implement an infinite scroll to have a smooth experience, 
        but when searching consider all tonetips available*/}
          {currentUser?.userType === "Admin" && <AdminMode />}
          <div className="btn-search-container">
            <AddNewToneTipButton setCreateToneTip={handleCreateToneTip} />
            <SearchBar />
          </div>
        </div>
        <div className="produce-table-main-container">
          {toneTips && <ProduceTable />}
          {!toneTips && <p>You dont have any ToneTips yet, create one!</p>}
        </div>
      </div>
      {(selectedToneTip || createToneTip) && (
        <RightSideDetailsContainer>
          {editToneTip && <EditToneTip />}
          {createToneTip && <CreateToneTip />}
          {toneTipDetails && <ToneTipDetails />}
        </RightSideDetailsContainer>
      )}
    </div>
  );
};

export default Produce;
