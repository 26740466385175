import { useFormik } from "formik";
import * as Yup from "yup";
import "./Register.scss";
import OrangeButton from "../OrangeButton/OrangeButton";
import EyeIcon from "../../assets/icons/EyeIcon.svg";
import { auth } from "../../firebaseConfig";
import { useUserContext } from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import { registerUserToDb } from "../../services/Users/LoginRegisterServices";
import { UserI } from "../../../Interfaces/User";
import { useState } from "react";

const SignupSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().required("Required").min(6, "Password must be at least 6 characters long"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Required"),
    displayName: Yup.string().required("Required"),
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required")
});

const Register = (props: any) => {
  const {registerWithEmailAndPassword} = useUserContext();
  const [loader, setLoader] = useState(false);
  const { setLogIn } = props;

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      passwordConfirmation: "",
      showPassword: false,
      displayName: "",
      firstName:"",
      lastName:""
    },
    validationSchema: SignupSchema,
    onSubmit: async(values) => {
      try {
        setLoader(true);
        const userData:UserI = {
          displayName:values.displayName,
          email: values.email,
          name: { firstName: values.firstName, lastName: values.lastName},
          userType:"Producer",
          createdAt: new Date(),
          lastLogin: new Date(),
          active: false,
          subType: null,
          toneTips:[],
          toneTipsLeft:0,
          trialTaken:false,
          favorites:[],
          likes:[]
        }
        let response:any = await registerWithEmailAndPassword(values.email, values.password, userData);
        const uid = response.user.uid;
        await registerUserToDb(userData, uid);
      } catch (error:any) {
        if (error.message.includes("email-already-in-use")){
          alert("A user is already registered  with this  email")
          formik.resetForm();
          return;
        }
      } finally { 
        setLoader(false);
      }
    }
  });

  return (
    <div className="register-form-container">
      <h1 className="rf-title">Welcome to ToneTip!</h1>
      <p className="rf-subtitle">
        Register so we can start creating something great together!
        <br />
        Already have an account?{' '}
        <span onClick={() => setLogIn(true)}>Log in</span>
      </p>
      <form onSubmit={formik.handleSubmit}>
        <div className="rf-input-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Type your email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.email && formik.errors.email && (
            <div className="formik-warning">{formik.errors.email}</div>
          )}
        </div>
        <div className="rf-input-group">
          <label htmlFor="displayName">Display Name</label>
          <input
            type="displayName"
            id="displayName"
            name="displayName"
            placeholder="Choose a name to display to other users"
            value={formik.values.displayName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.displayName && formik.errors.displayName && (
            <div className="formik-warning">{formik.errors.displayName}</div>
          )}
        </div>
        <div className="rf-input-group">
          <label htmlFor="firstName">First name</label>
          <input
            type="firstName"
            id="firstName"
            name="firstName"
            placeholder="Type your first name"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.firstName && formik.errors.firstName && (
            <div className="formik-warning">{formik.errors.firstName}</div>
          )}
        </div>
        <div className="rf-input-group">
          <label htmlFor="lastName">Last name</label>
          <input
            type="lastName"
            id="lastName"
            name="lastName"
            placeholder="Type your last name"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.lastName && formik.errors.lastName && (
            <div className="formik-warning">{formik.errors.lastName}</div>
          )}
        </div>

        <div className="rf-input-group">
          <label htmlFor="password">Password</label>
          <input
            type={formik.values.showPassword ? "text" : "password"}
            id="password"
            name="password"
            placeholder="Type your password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          {formik.touched.password && formik.errors.password && (
            <div className="formik-warning">{formik.errors.password}</div>
          )}
          <button
            type="button"
            onClick={() =>
              formik.setFieldValue("showPassword", !formik.values.showPassword)
            }
            id="toggle-show-password"
          >
            <img src={EyeIcon} alt="Eye icon"/>
          </button>
        </div>

        <div className="rf-input-group">
          <label htmlFor="passwordConfirmation">Confirm Password</label>
          <input
            type={formik.values.showPassword ? "text" : "password"}
            id="passwordConfirmation"
            name="passwordConfirmation"
            placeholder="Confirm your password"
            value={formik.values.passwordConfirmation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.passwordConfirmation &&
            formik.errors.passwordConfirmation && (
              <div className="formik-warning">
                {formik.errors.passwordConfirmation}
              </div>
            )}
        </div>
        <div className="submit-container">
          <OrangeButton type="submit" loader={loader}>Register</OrangeButton>
        </div>
      </form>
    </div>
  );
};

export default Register;
