import "./UserDetails.scss";
import CloseButton from "../../assets/img/close-btn.svg";
import UserInfoCard from "../UserInfoCard/UserInfoCard";
import UserPurchaseData from "../UserPurchaseData/UserPurchaseData";
import DefaultAvatar from "../../assets/img/user-default-avatar.svg";
import { UserI } from "../../../Interfaces/User";
import { updateUserInfoFromAdminTab } from "../../services/Users/UserGeneralServices";

interface UserDetailsPropsI {
  selectedUser: UserI;
  fetchAllUsers: () => Promise<void>;
}

const FALLBACK_USER: UserI = {
  displayName: "-",
  email: "-",
  name: {
    firstName: "-",
    lastName: "",
  },
  userType: "Producer",
  createdAt: new Date(0),
  lastLogin: new Date(0),
  active: true,
  subType: null,
  toneTips: [],
  toneTipsLeft: 0,
  trialTaken: false,
  favorites:[],
  likes:[]
};

function UserDetails(props: UserDetailsPropsI) {
  const { selectedUser, fetchAllUsers } = props;

  const updateUserInfo = async () => {
    if (selectedUser.id) {
      await updateUserInfoFromAdminTab(selectedUser.id, selectedUser).then(
        (res) => {
          alert("User updated successfully");
          fetchAllUsers();
        }
      );
    }
  };

  return (
    <div className="user-details-main-container">
      <div className="user-details-top-part">
        <div className="user-name">{`${
          selectedUser.name?.firstName || FALLBACK_USER.name.firstName
        } ${selectedUser.name?.lastName || FALLBACK_USER.name.lastName}`}</div>
        <div className="close-btn">
          <img src={CloseButton} />
        </div>
      </div>
      <div className="user-info section">
        <div className="section-title">USER</div>
        <UserInfoCard
          avatar={selectedUser.avatar || DefaultAvatar}
          name={selectedUser?.name || FALLBACK_USER.name}
          email={selectedUser.email || FALLBACK_USER.email}
        />
      </div>
      <div className="user-info section">
        <div className="section-title">PURCHASE DATA</div>
        <UserPurchaseData
          user={selectedUser}
          updateUserInfo={updateUserInfo}
          refreshUsers={fetchAllUsers}
        />
      </div>
    </div>
  );
}

export default UserDetails;
