import { CouponI } from "../../../Interfaces/Coupon";
import DeleteIcon from "../../assets/img/delete-tonetip-button.svg";
import EditIcon from "../../assets/img/edit-tonetip-button.svg";
import "./CouponsTable.scss";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBroom, faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import {
  createNewCoupon,
  deleteCoupon,
  updateCouponName,
} from "../../services/CloudFunctions";
import { format, fromUnixTime } from "date-fns";
import { Spinner } from "react-bootstrap";

interface CouponsTableRowPropsI {
  coupon: CouponI;
  fetchCoupons: () => Promise<void>;
}

const CouponsTableRow = (props: CouponsTableRowPropsI) => {
  let { coupon, fetchCoupons } = props;
  const [editMode, setEditMode] = useState<boolean>(false);
  const [couponBackUp, setCouponBackUp] = useState(coupon);
  const [editableCoupon, setEditableCoupon] = useState(coupon);
  const [loader, setLoader] = useState(false);

  const todayDate = (): string => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    const minDate = yyyy + "-" + mm + "-" + dd;
    return minDate;
  };

  const saveChanges = async () => {
    setLoader(true);
    try {
      const res = await updateCouponName({
        newName: editableCoupon.title,
        couponId: coupon.code,
      });
      if (res) {
        alert("Success");
        await fetchCoupons();
        setEditMode(false);
      }
    } catch (error: any) {
      alert(error.message);
    }
    setLoader(false);
  };

  const saveNewCoupon = async () => {
    setLoader(true);
    try {
      const res = await createNewCoupon(editableCoupon);
      if (res) {
        alert("Success");
        setEditMode(false);
        await fetchCoupons();
      }
    } catch (error: any) {
      alert(error.message);
    }
    setLoader(false);
  };

  const handleInputChanges = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    property: keyof CouponI
  ) => {
    let { value } = e.target;
    if (property === "redeemBy") {
      let dateObj: Date = new Date(value);
      value = (dateObj.getTime() / 1000).toString();
    }
    setEditableCoupon((prevState) => ({ ...prevState, [property]: value }));
  };

  const discardChanges = () => {
    setEditMode(false);
    coupon = couponBackUp;
  };

  const handleDeleteCoupon = async () => {
    setLoader(true);
    if (window.confirm("Are you sure you want to delete this coupon?")) {
      if (coupon.createdAt) {
        await deleteCoupon({ couponId: coupon.code });
        await fetchCoupons();
      } else {
        alert("This coupon cannot be deleted");
      }
    }
    setLoader(false);
  };

  const editCoupon = async () => {
    setEditMode(true);
  };

  return (
    <tr className="coupon-table-row">
      <th style={{ maxWidth: "150px" }}>
        {editMode || !coupon.createdAt ? (
          <input
            value={editableCoupon.title}
            type="text"
            name="title"
            onChange={(e) => {
              handleInputChanges(e, "title");
            }}
          />
        ) : (
          coupon.title
        )}
      </th>
      <td style={{ maxWidth: "90px" }}>
        {!coupon.createdAt ? (
          <input
            value={editableCoupon.code}
            type="text"
            name="code"
            pattern="^[a-zA-Z0-9_\-]+$"
            onChange={(e) => {
              handleInputChanges(e, "code");
            }}
          />
        ) : (
          coupon.code
        )}
      </td>
      <td>
        {!coupon.createdAt ? (
          <select
            value={editableCoupon.type}
            name="type"
            onChange={(e) => {
              handleInputChanges(e, "type");
            }}
          >
            <option value="money">$</option>
            <option value="percentage">%</option>
          </select>
        ) : coupon.type === "money" ? (
          "$"
        ) : (
          "%"
        )}
      </td>
      <td style={{ maxWidth: "75px" }}>
        {!coupon.createdAt ? (
          <input
            value={editableCoupon.discountAmount}
            type="number"
            name="discountAmount"
            min={1}
            max={100}
            onChange={(e) => {
              handleInputChanges(e, "discountAmount");
            }}
          />
        ) : (
          coupon.discountAmount + (editableCoupon.type === "money" ? "$" : "%")
        )}
      </td>
      <td>
        {!coupon.createdAt ? (
          <select
            value={editableCoupon.durationType}
            name="durationType"
            onChange={(e) => {
              handleInputChanges(e, "durationType");
            }}
          >
            <option value="once">Once</option>
            <option value="repeating">Repeating</option>
            <option value="forever">Forever</option>
          </select>
        ) : coupon.durationType === "forever" ? (
          "Forever"
        ) : coupon.durationType === "once" ? (
          "Once"
        ) : (
          "Repeating"
        )}
      </td>
      <td style={{ maxWidth: "75px" }}>
        {!coupon.createdAt ? (
          <input
            value={editableCoupon.durationMonths || 1}
            type="number"
            name="durationMonths"
            min={1}
            onChange={(e) => {
              handleInputChanges(e, "durationMonths");
            }}
          />
        ) : (
          coupon.durationMonths || "-"
        )}
      </td>
      <td style={{ maxWidth: "135px" }}>
        {!coupon.createdAt ? (
          <input
            type="date"
            name="redeemBy"
            min={todayDate()}
            onChange={(e) => {
              handleInputChanges(e, "redeemBy");
            }}
          />
        ) : (
          coupon.redeemBy ? format(fromUnixTime(coupon.redeemBy), "MM/dd/yy") : "-"
        )}
      </td>
      <td style={{ maxWidth: "115px" }}>
        {!coupon.createdAt ? (
          <input
            value={editableCoupon.maximumRedemptions}
            type="number"
            name="maximumRedemptions"
            min={1}
            onChange={(e) => {
              handleInputChanges(e, "maximumRedemptions");
            }}
          />
        ) : (
          coupon.maximumRedemptions
        )}
      </td>
      <td style={{ maxWidth: "100px" }}>{coupon.timesRedeemed}</td>
      {coupon.createdAt && !editMode && (
        <>
          <td>
            {!loader && <img src={DeleteIcon} onClick={handleDeleteCoupon} />}
            {loader && <Spinner animation="border" role="status" />}
          </td>
          <td>
            <img src={EditIcon} onClick={editCoupon} />
          </td>
        </>
      )}
      {(!coupon.createdAt || editMode) && (
        <>
          <td>
            {!loader && <button
              type="button"
              className="dismiss"
              style={{ backgroundColor: "#ff8929" }}
              onClick={!coupon.createdAt ? saveNewCoupon : saveChanges}
            >
              <FontAwesomeIcon icon={faFloppyDisk} />
            </button>}
            {loader && <Spinner animation="border" role="status" />}
          </td>
        </>
      )}
      {coupon.createdAt && editMode && (
        <>
          <td>
            {!loader && <button className="dismiss" onClick={discardChanges}>
              <FontAwesomeIcon icon={faBroom} />
            </button>}
            {loader && <Spinner animation="border" role="status" />}
          </td>
        </>
      )}
    </tr>
  );
};

export default CouponsTableRow;
