import { db } from "../../firebaseConfig";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebaseConfig";
import { fetchSignInMethodsForEmail } from "firebase/auth";
import { UserI } from "../../../Interfaces/User";
import { collection, doc, setDoc, updateDoc } from "firebase/firestore";
import { createUserDocInFb } from "../CloudFunctions";

export async function checkIfUserExistsByEmail(email: string) {
  const signInMethods = await fetchSignInMethodsForEmail(auth, email);
  if (signInMethods.length > 0) return true;
  else return false;
}

export async function registerUserToDb(user: UserI, uid: string) {
  try {
    await createUserDocInFb(user)
    //await setDoc(doc(db, "Users", uid), user);
  } catch (error: any) {
    alert(error.message);
    // console.log(error);
  }
}

export async function updateLastLogin(id: string, time: number) {
  try {
    const userRef = doc(db, "Users", id);
    await updateDoc(userRef, { lastLogin: new Date(time) });
  } catch (error) {
    // console.log(error);
  }
}

export async function resetPassword(email:string) {
  try {
    const res = await sendPasswordResetEmail(auth, email);
    alert(`An email with the necessary steps to reset your password has been sent to ${email}`);
  } catch (error:any) {
    alert(error.message);
    console.log(error.message)
  }
}