import { useState } from "react";
import OrangeButton from "../OrangeButton/OrangeButton";
import "./UserPurchaseData.scss";
import { useFormik } from "formik";
import DeleteUserModal from "../Modals/DeleteUserModal/DeleteUserModal";
import { UserI } from "../../../Interfaces/User";

interface UserPurchaseDataI {
  user:UserI;
  updateUserInfo: () => Promise<void>;
  refreshUsers: () => Promise<void>;
}

function UserPurchaseData(props:UserPurchaseDataI) {
  const {user, updateUserInfo, refreshUsers} = props;
  const [deleteUserModal, setDeleteUserModal] = useState<boolean>(false);
  const [loader, setLoader] = useState(false);

  const handleDeleteUser = async () => {
    setDeleteUserModal(true);
  }

  const formik = useFormik({
    initialValues: {
      type: user.userType || "Producer",
      toneTips: user.toneTipsLeft || 0,
      displayName: user.displayName || "",
      firstName: user.name?.firstName || "" ,
      lastName: user.name?.lastName || "",
      email: user.email,
    },
    enableReinitialize:true,
    onSubmit: (values) => {
      handleSaveUserChanges();
    },
  });

  const handleSaveUserChanges = async () => {
    setLoader(true);
    user.userType = formik.values.type;
    user.toneTipsLeft = formik.values.toneTips;
    user.displayName = formik.values.displayName;
    user.name.firstName = formik.values.firstName;
    user.name.lastName = formik.values.lastName;
    user.email = formik.values.email;
    await updateUserInfo();
    setLoader(false);
    }

  return (
    <div className="user-purchase-data-container">
      <DeleteUserModal show={deleteUserModal} onHide={(() => setDeleteUserModal(false))} userToDeleteId={user.id} refreshUsers={refreshUsers}/>
      <form onSubmit={formik.handleSubmit}>
        <div className="input-row">
          <div className="up-input-group type">
            <label htmlFor="type">Type</label>
            <select
              id="type"
              name="type"
              onChange={formik.handleChange}
              value={formik.values.type}
            >
              <option>Producer</option>
              <option>Admin</option>
            </select>
          </div>
          <div className="up-input-group tone-tips">
            <label htmlFor="toneTips">ToneTips</label>
            <input
              id="toneTips"
              name="toneTips"
              type="number"
              onChange={formik.handleChange}
              value={formik.values.toneTips}
            />
          </div>
        </div>
        <div className="input-row">
          <div className="up-input-group display-name">
            <label htmlFor="displayName">Display name</label>
            <input
              id="displayName"
              name="displayName"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.displayName}
            />
            {formik.touched.displayName && formik.errors.displayName ? (
              <div className="form-error">{formik.errors.displayName}</div>
            ) : null}
          </div>
        </div>
        <div className="input-row">
          <div className="up-input-group first-name">
            <label htmlFor="firstName">First name</label>
            <input
              id="firstName"
              name="firstName"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.firstName}
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <div className="form-error">{formik.errors.firstName}</div>
            ) : null}
          </div>
          <div className="up-input-group last-name">
            <label htmlFor="lastName">Last name</label>
            <input
              id="lastName"
              name="lastName"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.lastName}
            />
            {formik.touched.lastName && formik.errors.lastName ? (
              <div className="form-error">{formik.errors.lastName}</div>
            ) : null}
          </div>
        </div>
        <div className="input-row">
          <div className="up-input-group display-name">
            <label htmlFor="email">Email</label>
            <input
              id="email"
              name="email"
              type="email"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="form-error">{formik.errors.email}</div>
            ) : null}
          </div>
        </div>
        <div className="actions-section-container">
          <button type="button" className="user-info-action-btn delete" onClick={handleDeleteUser}>
            Delete
          </button>
          <button
            type="button"
            className="user-info-action-btn dismiss"
            onClick={() => formik.resetForm()}
          >
            Dismiss changes
          </button>
          <OrangeButton type="submit" loader={loader}>Save</OrangeButton>
        </div>
      </form>
    </div>
  );
}

export default UserPurchaseData;
