import { OverlayTrigger, Tooltip } from "react-bootstrap";
import PricePlansIcon from "../../assets/icons/money-icon.svg";
import { useState } from "react";
import PricePlansCRUDmodal from "../Modals/PricePlansCRUDmodal/PricePlansCRUDmodal";

const PricePlansSettingsButton = () => {
  const [pricePlansModal, setPricePlansModal] = useState(false);
  const pricePlansTooltip = (
    <Tooltip id="price-plans-tooltip">
      <strong>Change price plans</strong>
    </Tooltip>
  );
  return (
    <>
      <PricePlansCRUDmodal
        show={pricePlansModal}
        hideModal={() => {
          setPricePlansModal(false);
        }}
      />
      <OverlayTrigger placement="bottom" overlay={pricePlansTooltip}>
        <button type="button" onClick={() => {setPricePlansModal(true)}} className="settings-btn">
          <img src={PricePlansIcon} />
        </button>
      </OverlayTrigger>
    </>
  );
};

export default PricePlansSettingsButton;
