import { useState } from "react";
import OrangeButton from "../OrangeButton/OrangeButton";

interface ApplyCouponI {
  testCouponExistance: (couponCode: string) => void;
}

const ApplyCoupon = (props:ApplyCouponI) => {
  const {testCouponExistance} = props;
  const [couponText, setCouponText] = useState<string>('');
  const handleTestCoupon = () => {
    if (couponText.length > 1)
    testCouponExistance(couponText);
  };

  return (
    <div className="input-coupon-container">
      <input type="text" onChange={(e) => setCouponText(e.target.value)} style={{marginRight:"10px"}}/>
      <OrangeButton type="button" clickEvent={handleTestCoupon}>
        Apply
      </OrangeButton>
    </div>
  );
};

export default ApplyCoupon;
