import "./ProfileOptionsModal.scss"
import UserIcon from "../../assets/icons/user-icon.svg";
import LockIcon from "../../assets/icons/lock-icon.svg";
import LogoutIcon from "../../assets/icons/logout-icon.svg";
import { useUserContext } from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import EditProfileModal from "../EditProfileModal/EditProfileModal";
import CreditCardIcon from "../../assets/icons/credit-card-icon.svg";
import SubscriptionIcon from "../../assets/icons/subscription-icon.svg";
import PaymentMethodsModal from "../Modals/PaymentMethodsModal/PaymentMethodsModal";
import ManageSubscriptionModal from "../Modals/ManageSubscriptionModal/ManageSubscriptionModal";


function ProfileOptionsModal() {
    const navigate = useNavigate();
    const {signOutUser, currentUser} = useUserContext();
    const [editProfileModal, setEditProfileModal] = useState<boolean>();
    const [subscriptionModal, setSubscriptionModal] = useState<boolean>(false);
    const [paymentMethodsModal, setPaymentMethodsModal] = useState(false);

    const handlePaymentMethodOptions =  () => {
        setPaymentMethodsModal(true);
    }

    const handleSignOut = async() => {
        await signOutUser();
        navigate('/login');
    }

    const handleCloseEditProfileModal = () => {
        setEditProfileModal(false);
    }

    const handleSubscription = () => {
        setSubscriptionModal(true);
    }

    return ( <div className="profile-options-content-container">
        {paymentMethodsModal && <PaymentMethodsModal show={paymentMethodsModal} onHide={() => setPaymentMethodsModal(false)}/>}
        <EditProfileModal show={editProfileModal} onCloseModal={handleCloseEditProfileModal}/>
        {subscriptionModal && <ManageSubscriptionModal show={subscriptionModal} onHide={() => setSubscriptionModal(false)}/>}
        <span style={{color:'rgb(0,0,0,0.5)', fontWeight:'800'}}>MY PROFILE</span>
        <div className="profile-option" onClick={() => setEditProfileModal(true)}><img src={UserIcon}/> <span>Edit profile</span></div>
        {/* <div className="profile-option"><img src={LockIcon}/><span>Change password</span></div> */}
        <div className="profile-option" onClick={handlePaymentMethodOptions}><img src={CreditCardIcon}/><span>Payment method</span></div>
        <div className="profile-option" onClick={handleSubscription}><img src={SubscriptionIcon}/><span>Manage subscription</span></div>
        <div className="profile-option" onClick={handleSignOut}><img src={LogoutIcon}/><span>Logout</span></div>
    </div> );
}

export default ProfileOptionsModal;