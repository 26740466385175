import { OverlayTrigger, Tooltip } from "react-bootstrap";
import EditButtonImg from "../../assets/img/edit-tonetip-button.svg";
import './EditButton.scss';

function EditButton(props:any) {
    const {clickEvent} = props
  const editTooltip = (
    <Tooltip id="edit-tooltip">
      <strong>Edit</strong>
    </Tooltip>
  );
  return (
    <OverlayTrigger placement="top" overlay={editTooltip}>
      <button type="button" onClick={clickEvent} id='edit-btn'>
        <img src={EditButtonImg} />
      </button>
    </OverlayTrigger>
  );
}

export default EditButton;
