import "./FinalStepContent.scss";
import ToneTipLogo from "../../../assets/img/tone-tip-logo-circle.svg";
import { useUserContext } from "../../../context/userContext";

function FinalStepContent(props:any) {
  const {currentUser} = useUserContext();
  const {planInfo, onHide} = props;
  return (
    <div className="final-step-conent-container">
      <div className="logo-container">
        <img src={ToneTipLogo} />
      </div>
      <span className="payment-success">Payment Successful</span>
      <span className="ty-message">Thank you, {currentUser?.name?.firstName}!</span>
      <span className="sub-message">
        You have successfully subscribed to {planInfo.planType} plan.
      </span>
    </div>
  );
}

export default FinalStepContent;
