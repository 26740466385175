import { db } from "../firebaseConfig";
import {
  getDoc,
  doc,
  collection,
  addDoc,
  setDoc,
  getDocs,
  where,
  query,
  updateDoc,
  orderBy,
} from "firebase/firestore";
import {
  CommentI,
  ToneTipI,
  UpdateToneTipDataI,
} from "../../Interfaces/ToneTip";
import { getUserDisplayNameAndEmailById } from "./Users/UserGeneralServices";

export async function getToneTipComments(toneTipTAG: string) {
  try {
    let comments: CommentI[] = [];
    const q = query(
      collection(db, "Comments"),
      where("toneTipTag", "==", toneTipTAG), orderBy("createdAt", "desc")
    );
    return await getDocs(q).then((res) => {
      res.docs.map((doc) => {
        const edited = doc.data().editedAt != null
        if (doc.data().deletedAt == null){
          comments.push({id:doc.id, editedComment:edited ,...doc.data() as CommentI});
        }
      });
      return comments;
    });
  } catch (error: any) {
    throw new Error(error.message);
  }
}

export async function getToneTipsLeft(userId: string) {
  try {
    const userRef = doc(db, "Users", userId);
    const docSnap = await getDoc(userRef);
    let availableToneTips: number = 0;
    if (docSnap.exists()) {
      if (docSnap.data().toneTipsLeft !== undefined) {
        availableToneTips = docSnap.data().toneTipsLeft;
      }
    }
    return availableToneTips;
  } catch (error: any) {
    alert(error.code);
  }
}

export async function enableOrDisableToneTip(
  toneTipTag: string,
  newStatus: boolean
) {
  try {
    const toneTipRef = doc(db, "ToneTips", toneTipTag);
    await updateDoc(toneTipRef, {active:newStatus});
  } catch (error) {
    return error;
  }
}

export async function enableOrDisableToneTipComments(
  toneTipTag: string,
  newStatus: boolean
) {
  try {
    const toneTipRef = doc(db, "ToneTips", toneTipTag);
    await updateDoc(toneTipRef, {commentsEnabled:newStatus});
  } catch (error) {
    return error;
  }
}

export async function updateToneTip(
  updatedData: UpdateToneTipDataI,
  toneTipTag: string
) {
  try {
    const toneTipRef = doc(db, "ToneTips", toneTipTag);
    await updateDoc(toneTipRef, { ...updatedData });
    return;
  } catch (error) {
    return error;
  }
}

export async function uploadToneTip(toneTip: ToneTipI, TAG: string) {
  try {
    const ttCollectionRef = doc(db, "ToneTips", TAG);
    await setDoc(ttCollectionRef, toneTip);
    return true;
  } catch (error: any) {
    // console.log(error);
    throw new Error(error.message);
  }
}

export async function getProducerToneTips(userId: string) {
  try {
    const toneTipsRef = collection(db, "ToneTips");
    const q = query(
      toneTipsRef,
      where("creator", "==", userId),
      orderBy("createdAt", "desc")
    );
    const userToneTips = await getDocs(q);
    let toneTips: ToneTipI[] = [];
    if (!userToneTips.empty) {
      userToneTips.docs.map((doc) => {
        let tempDoc = { id: doc.id, ...(doc.data() as ToneTipI) };
        toneTips.push(tempDoc as ToneTipI);
      });
    } else {
      throw new Error("No toneTips found");
    }
    return toneTips;
  } catch (error:any) {
    // console.log(error.message);
  }
}

export async function getLastToneTipTAG(admin?:boolean) {
  // This service gets the lastId used
  try {
    const lastToneTipTAGRef = doc(
      collection(db, "Miscellaneous"),
      admin === true ? "LastToneTipTAGAdmin": "LastToneTipTAG"
    );
    const lastToneTipTagDoc = await getDoc(lastToneTipTAGRef);
    let newHexTAG: string;
    if (lastToneTipTagDoc.exists()) {
      const lastToneTipTag = lastToneTipTagDoc.data().value;
      const nextToneTipTag = parseInt(lastToneTipTag, 16) + 1;
      const nextToneTipTagHex = nextToneTipTag
        .toString(16)
        .toUpperCase()
        .padStart(12, "0");
        if (nextToneTipTagHex === "0000000003FF"){
          alert("All admin UARCs have been consumed");
          return;
        }
      await updateDoc(lastToneTipTAGRef, { value: nextToneTipTagHex });
      newHexTAG = nextToneTipTagHex;
    } else {
      return null;
    }
    return newHexTAG;
  } catch (error) {
    // console.log(error);
  }
}

export async function checkIfToneTipExistsById(tag: string) {
  try {
    const toneTipRef = doc(db, "ToneTips", tag);
    const toneTipDoc = await getDoc(toneTipRef);
    if (toneTipDoc.exists()) {
      return false;
    } else {
      return true;
    }
  } catch (error) {
    // console.log(error);
  }
}

export async function fetchSingleToneTip(toneTipTAG:string):Promise<ToneTipI> {
  try {
    let tt:ToneTipI;
    const ttRef = doc(db,"ToneTips", toneTipTAG);
    const ttDoc = await getDoc(ttRef);
    if (ttDoc.exists()){
      tt = {id: ttDoc.id, ...ttDoc.data() as ToneTipI}
    } else {
      throw new Error("There was a problem fetching ToneTip data, try refreshing the page");
    }
    return tt;
  } catch (error:any) {
    throw new Error(error.message);
  }
}

export async function fetchEveryToneTip(): Promise<Array<ToneTipI>>{
  try {
    const ttsRef = collection(db, "ToneTips");
    const q = query(
      ttsRef,
      orderBy("createdAt", "desc")
    );
    const ttsDocSnap = await getDocs(q);
    const tts: ToneTipI[] = await Promise.all(
      ttsDocSnap.docs.map(async (tt) => {
        const creatorInfo = await getUserDisplayNameAndEmailById(tt.data().creator);
        return { id: tt.id, creatorDisplayName: creatorInfo.displayName, creatorEmail: creatorInfo.email, ...tt.data() } as ToneTipI;
      })
    );
    return tts;
  } catch (error) {
    // console.log(error)
    throw new Error("An error ocurred while fetching all the ToneTips")
  }
}
