import OrangeButton from "../OrangeButton/OrangeButton";
import "./EditProfileModal.scss";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useUserContext } from "../../context/userContext";
import DefaultAvatar from "../../assets/img/user-default-avatar.svg";
import { Key, useEffect, useRef, useState } from "react";
import { uploadPicture } from "../../services/StorageUploadServices";
import {
  updateFirebaseEmail,
  updateProfile,
} from "../../services/Users/UserGeneralServices";
import { updateStripeCustomerEmailAndName } from "../../services/CloudFunctions";
const FILE_SIZE = 2000000; // 2 MB

export default function EditProfileModal(props: any) {
  const { show, onCloseModal } = props;
  const { currentUser, refreshUser } = useUserContext();
  const [imagePreview, setImagePreview] = useState<string>(
    currentUser?.avatar || DefaultAvatar
  );
  const [profilePicFile, setProfilePicFile] = useState<File | null>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const imageInputRef: any = useRef(null);

  const formik = useFormik({
    initialValues: {
      displayName: currentUser?.displayName || "",
      firstName: currentUser?.name?.firstName || "",
      lastName: currentUser?.name?.lastName || "",
      email: currentUser?.email || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      displayName: Yup.string().required("Required").max(25),
      firstName: Yup.string().required("Required"),
      lastName: Yup.string().required("Required"),
      email: Yup.string().email("Invalid email").required("Required"),
    }),
    onSubmit: async (values: any) => {
      let avatarURL: string | null = "";
      try {
        setLoader(true);
        if (currentUser?.id) {
          if (values.email !== formik.initialValues.email) {
            await updateFirebaseEmail(values.email);
          }
          if (profilePicFile) {
            await uploadPicture(profilePicFile, "avatars", currentUser.id).then(
              (res) => {
                avatarURL = res;
              }
            );
          }
          const response = await updateProfile(
            values.displayName,
            values.firstName,
            values.lastName,
            values.email,
            avatarURL || currentUser.avatar || ""
          );
          if (response) alert("Profile updated successfully");
          if (currentUser.stripeId) {
            try {
              await updateStripeCustomerEmailAndName({
                customerId: currentUser.stripeId,
                email: values.email,
                name: `${values.firstName} ${values.lastName}`,
              });
            } catch (error:any) {
              // console.log(error.message)
            }
          }
          //await refreshUser();
          onCloseModal();
        }
      } catch (error: any) {
        if (error.message.includes("requires-recent-login"))
          alert("To update email you have to login again, please do so!");
      } finally{
        setLoader(false);
      }
    },
  });

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.size > FILE_SIZE) {
        alert("File size must be 2 MB or less");
        return;
      }
      setProfilePicFile(file);
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      setProfilePicFile(null);
      setImagePreview(currentUser?.avatar || DefaultAvatar);
    }
  };

  const handleImageClick = () => {
    if (imageInputRef.current) {
      imageInputRef.current.click();
    }
  };

  const handleModalExit = () => {
    formik.resetForm();
    setProfilePicFile(null);
    setImagePreview(currentUser?.avatar || DefaultAvatar);
    setLoader(false);
  };

  // useEffect(() => {
  //   if (formik.dirty || profilePicFile) {
  //     setLoader(true);
  //   } else {
  //     setLoader(false);
  //   }
  // }, [formik.dirty, profilePicFile]);

  return (
    <Modal
      show={show}
      onHide={onCloseModal}
      dialogClassName="edit-profile-modal"
      centered
      onExited={handleModalExit}
    >
      <Modal.Header
        closeButton
        style={{ borderBottom: "none", paddingBottom: "0" }}
      />
      <Modal.Body className="edit-profile-modal-body">
        <div className="profile-picture-container">
          <input
            type="file"
            style={{ display: "none" }}
            onChange={handleImageChange}
            ref={imageInputRef}
            accept="image/jpeg, image/png"
          />
          <div className="pp-frame" onClick={handleImageClick}>
            <img src={imagePreview} />
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="epm-row">
            <div className="epm-input-group">
              <label htmlFor="displayName">Display name</label>
              <input
                type="text"
                id="displayName"
                name="displayName"
                onChange={formik.handleChange}
                value={formik.values.displayName}
              />
              {formik.touched.displayName && formik.errors.displayName && (
                <div className="formik-warning">
                  {formik.errors.displayName}
                </div>
              )}
            </div>
          </div>
          <div className="epm-row">
            <div className="epm-input-group">
              <label htmlFor="firstName">First name</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                onChange={formik.handleChange}
                value={formik.values.firstName}
              />
              {formik.touched.firstName && formik.errors.firstName && (
                <div className="formik-warning">{formik.errors.firstName}</div>
              )}
            </div>
            <div className="epm-input-group">
              <label htmlFor="lastName">Last name</label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                onChange={formik.handleChange}
                value={formik.values.lastName}
              />
              {formik.touched.lastName && formik.errors.lastName && (
                <div className="formik-warning">{formik.errors.lastName}</div>
              )}
            </div>
          </div>
          <div className="epm-row">
            <div className="epm-input-group">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                id="email"
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email && (
                <div className="formik-warning">{formik.errors.email}</div>
              )}
            </div>
          </div>
          <div className="epm-row">
            <OrangeButton type="submit" loader={loader}>
              Save
            </OrangeButton>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
