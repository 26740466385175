import { Modal, Spinner } from "react-bootstrap";
import OrangeButton from "../../OrangeButton/OrangeButton";
import { useUserContext } from "../../../context/userContext";
import {
  addYears,
  differenceInCalendarDays,
  differenceInDays,
  getUnixTime,
  startOfDay,
} from "date-fns";
import { SubscriptionPlanI } from "../../../../Interfaces/PlanInfo";
import { useEffect, useState } from "react";
import {
  changeCurrentPlan,
  getCustomerBalance,
} from "../../../services/CloudFunctions";

interface ChangePlanModalI {
  show: boolean;
  onHide: () => void;
  planInfo: SubscriptionPlanI;
  annual: boolean;
}

const ChangePlanModal = (props: ChangePlanModalI) => {
  const { show, onHide, annual, planInfo } = props;
  const { userSubscription, refreshUser, currentUser } = useUserContext();
  const [nextInvoiceAmount, setNextInvoiceAmount] = useState<number>();
  const [balance, setBalance] = useState<number>();
  const [loader, setLoader] = useState(false);

  const getUserBalance = async () => {
    if (currentUser?.stripeId) {
      try {
        // console.log(currentUser.stripeId);
        const { data: tempBalance } = await getCustomerBalance(
          currentUser.stripeId
        );
        // console.log(tempBalance)
        setBalance(tempBalance);

      } catch (error: any) {
        // console.log(error.message);
      }
    }
  };

  const calculateNextInvoice = () => {
    const currentInterval =
      userSubscription?.items.data[0].price.recurring?.interval;
    const newInterval = annual ? "year" : "month";
    const intervalChange = currentInterval !== newInterval;
    if (
      userSubscription?.current_period_end &&
      userSubscription.current_period_start &&
      userSubscription.items.data[0].price.unit_amount &&
      balance !== undefined
    ) {
      // console.log(balance)
      const endTimestamp = userSubscription?.current_period_end;
      const startTimestamp = userSubscription?.current_period_start;
      const newPriceAmount = annual
        ? planInfo.pricePerYear
        : planInfo.pricePerMonth;
      const currentPriceAmount =
        userSubscription.items.data[0].price.unit_amount / 100;
      const todayTimestamp = getUnixTime(startOfDay(new Date()));
      const daysConsumed = differenceInCalendarDays(
        new Date(todayTimestamp * 1000),
        new Date(startTimestamp * 1000)
      );
      const currentPeriodDays = differenceInCalendarDays(
        new Date(endTimestamp * 1000),
        new Date(startTimestamp * 1000)
      );
      const daysLeftInPeriod = currentPeriodDays - daysConsumed;
      if (newPriceAmount) {
        const nextInvoiceDifferentPeriod =
          newPriceAmount -
          (currentPriceAmount / currentPeriodDays) * daysLeftInPeriod;
        const nextInvoiceSamePeriod = parseFloat(
          (
            newPriceAmount +
            daysLeftInPeriod *
              ((newPriceAmount - currentPriceAmount) / currentPeriodDays)
          ).toFixed(2)
        );
        const nextInvoiceAmountTemp = intervalChange
          ? (nextInvoiceDifferentPeriod + balance)
          : (nextInvoiceSamePeriod + balance);
        // console.log("nextInvoiceAmount: " + nextInvoiceAmountTemp);
        if (nextInvoiceAmountTemp < 0) {
          setNextInvoiceAmount(0);
        } else {
          setNextInvoiceAmount(nextInvoiceAmountTemp);
        }
      }
    }
  };

  useEffect(() => {
    if (balance != undefined) {
      calculateNextInvoice();
    }
  }, [annual, balance]);

  useEffect(() => {
    getUserBalance();
  }, []);

  const handlePlanUpdate = async () => {
    try {
      setLoader(true);
      const newPriceId = annual
        ? planInfo.pricePerYearId
        : planInfo.pricePerMonthId;
      const subItemId = userSubscription?.items.data[0].id;
      if (currentUser?.trialTaken && planInfo.planType.toLowerCase().includes("trial")){
        throw new Error("You already took a trial period, choose any of the other plans please.")
      }
      if (newPriceId && subItemId) {
        const { data: newSubItem } = await changeCurrentPlan({
          newPriceId,
          subItemId,
        });
        alert("You have successfully changed plans!");
        //await refreshUser();
        onHide();
      }
    } catch (error: any) {
      // console.log(error.message);
      alert("Error: " + error.message);
    } finally{
      setLoader(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <b>Note:</b> We prorate subscription changes. For example, if a customer
        signs up on May 1 for a $100 price, they'll be billed $100 immediately.
        If on May 15 they switch to a $200 price, then on June 1 they'll be
        billed $250 ($200 for a renewal of her subscription, plus a $50
        prorating adjustment for half of the previous month's $100 difference).
        Similarly, a downgrade generates a credit that is applied to the next
        invoice. In this case, your next payment will be billed (approximately)
        at: {nextInvoiceAmount?.toString() + " USD"|| <><Spinner/>Loading estimate...</>}
        <br />
        <br/>
        {nextInvoiceAmount === 0
          ? "A payment of 0 means you have credit left for the next invoice!"
          : ""}
        <br />
        <br />
        <OrangeButton type="button" clickEvent={handlePlanUpdate} loader={loader}>
          Yes, change my plan!
        </OrangeButton>
      </Modal.Body>
    </Modal>
  );
};

export default ChangePlanModal;
