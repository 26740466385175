import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../context/userContext";
import { useEffect } from "react";

export default function LoginRedirect (props:any) {
    const {currentUser} = useUserContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (currentUser){
            navigate('/produce');
        }
    }, [currentUser]);
    return props.children;
}