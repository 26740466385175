import { db } from "../../firebaseConfig";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  increment,
  orderBy,
  query,
  where,
  updateDoc,
  arrayUnion,
  onSnapshot,
} from "firebase/firestore";
import { UserI } from "../../../Interfaces/User";
import { auth } from "../../firebaseConfig";
import { updateEmail } from "firebase/auth";
import { addAdminClaim } from "../CloudFunctions";


export async function addAdminToken(userId:string) {
  try {
    await addAdminClaim(userId);
    return true
  } catch (error:any) {
    console.log(error.message)
    return null
  }
} 


export async function updateUserInfoFromAdminTab(
  userId: string,
  updatedUser: UserI
) {
  try {
    const userRef = doc(db, "Users", userId);
    await updateDoc(userRef, {
      userType: updatedUser.userType,
      toneTipsLeft: updatedUser.toneTipsLeft,
      displayName: updatedUser.displayName,
      name: {
        firstName: updatedUser.name.firstName,
        lastName: updatedUser.name.lastName,
      },
      email: updatedUser.email,
    });
    return true;
  } catch (error) {
    return false;
  }
}

export async function changeUserActiveStatus(
  userId: string,
  newStatus: boolean
) {
  try {
    const userRef = doc(db, "Users", userId);
    await updateDoc(userRef, { active: newStatus });
    return newStatus;
  } catch (error) {
    return null;
  }
}

export async function getUserById(id: string) {
  try {
    const userRef = doc(db, "Users", id);
    const docSnap = await getDoc(userRef);
    if (docSnap.exists()) {
      const user: UserI = {
        id: docSnap.id,
        ...(docSnap.data() as UserI),
      };
      return user;
    }
  } catch (error) {
    // console.log(error);
    return null;
  }
}

export async function updateProfile(
  displayName: string,
  firstName: string,
  lastName: string,
  email: string,
  avatarURL: string
) {
  try {
    if (auth.currentUser?.uid) {
      const userRef = doc(db, "Users", auth.currentUser.uid);
      const response = await updateDoc(userRef, {
        displayName: displayName,
        name: { firstName: firstName, lastName: lastName },
        email: email,
        avatar: avatarURL,
      });
      return true;
    }
  } catch (error) {
    // console.log(error);
    return false;
  }
}

export async function updateFirebaseEmail(email: string) {
  try {
    if (auth.currentUser) {
      await updateEmail(auth.currentUser, email);
    }
    return true;
  } catch (error: any) {
    // console.log(error);
    throw new Error(error);
  }
}

export async function getAllUsers() {
  // Fetch all users ordered by last login
  try {
    const usersRef = collection(db, "Users");
    const q = query(collection(db, "Users"));
    let snapShot = await getDocs(q);
    const users: UserI[] = snapShot.docs.map((doc) => {
      return { id: doc.id, ...(doc.data() as UserI) };
    });
    return users;
  } catch (error) {
    // console.log(error);
  }
}

export async function decreaseToneTipsLeft(userId: string, TAG: string) {
  try {
    const userRef = doc(db, "Users", userId);
    const toneTipsLeft = await updateDoc(userRef, {
      toneTipsLeft: increment(-1),
      toneTips: arrayUnion(TAG),
    });
    return true;
  } catch (error) {
    // console.log(error);
  }
}

export async function getUserDisplayNameAndEmailById(userId: string) {
  try {
    const userRef = doc(db, "Users", userId);
    const userInfoSnap = await getDoc(userRef);
    let userInfo;
    if (userInfoSnap.exists()) {
      userInfo = {
        displayName: userInfoSnap.data().displayName,
        email: userInfoSnap.data().email,
      };
    } else {
      userInfo = { displayName: "User not found", email: "User not found" };
    }
    return userInfo;
  } catch (error) {
    // console.log(error);
    throw new Error("Error fetching user info");
  }
}


