import { Accordion, Modal, Spinner } from "react-bootstrap";
import PaymentMethodCard from "../../PaymentMethodCard/PaymentMethodCard";
import "./PaymentMethodsModal.scss";
import ForwardsArrow from "../../../assets/img/go-forward-arrow.svg";
import AddElementButton from "../../../assets/icons/add-command-btn.svg";
import { useEffect, useState } from "react";
import { useUserContext } from "../../../context/userContext";
import AddPaymentMethodForm from "./AddPaymentMethodForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

interface PaymentMethodsModalProps {
  show: boolean;
  onHide: React.Dispatch<React.SetStateAction<boolean>>;
}

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_LIVE_PUBLISHABLE_KEY!
);
const options = {
  mode: "payment" as "payment",
  currency: "usd",
  amount: 1099,
  paymentMethodCreation:"manual" as "manual",
  paymentMethodTypes: ['card'],
};

const PaymentMethodsModal = (props: PaymentMethodsModalProps) => {
  const { show, onHide } = props;
  const [addPaymentMethod, setAddPaymentMethod] = useState(false);
  const { userPaymentMethod: paymentMethod } = useUserContext();
  const {currentUser, refreshUser} = useUserContext();
  const hideModal = () => {
    onHide(false);
  };

// const getStripeId = async() => {
//   await refreshUser();
// }

// useEffect(() => {
//   if (!currentUser?.stripeId){
//     getStripeId();
//   }
// }, []);

  return (
    <Elements stripe={stripePromise} options={options} >
      <Modal
        show={show}
        onHide={hideModal}
        onExited={() => setAddPaymentMethod(false)}
        contentClassName="payment-method-modal"
      >
        <Modal.Header closeButton>
          <h3>Payment method</h3>
        </Modal.Header>
        <Modal.Body>
          {paymentMethod && (
            <Accordion defaultActiveKey="0">
              <PaymentMethodCard paymentMethod={paymentMethod}/>
            </Accordion>
          )}
          {paymentMethod === undefined && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Spinner animation="border" role="status" />
              <h5 style={{ marginBottom: "0" }}>
                &nbsp;&nbsp;Loading payment method...
              </h5>
            </div>
          )}
          {paymentMethod === null && !addPaymentMethod && (
            <>
              <p>No payment method found...</p>
              <p>
                Do you want to add One? <img src={ForwardsArrow} />
                <img
                  src={AddElementButton}
                  id="add-element-btn"
                  onClick={() => {
                    setAddPaymentMethod(true);
                  }}
                />
              </p>
            </>
          )}
          {addPaymentMethod && currentUser?.stripeId && <AddPaymentMethodForm setAddPaymentMethod={setAddPaymentMethod}/>}
        </Modal.Body>
      </Modal>
    </Elements>
  );
};

export default PaymentMethodsModal;
