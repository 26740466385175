import "./AdminTable.scss";
import { FormCheck } from "react-bootstrap";
import { UserI } from "../../../Interfaces/User";
import DefaultPP from "../../assets/img/user-default-avatar.svg";
import {firebaseTimeStampToDate} from "../../utils/StringFormatters";
import { FirebaseTimeStampI } from "../../../Interfaces/FirebaseTimeStamp";
import { changeUserActiveStatus } from "../../services/Users/UserGeneralServices";
import { useRef, useState } from "react";

const AdminTable = (props: any) => {
  const { users, setSelectedUser } = props;
  const [activeStatusLoader, setActiveStatusLoader] = useState<boolean>(false);

  const handleActiveStatusChange = async (e: React.ChangeEvent<HTMLInputElement>, user:UserI) => {
    setActiveStatusLoader(true);
    const newStatus:boolean = e.target.checked;
    if (user.id){
      const res = await changeUserActiveStatus(user.id, newStatus);
      if (res !== null){
        alert(`Status updated to : ${res ? "Active" : "Not active"}`)
      } else{
        alert("Failed to update status, try again")
      }
    }
    setActiveStatusLoader(false);
  }

  const dateFormatter = (date: FirebaseTimeStampI) => {
    const jsDate = firebaseTimeStampToDate(date);
    const exactDate = jsDate?.toLocaleDateString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    const time = jsDate?.toLocaleTimeString();

    return (
      <>
        {exactDate}
        <br />
        <span className="time-text">{time}</span>
      </>
    );
  };

  return (
    <div className="produce-table-container">
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">USER</th>
            <th scope="col">EMAIL</th>
            <th scope="col">SIGNED IN</th>
            <th scope="col">SUBSCRIPTION</th>
            <th scope="col" className="centered icon-header">
              ENABLED
            </th>
          </tr>
        </thead>
        <tbody>
          {users.map((user: UserI) => {
            return (
              <tr onClick={() => setSelectedUser(user)} key={user.id}>
                <th scope="row">
                  <div className="pp-tonetip-cell-container">
                    <img src={user.avatar || DefaultPP} />
                    <span className="creator-display-name">{user.displayName || "-"}</span>
                  </div>
                </th>
                <td>{user.email}</td>
                <td>
                {dateFormatter(user.lastLogin as FirebaseTimeStampI)}
                </td>
                <td>
                  {user.subType || "No plan"}
                  <br />
                  <span className="time-text">{`${user.toneTipsLeft?.toString()} TTs left` || ""}</span>
                </td>
                <td className="centered">
                  <FormCheck
                    type="switch"
                    id="enabled-switch"
                    defaultChecked={user.active}
                    onChange={(e) => handleActiveStatusChange(e,user)}
                    disabled={activeStatusLoader}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default AdminTable;
