import "./AddNewToneTipButton.scss";
import ToneTipWaves from "../../assets/img/tone-tip-orange-waves.svg";
import { getToneTipsLeft } from "../../services/ToneTipServices";
import { useUserContext } from "../../context/userContext";

const AddNewToneTipButton = (props: any) => {
  const { setCreateToneTip } = props;
  const { currentUser } = useUserContext();

  const handleCreateToneTip = async () => {
    if (!currentUser || !currentUser.id) return;

    if (currentUser.userType === "Admin") {
      setCreateToneTip();
      return;
    }

    //const toneTipsLeft = await getToneTipsLeft(currentUser.id);
    const toneTipsLeft = currentUser.toneTipsLeft;
    if (!toneTipsLeft || toneTipsLeft <= 0) {
      alert("Please upgrade your plan to get additional ToneTips.");
      return;
    }

    setCreateToneTip();
  };

  return (
    <>
      <div
        className="new-tonetip-button-container"
        onClick={handleCreateToneTip}
      >
        <img src={ToneTipWaves} className="waves" alt="Tone tip waves" />
        <div className="orange-plus-container">
          <div className="horizontal-bar bar"></div>
          <div className="vertical-bar bar"></div>
        </div>
        <div id="new-tone-tip-tooltip">Add New ToneTip</div>
      </div>
    </>
  );
};
export default AddNewToneTipButton;
