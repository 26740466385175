import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import RegisterPage from "./pages/RegisterPage/RegisterPage";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import MainLayout from "./layouts/MainLayout/MainLayout";
import Produce from "./pages/Produce/Produce";
import UpgradePage from "./pages/Upgrade/UpgradePage";
import Admin from "./pages/Admin/Admin";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import UserContextProvider from "./context/userContext";
import UserAccess from "./utils/UserAccess/UserAccess";
import LoginRedirect from "./utils/LoginRedirect/LoginRedirect";
import AdminAccess from "./utils/AdminAccess/AdminAccess";
import Privacy from "./pages/Privacy/Privacy";
import ToneTipContextProvider from "./context/toneTipsContext";
import ActiveUserAccess from "./utils/ActiveUserAcces/ActiveUserAccess";
import TtPreviewPage from "./pages/TtPreview/TtPreviewPage";
import BasicAlternateLayout from "./layouts/BasicAlternateLayout/BasicAlternateLayout";

const router = createBrowserRouter([
  {
    path: "/login",
    element: (
      <LoginRedirect>
        <RegisterPage />
      </LoginRedirect>
    ),
  },
  {
    path: "/pricing",
    element: (
      <BasicAlternateLayout>
        <UpgradePage />
      </BasicAlternateLayout>
    ),
  },
  {
    path: "/privacy",
    element: (
      // <BasicAlternateLayout>
        <Privacy /> // Currently this component only redirects to tonetip.com/privacy-policy
      // </BasicAlternateLayout>
    ),
  },
  {
    path: "/tt/:id",
    element: (
      <BasicAlternateLayout>
        <TtPreviewPage />,
      </BasicAlternateLayout>
    ),
  },
  {
    path: "/",
    element: <Navigate to={"/produce"} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "",
    element: (
      <UserAccess>
        <MainLayout />
      </UserAccess>
    ),
    children: [
      {
        path: "/produce",
        element: (
          <ToneTipContextProvider>
            <ActiveUserAccess>
              <Produce />
            </ActiveUserAccess>
          </ToneTipContextProvider>
        ),
      },
      {
        path: "/upgrade",
        element: <UpgradePage />,
      },
      {
        path: "/admin",
        element: (
          <AdminAccess>
            <Admin />
          </AdminAccess>
        ),
      },
    ],
    errorElement: <ErrorPage />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <UserContextProvider>
      <RouterProvider router={router} />
    </UserContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
